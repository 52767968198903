import React, { useState, useEffect } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const PresetComponent = () => {
    const [currentPresetIndex, setCurrentPresetIndex] = useState(0);


  const presets = [
    {
        reward: "$3.00",
        title: "Ferrero",
        subtitle: "Chocolate Box Assorted Collection",
        size: "15pk 172g",
        image: "https://imagesjeb.s3.ap-northeast-1.amazonaws.com/ferrero-product.png",
        code: "ABC123",
    },
    {
        reward: "$5.00",
        title: "Whittakers",
        subtitle: "Chocolate Creamy Milk Block",
        size: "250g",
        image: "https://imagesjeb.s3.ap-northeast-1.amazonaws.com/whittakersProduct.png",
        code: "XYZ789",
    },
    {
        reward: "$6.00",
        title: "Dove",
        subtitle: "Body Wash Triple Moisturising",
        size: "1L",
        image: "https://imagesjeb.s3.ap-northeast-1.amazonaws.com/DoveProduct.png",
        code: "ABC123",
    },
    {
        reward: "$4.50",
        title: "Blue Frog",
        subtitle: "Keto Cereal Almond, Peanut And Raspberry",
        size: "350g",
        image: "https://imagesjeb.s3.ap-northeast-1.amazonaws.com/blueFrogProduct-removebg-preview.png",
        code: "XYZ789"
    },
];

useEffect(() => {
    const interval = setInterval(() => {
        setCurrentPresetIndex((prevIndex) => (prevIndex + 1) % presets.length);
    }, 9000); // Change every 7 seconds

    return () => clearInterval(interval); // Clean up on component unmount
}, []);

const currentPreset = presets[currentPresetIndex];

  return (
    <div className="phone-container">
      <div className="phone">
        <div className="phone-screen">
        <div className="details" style={{ opacity: 1 }}> 
                <div className="details-header1">{currentPreset.reward}</div>
                <div className="details-header1-5">towards one</div>
                <div className="details-header2">{currentPreset.title}</div>
                <div className="details-header2-sub">{currentPreset.subtitle}</div>
                <div className="details-header3">{currentPreset.size}</div>
                {/* <center> */}

      <LazyLoadImage
      effect="blur"
      style={{width: "120px", height: "140px", objectFit: "contain"}} 
      src={currentPreset.image} 
      alt=""/>
                {/* </center> */}
                <div className="details-border"></div>
                <div className="details-info-header">Code</div>
                <div className="details-code">{currentPreset.code}</div>
                <div className="details-header3-5">Expires: 25/10/23</div>
                <div className="details-header3-5">Valid with one purchase only</div>
                </div>
                {/* </center> */}
            </div>
      </div>
    </div>
  );
};

export default PresetComponent;
