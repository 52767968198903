import React from 'react';
import { useSelector } from 'react-redux';

import { Modal, Button, Box } from '@material-ui/core/';

import Arrow from "./../assets/arrow.png"

import CrossClose from "./../assets/crossClose.png"


// IMAGES:

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: "300px",
  // backgroundImage: "linear-gradient(to top, #fe9938, #fe9337, #fe8d37, #fe8737, #fe8137, #fe7c37, #fe7737, #fe7238, #fe6d38, #fe6838, #fe6338, #fe5e38)",
  backgroundColor: 'rgba(48, 1, 143, 0.8)', /* #30018F at 50% transparency */
  color: "#FFF",
  textAlign: "center",
  fontFamily: "Roboto, sans-serif",
  fontSize: "20px",
  // border: '0.5px solid #fff',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  // NEW:
  borderRadius: "7px"
};



export default function NestedModal({var2Value}) {
  const { post } = useSelector((state) => state.posts);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const FullStoreRangedList = () => [
    "New World Albany",
    "Pak n Save Westgate",
    "Woolworths Browns Bay",
    "Woolworths Greenlane",
    "Woolworths Howick",
    "Woolworths Lincoln Road",
    "Woolworths New Lynn",
    "Woolworths Silverdale",
    "Woolworths St Lukes",
    "Woolworths Takapuna",
    "Woolworths Westgate",
  ];



  return (
    <div>
      <div> 
      <p style={{color: "#A7A7A7", fontFamily: "Roboto, sans-serif", fontSize: "17px", margin: "25px 20px 0px" }}>Where can I redeem my reward?</p>
      <p style={{color: "#A7A7A7", fontFamily: "Roboto, sans-serif", fontSize: "14px", margin: "3px 5px 10px" }}>*subject to stock availability</p>
    <Button style={{    
      borderRadius: "7px",
      color: "#846CC5",
    fontFamily: "Roboto, sans-serif",
    fontSize: "0.95rem",
    fontStyle: "oblique 20deg",
    margin: "0px 0px 8px",
    padding: "2px 15px",
    fontWeight: 500,
      textTransform: "none"
  }} 
    onClick={handleOpen}>See stores <img src={Arrow} style={{width: "12px", marginLeft: "2px"}} alt=""/></Button>
    </div>
           
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
              <Box sx={{
    ...style,
    width: "auto",
    minWidth: "300px",
    maxHeight: "80vh", // Set a maximum height for the modal
    overflowY: "auto", // Enable vertical scrolling when the content overflows
  }}
>

{/* <Button style={{    
            backgroundColor: "#fff",
            color: "#fe5e38cc",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            margin: "10px",
            padding: "10px 30px",
            fontWeight: 900}} 
     onClick={handleClose}>Close</Button> */}

<div onClick={handleClose} className="closeButton"><img style={{  width: "25px"}} src={CrossClose} alt=""/></div>


          <p style={{fontSize: "15px", fontFamily: "Roboto"}}>Where can I purchase {post.title} {post.subTitle} using my reward code?  <br/> </p>
          <p style={{fontSize: "18px", margin: "5px 0px",  fontFamily: "Roboto"}}>You can <span style={{ fontWeight: 900 }}>only</span> redeem your code at the following stores: <br/> </p>
          <p style={{fontSize: "14px", padding: "0px", margin: "0px 0px 10px",  fontFamily: "Roboto"}}>*Subject to stock availability</p>
        

     {/* {post.storeList === 1 ? (

          <div className="srl-container">
          {post.storeList.map((store, index) => (
             <p key={index} className="srl-text">
            {store}
            </p>
            ))}
         </div>

) : (
  <div className="srl-container">
  {FullStoreRangedList.map((store, index) => (
     <p key={index} className="srl-text">
    {store}
    </p>
    ))}
 </div>

)} */}

<div className="srl-container">
      {Array.isArray(post.storeList) ? (
        post.storeList.map((store, index) => (
          <p key={index} className="srl-text">
            {store}
          </p>
        ))
      ) : (
        FullStoreRangedList().map((store, index) => (
          <p key={index} className="srl-text">
            {store}
          </p>
        ))
      )}
    </div>

         
          {/* <ChildModal var2Value={var2Value} /> */}


        </Box>
      </Modal>
    </div>
  );
}




// function ChildModal({var2Value}) {
    //   const { post } = useSelector((state) => state.posts);
    //   const [open, setOpen] = React.useState(false);
    
    
    //   const handleOpen = () => {
    //     setOpen(true);
    //   };
    //   const handleClose = () => {
    //     setOpen(false);
    //   };
    
    //   return (
    //     <React.Fragment>
    //       <Button style={{    
    //             backgroundColor: "#FFF",
    //             color: "#f79237",
    //             fontFamily: "Urbanist, sans-serif",
    //             fontSize: "18px",
    //             margin: "10px",
    //             padding: "10px 30px",
    //             fontWeight: 900}} 
    
    //       onClick={handleOpen}>REVEAL UNIQUE CODE</Button>
    //       <Modal
    //         hideBackdrop
    //         open={open}
    //         onClose={handleClose}
    //         aria-labelledby="child-modal-title"
    //         aria-describedby="child-modal-description"
    //       >
    //         <Box sx={{ ...style, width: "auto", minWidth: "300px" }}>
     
    //           <p style={{textTransform: "capitalise", fontSize: "24px"}}>{post.reward} towards one:</p>
    //           <p style={{textTransform: "capitalise", fontSize: "20px"}}>{post.title}</p>
    //           <p id="child-modal-title" style={{textTransform: "capitalise", fontSize: "25px"}}>{post.subTitle}</p>
    //           <p style={{color: "#FFF", fontFamily: "Urbanist, sans-serif", margin: "0px 0px 10px"}}>{post.attribute}</p>
         
    //           <img style={{maxWidth: "80px", marginBottom: "10px"}} src={post.rewardSelectedFile} alt=""/>
    //           <div style={{border: "1px solid #FFF"}}></div>
    //           <p style={{textTransform: "capitalise", fontSize: "12px", marginBottom: "10px", marginTop: "20px"}}>UNIQUE CODE:</p>
    //           <h2 style={{fontSize: "28px", marginTop: "0px"}}>{var2Value}</h2>
        
    //           <Typography style={{color: "#FFF", fontFamily: "Urbanist, sans-serif", margin: "20px"}}>Expires (1pm): <span style={{fontSize: "26px", fontWeight: 700}}>{post.uniqueCodeDateExpiry}</span></Typography>
    //           <p style={{fontSize: "14px"}} id="child-modal-description">
    //             Valid with one purchase only.
    //           </p>
    //           <Button style={{    
    //             backgroundColor: "#FFF",
    //             color: "#f79237",
    //             fontFamily: "Urbanist, sans-serif",
    //             fontSize: "18px",
    //             margin: "10px",
    //             padding: "10px 30px",
    //             fontWeight: 900}} 
    //      onClick={handleClose}>Close</Button>
    
    //      <p style={{fontSize: "12px", margin: "10px 20px"}}>
    //      This coupon is only valid upon purchase of {post.title} {post.subTitle}. Only one coupon can be used per product purchased. This coupon is not redeemable for any other products or exchangeable for cash, and must be used as is. This is a digitised coupon; Permitted to single use only. Once the coupon’s unique code has been claimed (completed transaction), it will no longer be valid.</p>
    //         </Box>
    //       </Modal>
    //     </React.Fragment>
    //   );
    // }
    