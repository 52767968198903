
import { AUTH } from '../constants/actionTypes';
import * as api from '../api/index.js';

export const signin = (formData, history) => async (dispatch) => {
  try {
    const { data } = await api.signIn(formData);

    dispatch({ type: AUTH, data });

    history.push('/');
  } catch (error) {
    console.log(error + " issue with ACTIONS for SIGN IN");
  }
};

export const signup = (formData, history) => async (dispatch) => {
  try {
    const { data } = await api.signUp(formData);

    dispatch({ type: AUTH, data });

    history.push('/');
  } catch (error) {
    console.log(error + " auth SIGN UP error in ACTION");
  }
};

// export const updateProfile = (id, age) => async (dispatch) => {
//   try {
//     const { data } = await api.updateProfile(id, age);

//     dispatch({ type: UPDATE, payload: data });
//     // console.log("success in the UPDATE_PROFILE actions");
//   } catch (error) {
//     // console.log(id, age)
//     // console.log(error + " issue with ACTIONS for updateProfile");
//   }
// };