import React from 'react';
// import { Link } from "react-router-dom"

import useStyles from './styles';
import "./styles.css"
import { LazyLoadImage } from 'react-lazy-load-image-component';

// DUMMY ASSET:
import DummyBarcode from "./assets/barcode.jpg"

const QuickView = ({post}) => {
    const user = JSON.parse(localStorage.getItem('profile'));
    const arrayClaimedComments = String(post.claimedcomments).split(',');

    const currentDate = new Date();
    const [day, month, year] = post.campaignEndDate.split('/');
    const campaignEndDate = new Date(`${year}-${month}-${day}`);

  // For the post card - simplicity otherwise the full ISO date will be displayed
    const formatCampaignEndDate = ({ post }) => {
      const dateObject = new Date(post.campaignEndDate);
      const day = dateObject.getUTCDate().toString().padStart(2, '0');
      const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0');
      const year = dateObject.getUTCFullYear();
  
      return `${day}/${month}/${year}`;
    };
  
    const formattedCampaignDate = formatCampaignEndDate({ post });

    const formatDrawDate = ({ post }) => {
      const dateObject = new Date(post.drawDate);
      const day = dateObject.getUTCDate().toString().padStart(2, '0');
      const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0');
      const year = dateObject.getUTCFullYear();
  
      return `${day}/${month}/${year}`;
    };
  
    const formattedDrawDate = formatDrawDate({ post });


    // COUNTS how many slots are remaining:
const difference = post.winnerSlots - post.claimedcomments.length;

    // Check if the total number of claimedComments exceeds the winnerSlots
    const winnerSlots = Number(post.winnerSlots);
    const claimedCommentsCount = arrayClaimedComments.length;


const InitialFilter = ({ post }) => {
  const classes = useStyles();
  const array = String(post.winners).split(',');
  const arrayClaimedComments = String(post.claimedcomments).split(',');

  let var2Value;
  let userWinner = false;

  let var2ValueCC;
  let hasClaimedCC = false;
  let userWinnerCC = false;

  array.forEach((item) => {
    const [var1, var2] = item.split('-');
    const resultId = user?.result?._id;

    if (resultId === var1) {
      userWinner = true;
      var2Value = var2;
    }
  });

  arrayClaimedComments.forEach((item) => {
    const [var1cc, var2cc, var3cc] = item.split('-');
    const resultId = user?.result?._id;

    if (resultId === var1cc) {
      userWinnerCC = true;
      var2ValueCC = var2cc;
      if (var3cc === 'CLAIMED') {
        hasClaimedCC = true;
      }
    }
  });

  return (
    <>
         {userWinner && !hasClaimedCC ? (
          <div>
                    {campaignEndDate && currentDate < campaignEndDate ? (
                      <div className="raise-on-hover">
                        <div className={classes.quickViewCard}>
                          {/* <Link to={`/quick-view-posts/${post._id}`} style={{ textDecoration: "none" }}> */}
                            {/* <p className={classes.quickViewTitleBrand}>{post.title}</p> */}

                            <p className={classes.quickViewBrand}>{post.reward}</p>
                            <p className={classes.towardsOneStyle}>towards one</p>
                            <p className={classes.quickViewSubBrand}>{post.title} {post.subTitle}</p>
                            <p className={classes.quickViewSubBrandSmall}>{post.attribute}</p>
                            {/* <p className={classes.quickViewSubBrand}>{post.attribute}</p> */}
{/*                 
                            {!post.expiredByTagCode ? (
                              <>
                                   <p className="infoHeader">Draw date:</p>
                            <p className="tagStyle">{formattedDrawDate}</p>
                              </>
                            ) : (
                              <></>
                            )} */}
              
      
                            <ClaimStatus post={post} />
            
                            {/* <p className="infoHeaderExpires">Expires: 1pm</p>
                            <p className="tagStyleExpires">{formattedCampaignDate}</p> */}
                          {/* </Link> */}
                        </div>
                      </div>
                     ) : null }
                     </div>
         ) : null}
    </>
  );
  
};


    const ClaimStatus = ({ post }) => {
      const classes = useStyles();
      const array = String(post.winners).split(',');
      const arrayClaimedComments = String(post.claimedcomments).split(',');
    
      let var2Value;
      let userWinner = false;
    
      let var2ValueCC;
      let hasClaimedCC = false;
      let userWinnerCC = false;
    
      array.forEach((item) => {
        const [var1, var2] = item.split('-');
        const resultId = user?.result?._id;
    
        if (resultId === var1) {
          userWinner = true;
          var2Value = var2;
        }
      });
    
      arrayClaimedComments.forEach((item) => {
        const [var1cc, var2cc, var3cc] = item.split('-');
        const resultId = user?.result?._id;
    
        if (resultId === var1cc) {
          userWinnerCC = true;
          var2ValueCC = var2cc;
          if (var3cc === 'CLAIMED') {
            hasClaimedCC = true;
          }
        }
      });
    
      return (
        <>
          {userWinner && (
            <>
              {hasClaimedCC ? (
            <div className={classes.claimedAlign}>
            <p className={classes.titleCollection}>Claimed</p>
            </div>
              ) : (
                <div style={{textAlign: "center"}}>
                {/* <p className={classes.titleCollection}>Winner: Not claimed</p> */}
                {/* <img style={{maxWidth: "100px", marginBottom: "0px"}} src={post.rewardSelectedFile} alt=""/> */}
                <div style={{width: "170px", height: "215px", margin: "0px auto"}}>
               <LazyLoadImage 
               effect="blur" 
               style={{maxWidth: "170px", marginBottom: "0px"}}
               src={post.rewardSelectedFile}
               alt=""
               />
               </div>


                {/* {
               <div style={{width: "250px", height: "81.91px", margin: "15px auto 5px"}}>
               <LazyLoadImage 
               effect="blur" 
               style={{maxWidth: "200px", marginBottom: "0px"}}
               src={DummyBarcode}
               alt=""
               />
               </div>
              } */}


                {claimedCommentsCount === winnerSlots ? (
                              <div style={{fontFamily: "Roboto", color: "#A7A7A7", fontFamily: "Roboto, sans-serif", margin: "20px 20px 3px", fontSize: "14px"}}><span style={{fontWeight: 700, fontSize: "18px"}}>{difference}</span> claim spot{difference !== 1 ? 's' : ''} remaining</div>
                ) : (
                  <>
                <p style={{ letterSpacing: "0.4px", color: "#A7A7A7", fontFamily: "Roboto, sans-serif", textTransform: "uppercase", fontSize: "10px", margin: "2px 0px 4px", fontWeight: 0}}>unique code</p>
                <h2 className={classes.uniqueCodeStyle}>{var2Value}</h2>
                {/* <div style={{color: "#FE5E38", fontFamily: "Urbanist, sans-serif", margin: "20px 20px 3px", fontSize: "16px"}}><span style={{fontWeight: 900, fontSize: "18px"}}>{difference}</span> claim spot{difference !== 1 ? 's' : ''} remaining</div> */}
                  </>
                )}

                {/* <p style={{color: "#FE5E38", fontFamily: "Urbanist, sans-serif", fontSize: "13px", margin: "3px 20px 15px" }}>This reward is still valid while there are still claim slots available</p> */}
                </div>
                // <DisplayDrawDate />
              )}
            </>
          )}
        </>
      );
      
    };

    
    return (
      <>
      <InitialFilter post={post} />
      </>
    );
}

export default QuickView