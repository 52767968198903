import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  smMargin: {
    margin: theme.spacing(1),
  },
  actionDiv: {
    textAlign: 'center',
  },

  headerStyle: {
    // color: "rgba(254, 94, 56, 0.8)", 
    color: "#808080",
    fontFamily: "Roboto, sans-serif", 
    margin: "0px 15px 0px", 
    padding: "20px 0px 3px",
    fontWeight: 500,
    fontSize: "1.15rem",
    textTransform: "capitalize",
    letterSpacing: "0.2px",
    // borderBottom: "1.5px solid #a7a7a7",
    // width: "184px"


  },

  // NEW POST ALIGNMENT, removed GRID:
  parentContainer: {
    minHeight: "190px",
    overflowX: "auto",
    whiteSpace: "nowrap",
    display: "flex",
    scrollbarWidth: "none",  // Firefox
    "-ms-overflow-style": "none",  // IE 10+
    "&::-webkit-scrollbar": {
      display: "none",  // WebKit (Chrome, Safari)
    },
    paddingTop: "0px",
    paddingBottom: "16px",
  },

  parentContainerSmall: {
    minHeight: "195px",
    overflowX: "auto",
    whiteSpace: "nowrap",
    display: "flex",
    scrollbarWidth: "none",  // Firefox
    "-ms-overflow-style": "none",  // IE 10+
    "&::-webkit-scrollbar": {
      display: "none",  // WebKit (Chrome, Safari)
    },
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  
  postGrid: {
    display: "inline-block",
    whiteSpace: "normal",
  },





  // postGrid: {
  //   display: "flex",
  //   flexDirection: "row",
  // },

  unavailableCardNotification: {
    color: "#30018F",
    margin: "12vh 8vw",
    padding: "0px",
    fontSize: "16px",
    textAlign: "center",
    fontFamily: "Urbanist, sans-serif",
    fontWeight: 700
  },

  unavailableCardNotificationSignup: {
    color: "#FE5E38",
    margin: "40px 20px",
    padding: "0px",
    fontSize: "19px",
    textAlign: "center",
    fontFamily: "Urbanist, sans-serif",
    fontWeight: 500
  },
  

  errorText: {
    padding: '12px 14px 0px 8px',
    marginTop: "2vh",
    marginBottom: 0,
    textAlign: "center",
    fontFamily: "Urbanist, sans-serif",
    color: "#30018F",
    fontSize: "16px",
    fontWeight: 700
  },

  errorTextButton: {
    backgroundColor: "#30018F",
    cursor: "pointer",
    color: "#FFF",
    width: "100px",
    fontSize: "16px",
    borderRadius: "8px",
    margin: "25px auto 0px",
    padding: "10px 0px"


  }
}));