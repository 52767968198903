
import React, { useState } from 'react'
import { Button } from '@material-ui/core';
import { Link } from "react-router-dom"

import useStyles from './styles';
import "./styles.css"

import { LazyLoadImage } from 'react-lazy-load-image-component';
import PresetComponent from './PresetComponent';
// import 'react-lazy-load-image-component/src/effects/blur.css';

// import ArrowOrange from "./../assets/arrowOrange.png"

const LandingPage = () => {
  const classes = useStyles();
  const [user] = useState(JSON.parse(localStorage.getItem('profile')));


  return (
    <>
    <div className="lp_section1">

      {/* <div className="lp_section1_image">
      <LazyLoadImage
      effect="blur"
      style={{maxWidth: "460px"}} 
      // src="https://coupo.s3.ap-northeast-1.amazonaws.com/section1Image.png" 
      src="https://coupo.s3.ap-northeast-1.amazonaws.com/section1ImagePurple.png"
      alt=""/>
      </div> */}

      <p className="lp_header1NEW"><span style={{  fontFamily: "Playwrite BE VLG", fontSize: "40px", fontWeight: 700 }}>Rewards</span> and <span style={{  fontFamily: "Playwrite BE VLG", fontSize: "40px", fontWeight: 700 }}>prizes</span> at your finger tips<span style={{  fontFamily: "Playwrite BE VLG", fontSize: "40px", fontWeight: 700 }}>.</span> </p>
     
      {/* <p className="lp_section1Header1SubNEW">Product discounts and vouchers up for grabs</p> */}
      {/* Join for your chance to win. */}

      {/* MOBILE RESPONSIVE: */}
      <PresetComponent/>

      {/* <div className="lp_section1_image_MOBILE">
      <LazyLoadImage
      effect="blur"
      style={{width: "420px"}} 
      src="https://coupo.s3.ap-northeast-1.amazonaws.com/section1ImagePurple.png"
      alt=""/>
      </div> */}

      </div>

      <div className="lp_section3">
        <p className="lp_header3"> How Coupo works</p>

        <div className="grid-container">
        <div className="grid-item"><p className="grid-item-number">1.</p><p className="grid-item-text-style"> Create an account by signing up. We're serious about privacy, so feel free to remain anonymous.</p><img className="lp_sectionImageStylePadded" src="https://coupo.s3.ap-northeast-1.amazonaws.com/CoupoStep1Phone.png" alt=""/></div>
        {/* <div className="grid-item"><p className="grid-item-number">Step 2. </p><p className="grid-item-text-style"> Once logged in, head over to your collection and click on a card that interests you.</p><img className="lp_sectionImageStylePadded" src="https://coupo.s3.ap-northeast-1.amazonaws.com/step2.png" alt=""/></div> */}

        <div className="grid-item"><p className="grid-item-number">2.</p><p className="grid-item-text-style"> Once logged in, head over to your collection and click on a card that interests you.</p><img className="lp_sectionImageStylePadded" src="https://coupo.s3.ap-northeast-1.amazonaws.com/CoupoStep2Phone.png" alt=""/></div>

        <div className="grid-item"><p className="grid-item-number">3.</p><p className="grid-item-text-style"> Answer the questions and hit <span style={{fontWeight: 700, fontSize: "18px"}}>Enter</span>. You'll then be redirected back to your collection.</p><img className="lp_sectionImageStylePadded" src="https://coupo.s3.ap-northeast-1.amazonaws.com/CoupoStep3Phone.png" alt=""/></div>
        <div className="grid-item"><p className="grid-item-number">4.</p><p className="grid-item-text-style">  There will now be a <span style={{fontWeight: 700, fontSize: "18px"}}>purple tick</span> on the card. Sit tight till the draw date, and we'll let you know if you've won.</p><img className="lp_sectionImageStylePadded" src="https://coupo.s3.ap-northeast-1.amazonaws.com/CoupoStep4Phone.png" alt=""/></div>
        </div>

        {!user?.result?.email &&
        <center>
        <Link to="/auth" style={{textDecoration: "none"}}>
        <Button style={{textTransform: "none", boxShadow: "rgba(0, 0, 0, 0.12) 0px 6px 16px", margin: "30px 0px 20px", borderRadius: "4px", backgroundColor: "#FFF", padding: "10px 40px", color: "#625caf", fontFamily: "Roboto, sans-serif", fontWeight: 900, fontSize: "16px", textDecoration: "none", textTransform: "uppercase"}}>Get started</Button>
        {/* <img style={{width: "20px", marginLeft: "7px"}} src={ArrowOrange} alt=""/> */}
        </Link>
        </center>
        }

      <div className="footerSection">
        <div className="footerResponsiveAlign">
      <div className="footerOptionLogo">Coupo</div>

      <Link style={{textDecoration: "none"}} to="/contact">
      <div className={classes.footerOption}>Contact us</div>
      </Link>

      <Link style={{textDecoration: "none"}} to="/terms-and-conditions">
      <div className={classes.footerOption}>Terms</div>
      </Link>

      <Link style={{textDecoration: "none"}} to="/privacy-policy">
      <div className={classes.footerOption}>Privacy Policy</div>
      </Link>
      </div>
      </div>
 

      </div>
      <div className="gutter"></div>
      </>
  )
}

export default LandingPage


// import React, {useState} from 'react'
// import { Button } from '@material-ui/core';
// import { Link } from "react-router-dom"

// import useStyles from './styles';
// import "./styles.css"
// import "./presetStyles.css"
// import PresetComponent from './PresetComponent';

// const LandingPage = () => {
//   const classes = useStyles();
//   const [user] = useState(JSON.parse(localStorage.getItem('profile')));


//   return (
//     <>
//     <div className="lp_section1">
//       <h1 className="lp_section1Header1">Rewards and prizes at your finger tips</h1>
     
//       <p className="lp_section1Header1Sub">Join for your chance to win</p>
      
//       <div className="lp_section1_image">
//       <img className="lp_section1_image_style" src="https://coupo.s3.ap-northeast-1.amazonaws.com/CoupoLanding1.png" alt=""/>
//       <img className="lp_section1_image_style" src="https://coupo.s3.ap-northeast-1.amazonaws.com/CoupoLanding2.png" alt=""/>
//       <img className="lp_section1_image_style" src="https://coupo.s3.ap-northeast-1.amazonaws.com/CoupoLanding3.png" alt=""/>
//       <img className="lp_section1_image_style" src="https://coupo.s3.ap-northeast-1.amazonaws.com/CoupoLanding4.png" alt=""/>
//       <img className="lp_section1_image_style" src="https://coupo.s3.ap-northeast-1.amazonaws.com/CoupoLanding5.png" alt=""/>
      
//       <img className="lp_section1_image_style" src="https://coupo.s3.ap-northeast-1.amazonaws.com/CoupoLanding1.png" alt=""/>
//       <img className="lp_section1_image_style" src="https://coupo.s3.ap-northeast-1.amazonaws.com/CoupoLanding2.png" alt=""/>
//       <img className="lp_section1_image_style" src="https://coupo.s3.ap-northeast-1.amazonaws.com/CoupoLanding3.png" alt=""/>
//       <img className="lp_section1_image_style" src="https://coupo.s3.ap-northeast-1.amazonaws.com/CoupoLanding4.png" alt=""/>
//       <img className="lp_section1_image_style" src="https://coupo.s3.ap-northeast-1.amazonaws.com/CoupoLanding5.png" alt=""/>

//       </div>


//       </div>

//       <div className="lp_section3">
//         <p className="lp_header3"> How it works </p>

//         {/* <div className="preGridItemLine"><p>Create a <span style={{fontFamily: "Orelega One"}}>Coupo</span> account by signing up.</p></div> */}

//         <div className="grid-container">
//         <div className="grid-item"><p className="grid-item-number">Step 1. </p><p className="grid-item-text-style"> Create an account by signing up.</p><img className="lp_sectionImageStylePadded" src="https://coupo.s3.ap-northeast-1.amazonaws.com/Step1Coupo.png" alt=""/></div>
//         <div className="grid-item"><p className="grid-item-number">Step 2. </p><p className="grid-item-text-style"> Head over to your collection and click on a card that interests you.</p><img className="lp_sectionImageStylePadded" src="https://coupo.s3.ap-northeast-1.amazonaws.com/Step2Coupo.png" alt=""/></div>
//         <div className="grid-item"><p className="grid-item-number">Step 3. </p><p className="grid-item-text-style">  Answer the questions and hit <span style={{fontWeight: 700, fontSize: "16px"}}>ENTER</span>.</p><img className="lp_sectionImageStylePadded" src="https://coupo.s3.ap-northeast-1.amazonaws.com/Step3Coupo.png" alt=""/></div>
//         <div className="grid-item"><p className="grid-item-number">Step 4.</p><p className="grid-item-text-style"> "After entering the draw, a draw date will be displayed. Sit tight until then, and we'll let you know if you've won.</p><img className="lp_sectionImageStylePadded" src="https://coupo.s3.ap-northeast-1.amazonaws.com/Step4Coupo.png" alt=""/></div>
//         </div>

//         {!user?.result?.email &&
//         <center>
//         <Link to="/auth" style={{textDecoration: "none"}}>
//         <Button style={{boxShadow: "rgba(0, 0, 0, 0.12) 0px 6px 16px", margin: "30px 0px 20px", borderRadius: "12px", backgroundColor: "#FFF", padding: "12px 50px", color: "#ff8e3e", fontFamily: "Urbanist, sans-serif", fontWeight: 700, fontSize: "18px", textDecoration: "none"}}>Get started</Button>
//         </Link>
//         </center>
//         }

//       <div className="footerSection">
//         <div className="footerResponsiveAlign">
//       <div className="footerOptionLogo">Coupo</div>

//       <Link style={{textDecoration: "none"}} to="/contact">
//       <div className={classes.footerOption}>Contact us</div>
//       </Link>

//       <Link style={{textDecoration: "none"}} to="/terms-and-conditions">
//       <div className={classes.footerOption}>Terms</div>
//       </Link>

//       <Link style={{textDecoration: "none"}} to="/privacy-policy">
//       <div className={classes.footerOption}>Privacy Policy</div>
//       </Link>
//       </div>
//       </div>

//       </div>
//       <div className="gutter"></div>
//       </>
//   )
// }

// export default LandingPage