import React from 'react';
import { Link } from "react-router-dom"

import useStyles from './styles';
import "./styles.css"

import Tick from "../../assets/tick.png"
import NotTick from "../../assets/notTick.png"
import Tick2 from "../../assets/tick2.png"

import { LazyLoadImage } from 'react-lazy-load-image-component';

const Post = ({post}) => {
    const classes = useStyles();
    const user = JSON.parse(localStorage.getItem('profile'));

    const currentDate = new Date();
    const [day, month, year] = post.campaignEndDate.split('/');
    const campaignEndDate = new Date(`${year}-${month}-${day}`);
  


  // For the post card - simplicity otherwise the full ISO date will be displayed
  const formatCampaignEndDate = ({ post }) => {
    const dateObject = new Date(post.campaignEndDate);
    const day = dateObject.getUTCDate().toString().padStart(2, '0');
    const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  const formattedCampaignDate = formatCampaignEndDate({ post });

  const formatDrawDate = ({ post }) => {
    const dateObject = new Date(post.drawDate);
    const day = dateObject.getUTCDate().toString().padStart(2, '0');
    const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  const formattedDrawDate = formatDrawDate({ post });




  const CheckUserSubmissionPost = () => {

      if (post.comments.length > 0) {
        return post.comments.find((comment) => comment === (user?.result?._id))
          ? ( 
            <ClaimStatus post={post}/>
          // <div className={classes.registeredAlignEntered}>
          // <img style={{width: "14px", height: "14px", marginTop: "5px"}} src={Tick} alt=""/>
          // <p className={classes.titleCollection}>Entered</p>
          // </div>
          ) : (
            <div className={classes.registeredAlign}>
                <img style={{ width: "25px", height: "25px", marginTop: "4px" }} src={NotTick} alt="" />
            {/* <p className={classes.titleCollectionNotEntered}>Not entered</p>     */}
            </div>
          );
      }
      return (
      <div className={classes.registeredAlign}>
          <img style={{ width: "25px", height: "25px", marginTop: "4px" }} src={NotTick} alt="" />
      {/* <p className={classes.titleCollectionNotEntered}>Not entered</p> */}
      </div>   
      )
    };



    const ClaimStatus = ({ post }) => {
      const classes = useStyles();
      const array = String(post.winners).split(',');
      const arrayClaimedComments = String(post.claimedcomments).split(',');
    
      let var2Value;
      let userWinner = false;
    
      let var2ValueCC;
      let hasClaimedCC = false;
      let userWinnerCC = false;
    
      array.forEach((item) => {
        const [var1, var2] = item.split('-');
        const resultId = user?.result?._id;
    
        if (resultId === var1) {
          userWinner = true;
          var2Value = var2;
        }
      });
    
      arrayClaimedComments.forEach((item) => {
        const [var1cc, var2cc, var3cc] = item.split('-');
        const resultId = user?.result?._id;
    
        if (resultId === var1cc) {
          userWinnerCC = true;
          var2ValueCC = var2cc;
          if (var3cc === 'CLAIMED') {
            hasClaimedCC = true;
          }
        }
      });

      
      return (
        <>
          {userWinner ? (
            <>
              {hasClaimedCC ? (
                <div className={classes.claimedAlign}>
                  {/* <img style={{ width: "12px", height: "12px", marginTop: "4px" }} src={Tick2} alt="" /> */}
                  <p className={classes.titleCollectionClaimed}>Claimed</p>
                </div>
              ) : (
                <div className={classes.registeredAlignEntered}>
                  <img style={{ width: "25px", height: "25px", marginTop: "4px" }} src={Tick} alt="" />
                  {/* <p className={classes.titleCollection}>Entered</p> */}
                </div>
              )}
            </>
          ) : (
            // Add your new conditional content here
            <div className={classes.registeredAlignEntered}>
              <img style={{ width: "25px", height: "25px", marginTop: "4px" }} src={Tick} alt="" />
              {/* <p className={classes.titleCollection}>Entered</p> */}
            </div>
          )}
        </>
      );

      // return (
      //   <>
      //     {userWinner && (
      //       <>
      //         {hasClaimedCC ? (
      //       <div className={classes.claimedAlign}>
      //       <img style={{width: "14px", height: "14px", marginTop: "5px"}} src={Tick2} alt=""/>
      //       <p className={classes.titleCollectionClaimed}>Claimed</p>
      //       </div>
      //         ) : (
      //           <div className={classes.registeredAlignEntered}>
      //           <img style={{width: "14px", height: "14px", marginTop: "5px"}} src={Tick} alt=""/>
      //           <p className={classes.titleCollection}>Entered</p>
      //           </div>
      //         )}
      //       </>
      //     )}
      //   </>
      // );
    };



    const ExpiresDateChecker = ({ post }) => {
      const classes = useStyles();
      const array = String(post.winners).split(',');
      const arrayClaimedComments = String(post.claimedcomments).split(',');
    
      let var2Value;
      let userWinner = false;
    
      let var2ValueCC;
      let hasClaimedCC = false;
      let userWinnerCC = false;
    
      array.forEach((item) => {
        const [var1, var2] = item.split('-');
        const resultId = user?.result?._id;
    
        if (resultId === var1) {
          userWinner = true;
          var2Value = var2;
        }
      });
    
      arrayClaimedComments.forEach((item) => {
        const [var1cc, var2cc, var3cc] = item.split('-');
        const resultId = user?.result?._id;
    
        if (resultId === var1cc) {
          userWinnerCC = true;
          var2ValueCC = var2cc;
          if (var3cc === 'CLAIMED') {
            hasClaimedCC = true;
          }
        }
      });

      
      return (
        <>
          {userWinner ? (
            <>
              {hasClaimedCC ? (
                <></>
              ) : (
              <>
              {post.expiredByTagCode === "expired" && 
                <p className="infoHeaderExpires">Expires <span className="tagStyle">{formattedCampaignDate}</span></p>
              }
              </>
              )}
            </>
          ) : (
            <>
            {post.expiredByTagCode === "expired" && 
              <p className="infoHeaderExpires">Expires <span className="tagStyle">{formattedCampaignDate}</span></p>
            }
            </>
          )}
        </>
      );

      // return (
      //   <>
      //     {userWinner && (
      //       <>
      //         {hasClaimedCC ? (
      //       <div className={classes.claimedAlign}>
      //       <img style={{width: "14px", height: "14px", marginTop: "5px"}} src={Tick2} alt=""/>
      //       <p className={classes.titleCollectionClaimed}>Claimed</p>
      //       </div>
      //         ) : (
      //           <div className={classes.registeredAlignEntered}>
      //           <img style={{width: "14px", height: "14px", marginTop: "5px"}} src={Tick} alt=""/>
      //           <p className={classes.titleCollection}>Entered</p>
      //           </div>
      //         )}
      //       </>
      //     )}
      //   </>
      // );
    };

    
    



    
    return (
      <>

        {/* <FilterForUser/> */}
        {/* THIS IS JUST THE STRAIGHTFORWARD, no need to input code check method - IF DOING THIS THEN NEED TO comment out the <FilterForUser/> line above */}        
        {campaignEndDate && currentDate < campaignEndDate ? (
  
          <div className="raise-on-hover">
             
            <div className={classes.card}>
              <div className={classes.itemContainer}>
            <Link to={`/posts/${post._id}`} style={{ textDecoration: "none" }}>
        

                <CheckUserSubmissionPost />


            <div style={{width: "145px", height: "187px", margin: "0px auto"}}>
            <LazyLoadImage 
               effect="blur" 
               style={{maxWidth: "145px", marginBottom: "0px"}}
               src={post.brandSelectedFile}
               alt=""
               />
               </div>

      

                {!post.expiredByTagCode ? (
                  <>
                       <p className="infoHeader">Draw date <span className="tagStyle">{formattedDrawDate}</span></p>
                {/* <p className="tagStyle">{formattedDrawDate}</p> */}
                  </>
                ) : (
                  <center>
                <div className="drawDateAlign">
                  <p className="drawDateText">Winners announced</p>
                </div>
                </center>
                )
                }
    
                {/* <DisplayDrawDate /> */}
                {/* <ClaimStatus post={post} /> */}

         

  

              <ExpiresDateChecker post={post}/>

    
              </Link>
              </div>
            </div>
          </div>
         ) : null } 
      </>
    );
}

export default Post