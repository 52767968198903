import React, { useState } from 'react';
import { Typography, TextField, Button } from '@material-ui/core/';
import Snackbar from '@material-ui/core/Snackbar';
import { useDispatch } from 'react-redux';

import { commentPost } from '../../../actions/posts';
import useStyles from './styles';


import "./styles.css"
import {useHistory} from "react-router-dom"
import EnterTheDraw from '../EnterTheDraw/ETD';
import PrizeModal from '../PrizeModal';

//IMAGES:
// import ArrowWhiteThick from "./../../assets/arrowWhiteThick.png"




const CPEForm = ({post}) => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('profile'));    
  const dispatch = useDispatch();
  const history = useHistory()


        const NoPrompts = () => {
            const [comment, setComment] = useState('');
            const [comments, setComments] = useState(post?.comments);
        
            const handleComment = async () => {
                const newComments = await dispatch(commentPost(`${user?.result?._id}`, post._id));
              setComment('');
              setComments(newComments);
            };
            
            return (
            <>
            <Button style={{ marginTop: '10px', padding: "10px 30px", fontFamily: "Urbanist, sans-serif", fontWeight: 700, color: "#FFF", backgroundImage: "radial-gradient(circle, #4C3AA1, #3F02A1)", fontSize: "20px"  }} fullWidth 
            color="white" variant="contained" onClick={handleComment}>
             Enter the draw
           </Button>
           </>
            )
          }


          const Prompts = () => {
            const user = JSON.parse(localStorage.getItem('profile'));
            const [no1, setNo1] = useState('');
            const [no1s, setNo1s] = useState(post?.no1s);

            const [no3, setNo3] = useState('');
            const [no3s, setNo3s] = useState(post?.no3s);

            const [yes1, setYes1] = useState('');
            const [yes1s, setYes1s] = useState(post?.yes1s);

            const [yes2, setYes2] = useState('');
            const [yes2s, setYes2s] = useState(post?.yes2s);

            const [yes3, setYes3] = useState('');
            const [yes3s, setYes3s] = useState(post?.yes3s);



            // const [comment, setComment] = useState('');
            // const [comments, setComments] = useState(post?.comments);
        
            // const [comment2, setComment2] = useState('');
            // const [comments2, setComments2] = useState(post?.comments2);

            // const [comment2b, setComment2b] = useState('');
            // const [comments2b, setComments2b] = useState(post?.comments2b);
    
            // const [comment3, setComment3] = useState('');
            // const [comments3, setComments3] = useState(post?.comments3);

            const [open, setOpen] = useState(false);
            const [userChoice, setUserChoice] = useState(null); // 0 for No, 1 for Yes

            const handleChoice = (choice) => {
              setUserChoice(choice);
              // You can add logic here to show/hide follow-up questions based on the user's choice
            };

    
            const handleCommentNo = async (e) => {
              // Check that all required fields have a value
              if (no1.trim() === '' || (post.prompt2Question && no1.trim() === '')) {
                // At least one field is empty. Show an error message.
                window.alert('You have missed completing an answer. Please complete all required fields before submitting.');
                return;
              }

              // SNACK BAR TRIGGER
              setOpen(true); 
            

              // All required fields have a value. Proceed with submitting the comment.
             
              const newComments = await dispatch(commentPost(`${user?.result?._id}`, post._id)); // <-- need to have two submissions, so can track if the user has already entered a submission (array checking string "")
              const newCommentsAdd = await dispatch(commentPost(`${user?.result?._id} || 0 || ${no1} || || ${no3} `, post._id)); // || ${chantRant} || ${selectedEmotion} ||  -- removed due to refresh issue


        
              setTimeout(() => {
                history.push("/posts");
              }, 1400); // Wait for 3 seconds before redirecting
           
              // no1('');
              // setNo1s(newComments);

              // no3('');
              // setNo3s(newComments);

              // setComment2('');
              // setComments2(newComments);

              // setComment2b('');
              // setComments2b(newComments);

              // setComment3('');
              // setComments3(newComments);
       
            };


            const handleCommentYes = async (e) => {
              // Check that all required fields have a value
              // if (yes1.trim() === '' || (post.prompt2Question && yes1.trim() === '') || (post.prompt3Question && yes2.trim() === '')) {
                if (yes1.trim() === '' || (post.prompt2Question && yes1.trim() === '') || (post.prompt3Question && yes2.trim() === '')) {
                // At least one field is empty. Show an error message.
                window.alert('You have missed completing an answer. Please complete all required fields before submitting.');
                return;
              }

              // SNACK BAR TRIGGER
              setOpen(true); 
            

              // All required fields have a value. Proceed with submitting the comment.
             
              const newComments = await dispatch(commentPost(`${user?.result?._id}`, post._id)); // <-- need to have two submissions, so can track if the user has already entered a submission (array checking string "")
              const newCommentsAdd = await dispatch(commentPost(`${user?.result?._id} || 1 || ${yes1} || ${yes2} || ${yes3} `, post._id)); // || ${chantRant} || ${selectedEmotion} ||  -- removed due to refresh issue


        
              setTimeout(() => {
                history.push("/posts");
              }, 1400); // Wait for 3 seconds before redirecting
           
              setYes1('');
              setYes1s(newComments);

              setYes1('');
              setYes2s(newComments);

              setYes3('');
              setYes3s(newComments);
       
            };
           
            
          
    

            return (
              <div style={{ width: '100%' }}>




          {!post.expiredByTagCode && <PrizeModal post={post} />}


           {/* <div className={classes.titleBorder}> </div> */}

    

          <p className={classes.promtpSectionHeader}>Answer the below to secure your spot in the draw</p>
          <p className={classes.promtpSectionHeaderSub}>High-quality answers will have a higher chance of winning</p>
          {/* <span style={{fontWeight: 700}}>Tip: </span> */}

          <div className={classes.promptSectionQuestionsContainer}>

          <p className={classes.promptQuestionCentered}>{post.prompt1Question}</p>

          <div className={classes.penetrationButtonContainer}>
        <Button
      style={{
        fontSize: "14px", 
        margin: "10px 7px 10px", 
        padding: "9px 30px", 
        // backgroundColor: "#fe5e38cc",
        // backgroundColor: "rgba(254, 94, 56, 0.9)", 

        backgroundColor: userChoice === 0 ? '#30018F' : '#846CC5',
        color: "#fff", 
        fontWeight: 700,
        border: "none",
        borderRadius: "3px",
        fontFamily: "Roboto, sans-serif",
        textTransform: "none",
        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.01),0px 2px 2px 0px rgba(0,0,0,0.1),0px 1px 5px 0px rgba(0,0,0,0.06)",
      }}
          // className={classes.penetrationButton}
          variant={userChoice === 0 ? 'contained' : 'outlined'}
          onClick={() => handleChoice(0)}
        >
          No
        </Button>
        <Button
      style={{
        fontSize: "14px", 
        margin: "10px 7px 10px", 
        padding: "9px 30px", 
        // backgroundColor: "rgba(254, 94, 56, 0.9)", 
        backgroundColor: userChoice === 1 ? '#30018F' : '#846CC5',

        color: "#fff", 
        fontWeight: 700,
        border: "none",
        borderRadius: "3px",
        fontFamily: "Roboto, sans-serif",
        textTransform: "none",
        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.01),0px 2px 2px 0px rgba(0,0,0,0.1),0px 1px 5px 0px rgba(0,0,0,0.06)",
      }}
        // className={classes.penetrationButton}
          variant={userChoice === 1 ? 'contained' : 'outlined'}
          onClick={() => handleChoice(1)}
        >
          Yes
        </Button>
      </div>




          {/* <TextField fullWidth rows={3} variant="outlined" label="" multiline value={comment} onChange={(e) => setComment(e.target.value)} />
          <br /> */}


                {/* You can conditionally render follow-up questions based on the user's choice */}
{userChoice === 0 && (
  <>
     {post.prompt2aQuestion ? (
            <>
          <Typography className={classes.promptQuestion}>{post.prompt2aQuestion}</Typography>
          <TextField fullWidth variant="standard" label="" multiline value={no1} onChange={(e) => setNo1(e.target.value)} />
          <br />
          </>
          ) : null
         }


{post.prompt3Question ? (
            <>
          <p className={classes.promptQuestion}><span className={classes.promptquestionBold}>optional* </span>any thoughts or feedback for the <span className={classes.promptquestionBold}>{post.title} </span>team?</p>
          <p className={classes.promptQuestionSub}>Feel free to share thoughts on advertising, product range, package design etc.</p>
          <TextField fullWidth variant="standard" label="" multiline value={no3} onChange={(e) => setNo3(e.target.value)} />
          <br />
          </>
          ) : null
         }


<div
  style={{ 
    backgroundImage: "radial-gradient(circle, #4C3AA1, #3F02A1)",
    margin: '5px auto 0px', 
    width: "98%",
    fontWeight: 700, 
    fontFamily: "Roboto, sans-serif", 
    // color: "rgba(254, 94, 56, 0.9)", 
    color: "#FFF",
    fontSize: "14px",
    borderRadius: "3px",
    padding: "12px 5.5px",
    cursor: "pointer",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.03),0px 2px 2px 0px rgba(0,0,0,0.08),0px 1px 5px 0px rgba(0,0,0,0.06)",
  }}
  fullWidth 
  disabled={userChoice === null}  // Disable the button if no choice has been made
  variant="contained"
  onClick={handleCommentNo}
>
  Enter
</div>

  </>

)}


      {userChoice === 1 && (
  <>
     {post.prompt2bQuestion ? (
            <>
          <p className={classes.promptQuestion}>{post.prompt2bQuestion}</p>
          <TextField fullWidth variant="standard" label="" multiline value={yes1} onChange={(e) => setYes1(e.target.value)} />
          <br />
          </>
          ) : null
         }

{post.prompt2cQuestion ? (
            <>
          <p className={classes.promptQuestion}>{post.prompt2cQuestion}</p>
          <TextField fullWidth variant="standard" label="" multiline value={yes2} onChange={(e) => setYes2(e.target.value)} />
          <br />
          </>
          ) : null
         }

{post.prompt3Question ? (
            <>
          <p className={classes.promptQuestion}><span className={classes.promptquestionBold}>optional* </span>any thoughts or feedback for the <span className={classes.promptquestionBold}>{post.title} </span>team?</p>
          <p className={classes.promptQuestionSub}>Feel free to share thoughts on advertising, product range, package design etc.</p>
          <TextField fullWidth variant="standard" label="" multiline value={yes3} onChange={(e) => setYes3(e.target.value)} />
          <br />
          </>
          ) : null
         }

<div
  style={{ 
    // backgroundColor: "#30018F",
    backgroundImage: "radial-gradient(circle, #4C3AA1, #3F02A1)",
    margin: '5px auto 0px', 
    width: "95%",
    fontWeight: 700, 
    fontFamily: "Roboto, sans-serif", 
    // color: "rgba(254, 94, 56, 0.9)", 
    color: "#FFF",
    fontSize: "14px",
    borderRadius: "3px",
    padding: "12px 5.5px",
    cursor: "pointer",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.03),0px 2px 2px 0px rgba(0,0,0,0.08),0px 1px 5px 0px rgba(0,0,0,0.06)",
  }}
  fullWidth 
  disabled={userChoice === null}  // Disable the button if no choice has been made
  variant="contained"
  onClick={handleCommentYes}
>
  Enter
</div>

  
  </>
      )}



          
  



{/* <Snackbar
        style={{textAlign: "center", fontFamily: "Urbanist, sans-serif", fontSize: "30px"}}
        open={open}
        onClose={(event, reason) => {
          setOpen(false);
        }}
        message="You have successfully entered the draw "
      /> */}

<Snackbar
  open={open}
  onClose={(event, reason) => {
  }}
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'center',
  }}
  ContentProps={{
    style: {
      backgroundColor: "#30018F", 
      color: '#FFF',
      fontWeight: 500,
      fontFamily: "Roboto, sans-serif",
      fontSize: '19px',
      display: "flex",
      justifyContent: "center",
      alignSelf: "center",
      alignItems: "center",
    },
  }}
  message={
    <div>You have successfully entered the draw</div>
  }
  
/>

              {/* <Button style={{ marginTop: '20px', fontWeight: 900, fontFamily: "Urbanist, sans-serif", color: "#fe8137", fontSize: "16px"}} fullWidth 
              // disabled={!comment.length}
               color="#FFF" variant="contained" onClick={handleComment}>
                ENTER
              </Button> */}



{/* {userChoice === 0 ? (
  <div
  style={{ 
    backgroundColor: "rgba(167, 167, 167, 0.05)",
    border: "2px solid rgba(254, 94, 56, 0.9)", 
    margin: '5px auto 0px', 
    width: "160px",
    fontWeight: 700, 
    fontFamily: "Urbanist, sans-serif", 
    color: "rgba(254, 94, 56, 0.9)", 
    fontSize: "14px",
    borderRadius: "4px",
    padding: "10px 5.5px",
    cursor: "pointer",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.03),0px 2px 2px 0px rgba(0,0,0,0.08),0px 1px 5px 0px rgba(0,0,0,0.06)",
    // width: "230px"
  }}
  fullWidth 
  disabled={userChoice === null}  // Disable the button if no choice has been made
  variant="contained"
  onClick={handleCommentNo}
>
  Enter
</div>
) : (

<div
  style={{ 
    backgroundColor: "rgba(167, 167, 167, 0.05)",
    border: "2px solid rgba(254, 94, 56, 0.9)", 
    margin: '5px auto 0px', 
    width: "160px",
    fontWeight: 700, 
    fontFamily: "Urbanist, sans-serif", 
    color: "rgba(254, 94, 56, 0.9)", 
    fontSize: "14px",
    borderRadius: "4px",
    padding: "10px 5.5px",
    cursor: "pointer",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.03),0px 2px 2px 0px rgba(0,0,0,0.08),0px 1px 5px 0px rgba(0,0,0,0.06)",
    // width: "230px"
  }}
  fullWidth 
  disabled={userChoice === null}  // Disable the button if no choice has been made
  variant="contained"
  onClick={handleCommentYes}
>
  Enter
</div>
)} */}




</div>


  
          </div>
            )    
          }


          const CheckUserSubmission = ({}) => {

          
            const PromptNoPromptChecker = () => {
              if (post.prompt1Question) {
                return <Prompts />;
              }
              // return <NoPrompts />;
              return <EnterTheDraw post={post} />;
            }

            const formatDrawDate = ({ post }) => {
              const dateObject = new Date(post.drawDate);
              const day = dateObject.getUTCDate().toString().padStart(2, '0');
              const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0');
              const year = dateObject.getUTCFullYear();
          
              return `${day}/${month}/${year}`;
            };
          
            const formattedDrawDate = formatDrawDate({ post });
        


          
            if (post.comments.length > 0) {
              return post.comments.find((comment) => comment === (user?.result?._id))
                ? (
                  <div className={classes.enteredNoteContainer}>
                    {/* <p className={classes.enteredNote}> 
                      Thank you for sharing your thoughts! 
                    </p> */}
                    <p className={classes.enteredNote2}>You have successfully entered the draw.</p>
                    <p className={classes.enteredNote2}>Prize results will be available on the <span style={{color: "#FFF", padding: "2px 4px", borderRadius: "3px", fontWeight: 500, fontSize: "1rem", color: "#30018F", fontFamily: "Roboto"}}>{formattedDrawDate}</span></p>
                   <p className={classes.enteredNote2}>Check back then or we'll let you know if you've won.</p>

                  </div>
                ) : (
               <div><PromptNoPromptChecker/></div>      
                );
            }
            return <><div><PromptNoPromptChecker/></div></>;
          };

  return (
    <>
    <div className={classes.promptSectionContainer}>
      <CheckUserSubmission/>
  </div>


  </>
  )
}

export default CPEForm








  // WORD COUNTER for goToPrompt: Start***
 
  // const [word1Count, setWord1Count] = useState(0);
  // const [word2Count, setWord2Count] = useState(0);
  // const [word3Count, setWord3Count] = useState(0);
  // const [word4Count, setWord4Count] = useState(0);
  // const [word5Count, setWord5Count] = useState(0);




  
  // useEffect(() => {
  //     if (post && post.subSegment1) {
  //         const word1Count = post.goToPrompts.filter(item => item === post.subSegment1).length;
  //         setWord1Count(word1Count || 0);
  //     }
  //     if (post && post.subSegment2) {
  //         const word2Count = post.goToPrompts.filter(item => item === post.subSegment2).length;
  //         setWord2Count(word2Count || 0);
  //     }
  //     if (post && post.subSegment3) {
  //         const word3Count = post.goToPrompts.filter(item => item === post.subSegment3).length;
  //         setWord3Count(word3Count || 0);
  //     }
  //     if (post && post.subSegment4) {
  //         const word4Count = post.goToPrompts.filter(item => item === post.subSegment4).length;
  //         setWord4Count(word4Count || 0);
  //     }
  //     if (post && post.subSegment5) {
  //         const word5Count = post.goToPrompts.filter(item => item === post.subSegment5).length;
  //         setWord5Count(word5Count || 0);
  //     }
  //   }, [post.goToPrompts, post]);
  
  // const totalVotes = (word1Count || 0) + (word2Count || 0) + (word3Count || 0) + (word4Count || 0) + (word5Count || 0);
  // const options = [post.subSegment1, post.subSegment2, post.subSegment3, post.subSegment4, post.subSegment5].filter(Boolean);
  // const counts = [word1Count, word2Count, word3Count, word4Count, word5Count].map(c => c || 0);
  // const image = [post.subSegment1Img, post.subSegment2Img, post.subSegment3Img, post.subSegment4Img, post.subSegment5Img].filter(Boolean);
  
   // WORD COUNTER for goTopPrompt: End***



  //  HANDLE COMMENT --- WITH COMMENTS added:
