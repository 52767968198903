import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  // card: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'center',
  //   alignItems: "center",
  //   borderRadius: '12px',
  //   height: "100%",
  //   width: "205px",
  //   position: 'relative',
  //   paddingBottom: "22px",
  //   margin: "18px 10px 0px",
  //   boxShadow: "rgba(0, 0, 0, 0.06) 0px 6px 16px",
  //   backgroundColor: "#FFF", 
  //   border: "1px solid rgb(240, 240, 240)",
  // },

  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '6px',
    height: '100%',
    width: '170px',
    position: 'relative',
    paddingBottom: '22px',
    margin: '12px 7px 0px',
    boxShadow: 'rgba(0, 0, 0, 0.04) 0px 6px 16px',
    backgroundColor: '#f8fafc',
    // border: '2px solid rgb(240, 240, 240)',
    border: "3px solid #fff"

  },
  
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1, /* This will allow the item container to expand vertically */
    marginTop: "0px"
  },
  

  media: {
    height: 100,
    objectFit: "contain",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "320px",
    textAlign: "center",
    margin: 15,
    padding: "20px",
  },


  cardBorder: {
    border: "solid",
    borderColor: "#fff",
    borderWidth: "2px",
    borderRadius: "6px",
    margin: "5px 14px 0px 14px",
  },
  fullHeightCard: {
    height: '100%',
  },
  grid: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px',
  },

  registeredAlignEntered: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    margin: "10px 9px 0px",
    // backgroundColor: "rgba(254, 94, 56, 0.6)",
    // backgroundColor: "#C8C8C8",
    // padding: "0px 1px 0px",
    borderRadius: "2px",
    // border: "0.5px solid rgba(254, 94, 56, 0.1)",
  },


  registeredAlign: {
    // display: "flex",
    // flexDirection: "row",
    // justifyContent: "center",
    // margin: "20px 30px 16px",

    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    margin: "10px 9px 0px",
    // backgroundColor: "rgba(254, 94, 56, 0.6)",
    // backgroundColor: "#C8C8C8",
    // padding: "0px 1px 0px",
    borderRadius: "2px",
    // border: "0.5px solid rgba(254, 94, 56, 0.1)",
  },

  claimedAlign: {
    // display: "flex",
    // flexDirection: "row",
    // justifyContent: "center",
    // margin: "20px 30px 16px",
    // padding: "2px 4px",
    color: "#787878",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "10px 30px 5px",
    // backgroundColor: "rgba(254, 94, 56, 0.3)",
    padding: "2px 5px 1px",
    borderRadius: "7px",
    // border: "1px solid #787878"
  },

  titleCollection: {
    margin: "4px 5px",
    textTransform: "uppercase",
    textAlign: "center",
    fontFamily: "Roboto, sans-serif",
    color: "#30018F",
    fontSize: "0.7rem",
    fontWeight: 700,
  },

  titleCollectionClaimed: {
    margin: "4px 5px 0px",
    textTransform: "uppercase",
    textAlign: "center",
    fontFamily: "Roboto, sans-serif",
    color: "#30018F",
    fontSize: "0.7rem",
    fontWeight: 700,

  },

  titleCollectionNotEntered: {
    margin: "8px 5px 0px",
    textTransform: "uppercase",
    textAlign: "center",
    fontFamily: "Roboto, sans-serif",
    // color: "rgba(254, 94, 56, 0.6)",
    color: "#A8A8A8",
    fontSize: "0.7rem",
    fontWeight: 700,
    // padding: "6px 16px 5px",
    borderRadius: "3px"
  },

  titleBrand: {
    padding: '0px 0px 6px',
    margin: "0px 0px 6px",
    // textAlign: "right",
    textAlign: "center",
    fontFamily: "Urbanist, sans-serif",
    // color: "#FFF",
    // color: "#FE5E38",
    color: "rgba(254, 94, 56, 0.8)",
    // color: "#808080",
    fontWeight: 700,
    fontSize: "18px"
  },

  description: {
    fontSize: 9,
    textAlign: "center",
    fontFamily: "Urbanist, sans-serif",
    color: "#FFF",
    padding: "0px 5px 5px"
  },
  couponDetails: {
    fontSize: 10,
    textAlign: "right"
  },

  subTitleContainer: {
    overflow: "scroll"
  },

  subTitle: {
    fontFamily: "Urbanist, sans-serif",
    color: "#FFF",
    margin: "15px 0px 10px 0px",
    fontSize: "10px",
    fontWeight: 900,
    textTransform: "uppercase",
    letterSpacing: "2px",
    textAlign: "center"
  }, 
  overlay2: {
    display: "flex",
    justifyContent: "center",
    padding: "5px"
  }


});



// import { makeStyles } from '@material-ui/core/styles';

// export default makeStyles({
//   media: {
//     height: 0,
//     paddingTop: '56.25%',
//     backgroundColor: 'rgba(0, 0, 0, 0.5)',
//     backgroundBlendMode: 'darken',
//   },
//   border: {
//     border: 'solid',
//   },
//   fullHeightCard: {
//     height: '100%',
//   },
//   card: {
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'space-between',
//     borderRadius: '15px',
//     height: '100%',
//     position: 'relative',
//   },
//   overlay: {
//     position: 'absolute',
//     top: '20px',
//     left: '20px',
//     color: 'white',
//   },
//   overlay2: {
//     position: 'absolute',
//     top: '20px',
//     right: '20px',
//     color: 'white',
//   },
//   grid: {
//     display: 'flex',
//   },
//   details: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     margin: '20px',
//   },
//   title: {
//     padding: '0 16px',
//   },
//   cardActions: {
//     padding: '0 16px 8px 16px',
//     display: 'flex',
//     justifyContent: 'space-between',
//   },
// });

