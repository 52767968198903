import React from 'react'
import { Link } from 'react-router-dom';
import useStyles from './styles';


const PrivacyPolicy = () => {
    const classes = useStyles();

  return (
    <div classname={classes.tc_container}>
        <h1 className={classes.tc_header1}>Coupo Limited Privacy Policy</h1>
        <h2 className={classes.tc_header2}>Who are we?</h2>
        <p className={classes.tc_text}>We Coupo (jebnz) Limited (“We” “Our” “Us” or “Coupo”). Coupo is focused on providing high-quality consumer research data to various business sectors and corporate clients of all sizes. We also have a large online consumer user base, which we use to provide real-time digital consumer insights to our clients through our Coupo Engagements. As the controller of your personal data, Coupo is responsible for its handling and protection.
        </p>


        <h2 className={classes.tc_header2}>What is our Privacy Commitment to you?</h2>
        <p className={classes.tc_text}>At Coupo, we value your privacy and are committed to protecting the personal data you provide us through our platform, websites, and services. This privacy notice explains how we collect, use, share, and secure the personal data you provide when you sign up, purchase a product or service, or participate in a Coupo Engagement. It also describes your privacy rights and how applicable laws may protect you. It is important that you read this privacy notice along with any other privacy notices or fair processing notices we may provide when collecting or processing personal data about you. These notices are intended to give you a full understanding of how and why we use your data. This privacy notice supplements any other notices and is not intended to override them.
        </p>

        <h2 className={classes.tc_header2}>What personal data do we collect about you?</h2>
        <ul>
        <li className={classes.tc_listText}>Information you give us. This is information about you that you provide by filling in forms on our sites or by corresponding with us by phone, email, or other means. It includes information you provide when you register to use our sites, participate in Coupo Engagements or other social media functions on our sites, and when you report a problem with our sites. This information may include your Identity data and your Contact data.</li>
        <li className={classes.tc_listText}>Information we collect about you. During each visit to our sites, we automatically collect the following information:</li>
        <li className={classes.tc_listText}>Technical data, including the IP address used to connect your computer to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system, and platform.</li>
        <li className={classes.tc_listText}>Information about your visit, including the full Uniform Resource Locators (URLs), clickstream to, through, and from our sites (including date and time), publications or news you viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page.</li>
        </ul>

        <p className={classes.tc_text}>In addition to collecting personal data, we also gather and use Aggregated Data such as statistical or demographic information for any purpose. This data is derived from your personal data but is not considered personal data under the law because it does not directly or indirectly identify you. For example, we may combine your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal data in a way that allows for direct or indirect identification, we will treat the combined data as personal data and handle it according to this privacy notice.
        We do not collect any Special Categories of Personal Data about you.
        If you do not provide the requested personal data, we may not be able to fulfill the terms of a contract or provide you with the requested goods or services. In such cases, we may have to cancel a product or service you have with us, but we will notify you if this occurs.
        </p>


        <p className={classes.tc_text}>Personal data, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).
        We may collect, use, store and transfer different kinds of personal data about you which we have grouped together follows:
        </p>

        <ul>
        <li className={classes.tc_listText}>Identity data - name (including first, last, maiden, and married names), marital status, gender, and suffixes.</li>
        <li className={classes.tc_listText}>Contact data - postal address, email address, and telephone number.</li>
        <li className={classes.tc_listText}>Special categories of personal data - ethnic/racial origin, health, genetics, political opinion, religion, sexual orientation, sex life, and biometrics (used for ID purposes).</li>
        <li className={classes.tc_listText}>Profile data - email and password, purchases or orders made by you, interests, preferences, feedback, and engagement responses.</li>
        <li className={classes.tc_listText}>Financial data - company bank account and/or payment card details.</li>
        <li className={classes.tc_listText}>Transaction data - details about payments to and from you, and other details of products and services you have purchased from us.</li>
        <li className={classes.tc_listText}>Technical data - IP addresses, login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system, and platform, and other technology on the devices you use to access the sites.</li>
        <li className={classes.tc_listText}>Usage data - information about how you use our sites, products, and services.</li>
        <li className={classes.tc_listText}>Marketing and Communications data - preferences in receiving marketing from us and our third parties, and communication preferences.</li>
        </ul>

        <p className={classes.tc_text}>Information we receive from other sources. This is information we receive about you if you use any of the other websites we operate or the other services we provide. We are working closely with third parties (including, for example, business partners, sub-contractors in technical, payment and delivery services, advertising networks, analytics providers and search information providers).</p>


        <h2 className={classes.tc_header2}>How do we collect your personal data?</h2>
        <p className={classes.tc_text}>We use different methods to collect data from and about you including through:</p>
        <ul>
        <li className={classes.tc_listText}>Direct interactions. You may provide us with your Identity data, Contact data, and Financial Data through various means, including filling out forms or corresponding with us by post, phone, email, or other means. This includes personal data you provide when you:</li>
        <li className={classes.tc_listText}>apply for our products or services;</li>
        <li className={classes.tc_listText}>create an account on our sites;</li>
        <li className={classes.tc_listText}>subscribe to our service or publications;</li>
        <li className={classes.tc_listText}>request marketing materials;</li>
        <li className={classes.tc_listText}>enter a competition, promotion, or engagement; or</li>
        <li className={classes.tc_listText}>give us feedback.</li>
        </ul>


        <h2 className={classes.tc_header2}>How do we use your personal data?</h2>
        <p className={classes.tc_text}>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>

        <ul>
        <li className={classes.tc_listText}>To perform the contract we are about to enter into or have entered into with you.</li>
        <li className={classes.tc_listText}>To fulfill our legitimate interests (or those of a third party) as long as they do not override your interests and fundamental rights.</li>
        <li className={classes.tc_listText}>To comply with legal or regulatory obligations.</li>
        </ul>


        <h2 className={classes.tc_header2}>Promotional offers from us:</h2>
        <p className={classes.tc_text}>We may use your Identity data, Contact data, Technical data, Usage data and Profile data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you (i.e. marketing).
        You will receive marketing communications from us if you have requested information from us or purchased services from us or if you provided us with your details when you entered a competition or registered for a promotion and, in each case, you have not opted out of receiving that marketing.
        </p>


        <h2 className={classes.tc_header2}>Change of purpose: </h2>
        <p className={classes.tc_text}>We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose.
        If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.
        Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.
        Disclosures of your personal data: We may have to share your personal data with the parties set out below for the purposes set out in the processing activities table above.
        </p>

        <p className={classes.tc_text}>We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>


        <h2 className={classes.tc_header2}>What security measures do we undertake to protect your personal data?</h2>
        <p className={classes.tc_text}>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.
        We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.
        What are our data retention and destruction policies?
        How long will you use my personal data for?
        We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.
        To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.
        </p>

        <h2 className={classes.tc_header2}>Business Transfers:</h2>
        <p className={classes.tc_text}>As we continue to develop our business, we might sell or buy additional assets. In such transactions, Coupo’s right to contact you for the purpose specified herein and the information you have voluntarily provided on our Sites, as part of a contract with us, or otherwise would generally be one of the transferred business assets. Any merger, sale or transfer of Coupo of substantially or all of its business assets, or other fundamental corporate transaction would be to an entity that agreed to comply with all of the material terms of our privacy notice. You will be notified via email and/or a prominent notice will be posted on our site if any transaction may result in any new proposed uses of your personal data which are incompatible with the uses set out in this Privacy Notice and of the choices you may have regarding your personal data.</p>

        <h2 className={classes.tc_header2}>Links:</h2>
        <p className={classes.tc_text}>From time to time we may offer visitors the ability to voluntarily link to other sites. Coupo does not review, and is not responsible for, the content or effect of the privacy policies of these sites.</p>

        <h2 className={classes.tc_header2}>Children’s privacy:</h2>
        <p className={classes.tc_text}>These sites are not intended for children and we do not knowingly collect data relating to children.</p>

        <h2 className={classes.tc_header2}>Your rights:</h2>
        <p className={classes.tc_text}>How do you access your information, or update, correct or delete your information?
        Upon request, Coupo will provide you with information about whether we hold any of your personal data.  You may access, correct, or request deletion of your personal data by emailing us at help@jebnz.com. We will respond to all requests within a reasonable timeframe.
        </p>

        <h2 className={classes.tc_header2}>How do I unsubscribe from marketing communications?</h2>
        <p className={classes.tc_text}>We and our selected third parties may process your personal data to send direct email marketing communications about our solutions. You may withdraw your consent at any time by clicking the “unsubscribe” link in the email communications we send to you.  Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a product/service purchase, product/service experience or other transactions.</p>


        <h2 className={classes.tc_header2}>Data retention:</h2>
        <p className={classes.tc_text}>If you unsubscribe from our mailing lists, we will no longer use your personal data to contact you, but in accordance with our backup procedures, we will retain your personal data until your personal data are eventually destroyed in accordance with our data retention and destruction policies and we will continue to employ the security procedures and technologies to keep your personal data safe.</p>

        <h2 className={classes.tc_header2}>How do you ask a question or make a complaint?</h2>
        <p className={classes.tc_text}>You can direct any questions or complaints about the use or disclosure of your personal data to our Privacy Contact at hi hello.jeb@gmail.com. We will investigate and attempt to resolve any complaints or disputes regarding the use or disclosure of your personal data within 30 days of receiving your complaint.
        We would, however, appreciate the chance to deal with your concerns before you approach the data protection regulator so please contact us at help@jebnz.com in the first instance.
        </p>

        <h2 className={classes.tc_header2}>Your other rights:</h2>
        <p className={classes.tc_text}>You may:</p>
        <ul>
        <li className={classes.tc_listText}>Request access to your personal data, and we may conduct ID checks before responding to your request.</li>
        <li className={classes.tc_listText}>Have your personal data erased, corrected, or restricted if it is inaccurate or requires updating. Under certain circumstances, you may also have the right to request the deletion of your personal data, but this may not always be possible due to legal requirements and other obligations and factors. You can update your personal data by contacting us at Toluna@Coupo.com or by writing to the address provided in the Privacy Contact section below.</li>
        <li className={classes.tc_listText}>Object to the processing of your personal data if we are not using it for the purposes outlined in this privacy policy.</li>
        </ul>


        <p className={classes.tc_text}>No fee usually required.
        You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.
        </p>

        <h2 className={classes.tc_header2}>What we may need from you:</h2>
        <p className={classes.tc_text}>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>

        <h2 className={classes.tc_header2}>Time limit to respond:</h2>
        <p className={classes.tc_text}>We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>

        <h2 className={classes.tc_header2}>Changes to our privacy notice:</h2>
        <p className={classes.tc_text}>This version was last updated on the date at the top of this privacy notice.
        We reserve the right to modify this privacy notice at any time, so please review it frequently. If we do decide to change our privacy notice, we will post those changes to this privacy statement on the homepage, or other places we deem appropriate so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it. If we make material changes to this policy, we will notify you here, by email, or by means of a notice on our home page prior to the change becoming effective.
        It is important that the personal data we hold about you is accurate and current, so please do keep us updated on any changes to your status or other personal data.
        </p>

            {/* FOOTER START */}
      <div className={classes.footerSection}>
      <div className={classes.footerOptionLogo}>Coupo.</div>

      <Link style={{textDecoration: "none"}} to="/contact">
      <div className={classes.footerOption}>Contact us</div>
      </Link>

      <Link style={{textDecoration: "none"}} to="/terms-and-conditions">
      <div className={classes.footerOption}>Terms</div>
      </Link>

      <Link style={{textDecoration: "none"}} to="/privacy-policy">
      <div className={classes.footerOption}>Privacy Policy</div>
      </Link>
      </div>
      {/* FOOTER END */}

    </div>
  )
}

export default PrivacyPolicy