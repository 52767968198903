import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, CircularProgress, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Post from './Post/Post';
import useStyles from './styles';
import PostUnredeemed from './Post/PostUnredeemed/PostUnredeemed';
import PostExpired from './Post/PostExpired/PostExpired';
import PostClaimed from './Post/PostClaimed/PostClaimed';
import PostNew from './Post/PostNew/PostNew';

const Posts = ({  setCurrentId }) => {
  const { posts, isLoading } = useSelector((state) => state.posts);
  const classes = useStyles();

  // const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));

  // Unavailable Cards CHECK:
  const filteredPosts = posts.filter((post) => {


    function formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    
    const currentDate = new Date();
    const todaysDate = formatDate(currentDate);
  
    
    const formatCampaignEndDate = ({ post }) => {
      const dateObject = new Date(post.visibilityEndDate);
      const day = dateObject.getDate().toString().padStart(2, '0');
      const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
      const year = dateObject.getFullYear();
      // return `${day}/${month}/${year}T00:00:00.000Z`; - attempting to preset the time proves to make the if (post.visibilityEndDate) INCOMPATIBLE and cancels the post carousels, and returns "NO POSTS atm and will let you know when" msg
      return `${day}/${month}/${year}`;
    };
    
    const formattedCampaignDate = formatCampaignEndDate({ post });
    
    

const dateParts1 = todaysDate.split('/');
const dateParts2 = formattedCampaignDate.split('/');

const dateToday = new Date(dateParts1[2], dateParts1[1] - 1, dateParts1[0]); // Months are 0-indexed in JavaScript Date objects
const campaignExpiry = new Date(dateParts2[2], dateParts2[1] - 1, dateParts2[0]);

const diffInMilliseconds = campaignExpiry.getTime() - dateToday.getTime();

console.log(diffInMilliseconds + " comparison difference between dates");

if (diffInMilliseconds < 0) {
  console.log("The campaign has expired.");
} else if (diffInMilliseconds === 0) {
  console.log("Today is the expiry date of the campaign.");
} else {
  console.log("The campaign is still active.");
}







    if (post.visibilityEndDate) {
      const dateToday = new Date(dateParts1[2], dateParts1[1] - 1, dateParts1[0]); // Months are 0-indexed in JavaScript Date objects
      const campaignExpiry = new Date(dateParts2[2], dateParts2[1] - 1, dateParts2[0]);
      
      const diffInMilliseconds = campaignExpiry.getTime() - dateToday.getTime();
      
      console.log(diffInMilliseconds + " comparison difference between dates");
      
      if (diffInMilliseconds < 0) {
        console.log("The campaign has expired.");
      } else if (diffInMilliseconds === 0) {
        console.log("Today is the expiry date of the campaign.");
      } else {
        console.log("The campaign is still active.");
      }

      // const campaignEndDate = new Date(post.campaignEndDate);
      // console.log(dateToday + "dateToday - attempting to get DATE TODAY UTC Local Time")
      // console.log(campaignExpiry + "Expiry Date Campaign")


      // return campaignExpiry > dateToday;   This is the correct comparison operator syntax howver the below returns the posts
      // return campaignExpiry < dateToday; 

      return diffInMilliseconds < 0
    }
    return false;
  });





// May 2024 OLD VERSION - NOW WITH ALL THE CONFUSION I HAVE REVERTED TO USING post.map instead of filteredPosts.map (effectively filteredPosts is now redundant)
  // const filteredPosts = posts.filter((post) => {
  //   if (post.campaignEndDate) {
  //     const campaignEndDate = new Date(post.campaignEndDate);
  //     return campaignEndDate > currentDate;   
  
  //   }
  //   return false;
  // });







  const history = useHistory();

  const reloadToPost = () => {
    history.push('/posts');
    window.location.reload(true); // Reload the page
  };


  
// const filteredPosts = posts.filter((post) => {
//   if (post.campaignEndDate) {
//     const [day, month, year] = post.campaignEndDate.split('/');
//     const campaignEndDate = new Date(`${year}-${month}-${day}`);
//     return campaignEndDate > currentDate;
//   }
//   return false; 
// });

  // NEED TO CREATE: notify list - add funtion (pushes UID to the NotifyList array: use LIKES methodology)


  // Search ERROR:
  if (!posts.length && !isLoading) return <div className={classes.errorText}>Something went wrong. try refreshing the page or go to the home screen.<div className={classes.errorTextButton} onClick={reloadToPost}>Go Back</div></div>;

  return (
    isLoading ? (

<CircularProgress
  style={{
    top: '20%',
    left: '48%', 
    position: 'absolute',
    color: '#30018F',
  }}
  size="2em"
/>


    ) : (
      !filteredPosts.length ? (
        <div className={classes.unavailableCardContainer}>
        <div className={classes.unavailableCardNotification}>Currently no cards available, please check back later or wait for an email notification</div>
        </div>
      ) : (
        <>
   
                <div className="parentContainerFull">
        <p className={classes.headerStyle}>All</p>
        <div className={classes.parentContainer}>
          {posts.map((post) => (
            <div key={post._id} className={classes.postGrid}>
              <Post post={post} setCurrentId={setCurrentId} />
  
            </div>
          ))}
        </div>
        </div>


     
       <div className="parentContainerFull">
       <p className={classes.headerStyle}>Claimed</p>
        <div className={classes.parentContainerSmall}>
          {posts.map((post) => (
            <div key={post._id} className={classes.postGrid}>
              <PostClaimed post={post} setCurrentId={setCurrentId} />
            </div>
          ))}
        </div>
        </div>


        <div className="parentContainerFull">
        <p className={classes.headerStyle}>New</p>
        <div className={classes.parentContainer}>
          {posts.map((post) => (
            <div key={post._id} className={classes.postGrid}>
              <PostNew post={post} setCurrentId={setCurrentId} />
            </div>
          ))}
        </div>
        </div>

        <div className="parentContainerFull">
        <p className={classes.headerStyle}>Winner</p>
        <div className={classes.parentContainerSmall}>
          {posts.map((post) => (
            <div key={post._id} className={classes.postGrid}>
              <PostUnredeemed post={post} setCurrentId={setCurrentId} />
            </div>
          ))}
        </div>
        </div>


        <div className="parentContainerFull">
        <p className={classes.headerStyle}>Unlucky</p>
        <div className={classes.parentContainerSmall}>
          {posts.map((post) => (
            <div key={post._id} className={classes.postGrid}>
              <PostExpired post={post} setCurrentId={setCurrentId} />
            </div>
          ))}
        </div>
        </div>
        </>
      )
    )
  );
}

export default Posts;


{/* <Grid style={{ justifyContent: "center" }} className={classes.container} container alignItems="stretch" spacing={4}>
{filteredPosts.map((post) => (
  <div key={post._id} className={classes.postGrid}>
    <Post post={post} setCurrentId={setCurrentId} />

  </div>
))}
</Grid> */}



// import React from 'react';
// import { useSelector } from 'react-redux';
// import { Grid, CircularProgress } from '@material-ui/core';

// import Post from './Post/Post';
// import useStyles from './styles';

// const Posts = ({setCurrentId}) => {
//     const { posts, isLoading, post } = useSelector((state) => state.posts);
//     const classes = useStyles();
//     if(!posts.length && !isLoading) return (<div className={classes.errorText}>Oops! No results, please double check you have searched correctly</div>);

//     return (
//         isLoading? <CircularProgress style={{margin: "15% 50%", 'color': '#f66238'}} size="4em"/> : (
//           !posts?.length ?  <CircularProgress style={{'color': '#f66238'}} size="4em" />: (

            
//             <Grid style={{justifyContent: "center"}} className={classes.container} container alignItems="stretch" spacing={4}>
//               {posts.map((post) => (
//                 // <Grid key={post._id} item xs={12} sm={12} md={7} lg={3}>
//                 <div className={classes.postGrid}>
//               <Post post={post} setCurrentId={setCurrentId} />
//                 </div>  
//                 // </Grid>
//               ))}
//             </Grid>
//            )
//         )
//     )
// }

// export default Posts
