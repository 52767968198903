import axios from 'axios';

const API = axios.create({ baseURL: 'https://jebnz.herokuapp.com/' });

// Ensures that user is logged in so user can properly interact with Jeb.
API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
  }

  return req;
});

export const fetchPosts = (page) => API.get(`/posts?page=${page}`);
export const fetchPost = (id) => API.get(`/posts/${id}`);
export const fetchPostsBySearch = (searchQuery) => API.get(`/posts/search?searchQuery=${searchQuery.search || 'none'}&tags=${searchQuery.tags}`);
export const createPost = (newPost) => API.post('/posts', newPost);
export const likePost = (id) => API.patch(`/posts/${id}/likePost`);
export const etdPost = (id) => API.patch(`/posts/${id}/etdPost`);
export const comment = (value, id) => API.post(`/posts/${id}/commentPost`, {value});
export const goToPrompt = (value, id) => API.post(`/posts/${id}/goToPromptPost`, {value});
export const updatePost = (id, updatedPost) => API.patch(`/posts/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/posts/${id}`);

// export const updatePostRedeem = (id, updatedPost) => API.patch(`/redeem/${id}`, updatedPost);

// "Hey database, get me some data and return it to me..."
export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);
// export const updateProfile = (formData) => API.put('/update-profile', formData);

export const updateProfile = (id, updateProfile) => API.patch(`/user/${id}`, updateProfile );
// export const updateProfile = (id, updatedProfile) => API.patch(`/user/update-profile/${id}`, updatedProfile );

// export const updateProfile = (id, formData) => API.patch(`/update-profile/${id}`, formData);



// export const addField = (value) => API.post(`/quiz1`, {value});

// export const createPostCPE = (id, newPost) => API.post(`/redeem/${id}`, newPost);