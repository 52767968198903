import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  promptSectionContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: "column",
    // margin: "25px 10vw",
    // padding: "15px 15px 25px",
    // border: "1px solid rgba(167, 167, 167, 0.2)",
    // borderRadius: "7px"
  },


  promptSectionQuestionsContainer: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    margin: "25px 2vw",
    padding: "20px 30px 25px",
    border: "1px solid rgba(167, 167, 167, 0.1)",
    borderRadius: "5px",
    backgroundColor: "rgba(167, 167, 167, 0.05)",
  },

  penetrationButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "15px"
  },

  penetrationButton: {
    cursor: "pointer",
    backgroundColor: "#fff",
    fontFamily: "Urbanist, sans-serif",
    padding: "10px 35px",
    margin: "20px",
    borderRadius: "4px",
    color: "#fe9337",
    fontWeight: 700,
    fontSize: "20px"
  },

  titleBorder: {
    border: "1px solid rgba(254, 94, 56, 0.2)",
    padding: "0px",
    borderRadius: "20px",
    margin: "0px 0px 30px"
  },


  promptSectionStatus: {
    fontSize: "25px",
    color: "#FFF",
    fontFamily: "Urbanist, sans-serif",
    margin: "10px 0px 20px",
    borderBottom: "2px solid #FFF"

  },

  promtpSectionHeader: {
    fontSize: "1rem",
    color: "#A7A7A7",
    fontFamily: "Roboto, sans-serif",
    margin: "10px 0px 0px",
    padding: "5px 35px 0px",
    // borderBottom: "2px solid #FFF",
    // textAlign: "left",
    fontWeight: 0,
    textAlign: "center",
  },

  promtpSectionHeaderSub: {
    fontSize: "0.95rem",
    color: "#A7A7A7",
    fontFamily: "Roboto, sans-serif",
    padding: " 0px 35px 2px",
    // borderBottom: "2px solid #FFF",
    textAlign: "center",
    fontWeight: 0,
    
  },

  promptQuestionCentered: {
    textAlign: "center",
    margin: "20px 0px 0px",
    fontSize: "1.1rem",
    color: "#9A9A9A",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 500
  },

  promptQuestion: {
    textAlign: "left",
    margin: "20px 0px 0px",
    fontSize: "1.1rem",
    color: "#9A9A9A",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 500
  },

  promptQuestionSub: {
    textAlign: "left",
    margin: "0px 0px 12px",
    fontSize: "0.9rem",
    color: "#9A9A9A",
    fontFamily: "Roboto, sans-serif"
  },

  promptquestionBold: {
    textAlign: "left",
    margin: "20px 0px 5px",
    fontSize: "15px",
    color: "#9A9A9A",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 700
  },


  enteredNoteContainer: {
    width: "100%",
    marginTop: "20px"
  },

  enteredNote: {
    color: "#fe5e38cc",
    fontFamily: "Urbanist, sans-serif",
    fontWeight: 500,
    fontSize: "22px",
    marginTop: "0px",
  },


  enteredNote2: {
    color: "#A7A7A7",
    fontFamily: "Roboto, sans-serif",
    fontSize: "1rem",
    marginTop: "0px"
    // display: "flex",
    // flexDirection: "column"
  },


  adSentimentResposeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "10px"
  },
  
  adSentimentInput: {
    marginBottom: "20px",
    fontFamily: "Urbanist, sans-serif",
    color: "#fe5e38cc",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    margin: "30px auto"

  },


  goToPromptImageStlye: {
    width: "130px",  
    // display: "block", 
    margin: "0",
    objectFit: "contain",
    display: "flex",
    alignItems: "left",
    justifyContent: "left"
  },

  
  align: {
    display: "flex"
  },


  // CHANT RANT:
  goToPromptContainer: {
    border: "2px solid #FFF",
    borderRadius: "20px",
    maxWidth: "600px",
    margin: "10px auto"
  },

  chantRantContainerBox: {
    maxHeight: "200px",
    overflow: "scroll"
  },



  goToPrompContainerFull: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: "10px auto",
    padding: "20px",
    border: "2px solid #FFF",
    borderRadius: "5px",
    maxWidth: "600px",
  },



  goToPromptHeader: {
    color: "#fe5e38cc",
    fontFamily: "Urbanist, sans-serif",
    fontWeight: 500,
    fontSize: "20px",
    margin: "40px auto 20px",
    paddingBottom: "8px",
    borderBottom: "2px solid #FFF",
    maxWidth: "600px"

  },

  

  pollResults: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0px auto",
    alignItems: "center"
  },

  resultsImage: {
    width: "60px",
    objectFit: "contain",
    marginLeft: "20px"
  },
  
  resultsPercentage: {
    maxWidth: "45px",
    margin: "0px 30px 0px 8px",
    fontSize: "13px",
    color: "#FFF",
    textAlign: "left",
    fontFamily: "Urbanist, sans-serif"
  },

  resultsVotes: {
    margin: "0px",
    fontSize: "12px",
    color: "#FFF",
    textAlign: "left",
    fontFamily: "Urbanist, sans-serif"
  },

  pollResultsOption: {
    width: "100%",
    marginBottom: "12px",
    display: "flex",
    flexDirection: "row",
    marginTop: "12px"
  },

  checkBoxText: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "20px",
    color: "#FFF",
    margin: "0px 20px"
  }
  
  // pollResultsBar: {
  //   height: "12px",
  //   backgroundColor: "#FFF",
  //   color: "white",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "flex-end",
  //   paddingRight: "0px",
  //   borderRadius: "20px",
  //   marginRight: "10px",
  //   width: "100px",
  //   marginTop: "5px"
  // },
  

//   optionText: {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     color: "#000"
// },





}));


  // commentsInnerContainer: {
  //   height: '200px',
  //   overflowY: 'auto',
  //   marginRight: '30px',
  // },