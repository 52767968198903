import {makeStyles} from "@material-ui/core/styles"

export default makeStyles((theme) => ({
    paper: {
        // marginTop: theme.spacing(8),
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
        // padding: theme.spacing(3),
        // borderRadius: "10px",
        // marginBottom: "80px"
        // marginTop: theme.spacing(8),
        marginTop: "2vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // padding: theme.spacing(6),
        padding: "20px 50px",
        borderRadius: "12px",
        marginBottom: "80px",
        // border: '1px solid rgb(240, 240, 240)',
        backgroundColor: "#FFF",
        // boxShadow: "rgba(0, 0, 0, 0.12) 0px 6px 16px",
        // boxShadow: 'rgba(0, 0, 0, 0.06) 0px 6px 16px',
    },


    paperNew: {
        marginTop: "3vh",
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        // padding: "30px 48px",
        borderRadius: "12px",
        marginBottom: "80px",
        padding: theme.spacing(3),
    },

    paperBorder: {
        // border: "4px solid #FE876B",
        padding: "30px",
        borderRadius: "10px"
    },
    root: {
        "& .MuiTextField-root": {
            margin: theme.spacing(1),
        },
    }, 
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    signInHeader: {
        letterSpacing: "-0.5px",
        fontFamily: "Roboto, sans-serif",
        textTransform: "none",
        fontSize: "22px",
        margin: "2px 0px 5px",
        paddingTop: "0px",
        color: "#1A014D",
        // color: "#A7A7A7",
        fontWeight: 700,
        // borderBottom: "2px solid #FE5E38",
        padding: "2px",
        textAlign: "center"
    },

    authDivider: {
        // border: "1px solid #FE5E38",
        width: "80%",
        margin: "10px 0px"
    },

    inputHeaders: {
        fontFamily: "Roboto, sans-serif",
        color: "#888",
        fontSize: "0.7rem",
        margin: "6px 0px 0px 10px",
        fontWeight: 700
    },

    authWelcome: {
        fontFamily: "Pacifico, sans-serif",
        color: "#30018F",
        fontSize: "24px",
        // CENTER
        display: "flex",
        justifyContent: "center",
        margin: "10px auto"
    },
    authWelcomeName: {
        fontFamily: "Pacifico, sans-serif",
        color: "#808080",
        fontSize: "25px",
        borderBottom: "2px solid #808080",
        marginBottom: "0px",
             // CENTER
             display: "flex",
             justifyContent: "center",
             margin: "0px auto"

    },
    authWelcomeSub: {
        fontFamily: "Roboto, sans-serif",
        color: "#818181",
        fontSize: "15px",
                     // CENTER
                     display: "flex",
                     justifyContent: "center",
                     margin: "15px 30px 7px",
                     textAlign: "center"
    },
    authWelcomeRequired: {
        fontFamily: "Urbanist, sans-serif",
        color: "#30018F",
        fontSize: "16px",
                     // CENTER
                     display: "flex",
                     justifyContent: "center",
                     margin: "10px auto 7px",
                     textAlign: "center" 
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(0.5),
    },

    fromControlStyle: {
      // margin: "10px 20px",
      width: '100%',
      marginTop: theme.spacing(3),
    },
    suburbNote: {
        fontFamily: "Urbanist, sans-serif",
        color: "#818181",
        fontSize: "13px",
                     // CENTER
                     display: "flex",
                     justifyContent: "center",
                    //  margin: "10px auto 7px",
                     textAlign: "center",
                     margin: "10px 20px 0px"
    },

    submit: {
        margin: "18px 0px 8px",
        // backgroundImage: "radial-gradient(circle, #5D4DAA, #4C3AA1, #3F02A1)",

        backgroundImage: "linear-gradient(to top, #4C3AA1, #30018F)",
        color: "#FFF",
        fontFamily: "Roboto, sans-serif",
        padding: "7px 0px",
        fontWeight: 700,
        borderRadius: "4px",
        textTransform: "none",
        textAlign: "center"

    },

    googleButton: {
        backgroundColor: '#30018F',
        color: "#FFF",
        fontFamily: "Urbanist, sans-serif",
        paddingTop: "9px"
    },

    signUpSwitch: {
        margin: "3px 0px 0px",
        padding: "2px 50px",
        fontFamily: "Roboto, sans-serif",
        fontWeight: 500,
        fontSize: "1rem",
        // color: "#fff",
        // color: "rgba(254, 94, 56, 0.9)",
        color: "#818181",
        textAlign: "center",
        cursor: "pointer",
        // backgroundColor: "rgba(254, 94, 56, 0.9)",
        // backgroundColor: "#F6F9FC",
        // backgroundImage: "radial-gradient(circle, #5D4DAA, #4C3AA1, #3F02A1)",

        // border: "1px solid #4C3AA1",
        borderRadius: "4px",
        boxShadow: "none"
    },

    forgottenPassword: {
        margin: "7px 10px",
        fontFamily: "Roboto, sans-serif",
        fontWeight: 500,
        fontSize: "12px",
        color: "#818181",
        textAlign: "center",
        cursor: "pointer"
    },

    forgottenPasswordInfo: {
        margin: "10px 10px",
        fontFamily: "Roboto, sans-serif",
        fontWeight: 500,
        fontSize: "12px",
        color: "#818181",
        textAlign: "center",
        cursor: "pointer"

    },



    // CHECKBOX:

    checkBoxText: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "13px",
        color: "#818181",
        margin: "5px 25px 5px",
        lineHeight: "1.1rem"
    },
}))








// import { makeStyles } from '@material-ui/core/styles';

// export default makeStyles((theme) => ({
//   paper: {
//     marginTop: theme.spacing(8),
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     padding: theme.spacing(2),
//   },
//   root: {
//     '& .MuiTextField-root': {
//       margin: theme.spacing(1),
//     },
//   },
//   avatar: {
//     margin: theme.spacing(1),
//     backgroundColor: theme.palette.secondary.main,
//   },
//   form: {
//     width: '100%', // Fix IE 11 issue.
//     marginTop: theme.spacing(3),
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 2),
//   },
//   googleButton: {
//     marginBottom: theme.spacing(2),
//   },
// }));