import React from 'react';
import { Link } from "react-router-dom"

import useStyles from '../styles';
import "../styles.css"
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Tick from "../../../assets/tick.png"
import Tick2 from "../../../assets/tick2.png"

const PostClaimed = ({post}) => {
    const classes = useStyles();
    const user = JSON.parse(localStorage.getItem('profile'));
    const arrayClaimedComments = String(post.claimedcomments).split(',');

    const currentDate = new Date();
    const [day, month, year] = post.campaignEndDate.split('/');
    const campaignEndDate = new Date(`${year}-${month}-${day}`);

  // For the post card - simplicity otherwise the full ISO date will be displayed
    const formatCampaignEndDate = ({ post }) => {
      const dateObject = new Date(post.campaignEndDate);
      const day = dateObject.getUTCDate().toString().padStart(2, '0');
      const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0');
      const year = dateObject.getUTCFullYear();
  
      return `${day}/${month}/${year}`;
    };
  
    const formattedCampaignDate = formatCampaignEndDate({ post });

    // const formatDrawDate = ({ post }) => {
    //   const dateObject = new Date(post.drawDate);
    //   const day = dateObject.getUTCDate().toString().padStart(2, '0');
    //   const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0');
    //   const year = dateObject.getUTCFullYear();
  
    //   return `${day}/${month}/${year}`;
    // };
  
    // const formattedDrawDate = formatDrawDate({ post });



  const CheckUserSubmissionPost = () => {

    if (post.comments.length > 0) {
      return post.comments.find((comment) => comment === (user?.result?._id))
        ? ( 
          // <ClaimStatus post={post}/>
          <>
          <div style={{marginTop: "32px"}}></div>
          <div className={classes.claimedAlign}>
   
          <img style={{ width: "11px", height: "11px", marginTop: "3px" }} src={Tick2} alt="" />
          <p className={classes.titleCollectionClaimed}>Claimed</p>
        </div>
        </>
        ) : (
          <div className={classes.registeredAlign}>
          <p className={classes.titleCollectionNotEntered}>Not entered</p>    
          </div>
        
        );
    }
    return (
    <div className={classes.registeredAlign}>
    <p className={classes.titleCollectionNotEntered}>Not entered</p>
    </div>   
    )
  };



    // COUNTS how many slots are remaining:
const difference = post.winnerSlots - post.claimedcomments.length;

    // Check if the total number of claimedComments exceeds the winnerSlots
    const winnerSlots = Number(post.winnerSlots);
    const claimedCommentsCount = arrayClaimedComments.length;


const InitialFilter = ({ post }) => {
  const classes = useStyles();
  const array = String(post.winners).split(',');
  const arrayClaimedComments = String(post.claimedcomments).split(',');

  let var2Value;
  let userWinner = false;

  let var2ValueCC;
  let hasClaimedCC = false;
  let userWinnerCC = false;

  array.forEach((item) => {
    const [var1, var2] = item.split('-');
    const resultId = user?.result?._id;

    if (resultId === var1) {
      userWinner = true;
      var2Value = var2;
    }
  });

  arrayClaimedComments.forEach((item) => {
    const [var1cc, var2cc, var3cc] = item.split('-');
    const resultId = user?.result?._id;

    if (resultId === var1cc) {
      userWinnerCC = true;
      var2ValueCC = var2cc;
      if (var3cc === 'CLAIMED') {
        hasClaimedCC = true;
      }
    }
  });

  return (
    <>
         {userWinner && hasClaimedCC ? (
          <div>
                    {/* {campaignEndDate && currentDate < campaignEndDate ? ( */}
                      <div className="raise-on-hover">
                        <div className={classes.card}>
                          <Link to={`/posts/${post._id}`} style={{ textDecoration: "none" }}>
                           

                            {/* <CheckUserSubmissionPost /> */}

                            <div style={{width: "110px", height: "125px", margin: "10px auto"}}>
            <LazyLoadImage 
               effect="blur" 
               style={{maxWidth: "110px", margin: "5px 0px 0px"}}
               src={post.rewardSelectedFile}
               alt=""
               />
               </div>

            
               {/* <p className="infoHeaderExpires">{currentDate > formattedCampaignDate ? 'Expired 1pm:' : 'Expires 1pm:'} <span style={{fontWeight: 900}}>{formattedCampaignDate}</span></p> */}
                          </Link>
                        </div>
                      </div>
                     {/* ) : null } */}
                     </div>
         ) : null}

{userWinner && hasClaimedCC === 0 ? (<p className="noPostsNotification">No claimed posts atm</p>) : ( null) }

    </>
  );
  
};


    const ClaimStatus = ({ post }) => {
      const classes = useStyles();
      const array = String(post.winners).split(',');
      const arrayClaimedComments = String(post.claimedcomments).split(',');
    
      let var2Value;
      let userWinner = false;
    
      let var2ValueCC;
      let hasClaimedCC = false;
      let userWinnerCC = false;
    
      array.forEach((item) => {
        const [var1, var2] = item.split('-');
        const resultId = user?.result?._id;
    
        if (resultId === var1) {
          userWinner = true;
          var2Value = var2;
        }
      });
    
      arrayClaimedComments.forEach((item) => {
        const [var1cc, var2cc, var3cc] = item.split('-');
        const resultId = user?.result?._id;
    
        if (resultId === var1cc) {
          userWinnerCC = true;
          var2ValueCC = var2cc;
          if (var3cc === 'CLAIMED') {
            hasClaimedCC = true;
          }
        }
      });
    
      return (
        <>
          {userWinner ? (
            <>
              {hasClaimedCC ? (
                <div className={classes.claimedAlign}>
                  <img style={{ width: "12px", height: "12px", marginTop: "4px" }} src={Tick2} alt="" />
                  <p className={classes.titleCollectionClaimed}>Claimed</p>
                </div>
              ) : (
                <div className={classes.registeredAlignEntered}>
                  {/* <img style={{ width: "11px", height: "11px", marginTop: "4px" }} src={Tick} alt="" /> */}
                  <p className={classes.titleCollection}>Entered</p>
                </div>
              )}
            </>
          ) : (
            // Add your new conditional content here
            <div className={classes.registeredAlignEntered}>
              {/* <img style={{ width: "11px", height: "11px", marginTop: "4px" }} src={Tick} alt="" /> */}
              <p className={classes.titleCollection}>Entered</p>
            </div>
          )}
        </>
      );
      
    };

    
    return (
      <>
      <InitialFilter post={post} />
      </>
    );
}

export default PostClaimed