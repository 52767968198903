import React from 'react'
import { Container } from '@material-ui/core';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import Navbar from './components/Navbar/Navbar';
import LandingPage from './components/LandingPage/LandingPage';
import Home from './components/Home/Home';
import Auth from './components/Auth/Auth';
import PostDetails from './components/PostDetails/PostDetails';
import Profile from './components/Profile/Profile';
import Contact from './components/Misc/Contact';
import TermsConditions from './components/Misc/TermsConditions';
import PrivacyPolicy from './components/Misc/PrivacyPolicy';
import QuickViews from './components/QuickViews/QuickViews';

// import Redeem from './components/Redeem/Redeem';
// import PostDetails2 from './components/PostDetails/PostDetails2';

const App = () => {
  const user = JSON.parse(localStorage.getItem('profile'));


  const IsLoggedIn = ({user}) => {
    if (user?.result?.id) {
      return true
    } return false
  }
  

 

  return (
    <BrowserRouter>
    {/* <Container maxWidth="xl"> */}
    {/* <center>
    <div style={{width: "90vw"}}> */}
      <Navbar/>
      <Switch>

      {/* <Route path="/" exact component={() => <Redirect to="/home" />} /> */}

      {IsLoggedIn ? (
            <Route path="/" exact component={Home} />
          ) : (
            <Route path="/" exact component={LandingPage} />
          )}

          
      {/* <Route path="/" exact component={LandingPage} /> */}
      <Route path="/home" exact component={LandingPage} />
        {/* <Route path="/collection" exact component={Home} /> */}
             {/* <Route path="/collection/:id" component={PostDetails}/> */}

        <Route path="/posts" exact component={Home} />
        <Route path="/posts/search" exact component={Home}/>

        <Route path="/quick-view" exact component={QuickViews}/>

        <Route path="/posts/:id" component={PostDetails}/>


        <Route path="/auth" exact component={Auth} />  


        {/* <Route path="/auth" exact component={() => (!user ? <Auth /> : <Redirect to="/collection" />)} /> */}

        
        {/* <Route path="/collection/search" exact component={Home}/> */}

     {/* <Route path="/" exact component={() => <Redirect to="/posts" />} /> */}
        {/* <Route path="/collection/:id" exact component={(PostDetails) => (!user ? <Auth /> : <Redirect to="/collection/:id" />)} /> */}


        {/* <Route path="/posts" exact component={Home} />
        <Route path="/posts/search" exact component={Home}/>
        <Route path="/posts/:id" component={PostDetails}/> */}
  
    {/* NEW: */}
        <Route path="/profile" component={Profile}/>


        {/* CONTACT  */}
        <Route path="/contact" exact component={Contact}/>
        <Route path="/terms-and-conditions" exact component={TermsConditions}/>
        <Route path="/privacy-policy" exact component={PrivacyPolicy}/>

        {/* SKIP the redeem functionality: */}
        {/* <Route path="/redeem" exact component={Redeem}/>
        <Route path="/redeem/search" exact component={Redeem}/> */}
        
        

      </Switch>
    {/* </Container> */}
    {/* </div>
    </center> */}
    </BrowserRouter>
  )
}
export default App