import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  media: {
    borderRadius: '10px',
    objectFit: 'contain',
    width: '100%',
    maxHeight: '230px',
    marginTop: "20px"
  },
  cardCD: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      flexDirection: 'column',

    },
  },
  borderPD: {
    border: "3px solid #FFF",
    borderRadius: "13px",
    // margin: "40px 20px",
  },

  ucsClaimedNotificationContainer: {
    margin: "50px 30px 70px"
  },


  ucsClaimedNotification: {
    color: "#fe5e38cc",
    fontFamily: "Roboto, sans-serif",
    fontSize: "0.9rem",
    letterSpacing: "0.5px",
    // margin: "55px 10px 95px"
    padding: "0px",
    margin: "0px 7px",
    textTransform: "uppercase"
  },

  ucsClaimedNotificationNotWinner: {
    backgroundImage: "radial-gradient(circle, #5D4DAA, #4C3AA1, #3F02A1)",
    fontFamily: "Roboto, sans-serif",
    fontSize: "0.9rem",
    // margin: "55px 10px 95px"
    padding: "15px 20px",
    margin: "10px 5px",
    textTransform: "uppercase",
    borderRadius: "4px",
    letterSpacing: "0.5px",
    color: "#fff",
    fontWeight: 500
  },

  ucsClaimedNotificationMissed: {
    // backgroundColor: "#FE5E38",
    fontFamily: "Roboto, sans-serif",
    fontSize: "0.9rem",
    // margin: "55px 10px 95px"
    padding: "10px 20px",
    margin: "20px 5px",
    textTransform: "uppercase",
    borderRadius: "4px",
    letterSpacing: "0.5px",
    fontWeight: 500
  },


  section: {
    borderRadius: '20px',
    margin: '20px 0px 50px',
    flex: 1,
    textAlign: "center"
  },

  title: {
    padding: '12px 14px 0px 8px',
    marginBottom: 0,
    textAlign: "center",
    fontFamily: "Roboto, sans-serif",
    color: "#fe5e38cc",
    fontSize: "25px",
    fontWeight: 700,
    letterSpacing: "1px"
  },
  
  titleBorder: {
    borderBottom: "1px solid rgba(167, 167, 167, 0.2)",
    padding: "0px",
    borderRadius: "20px",
    margin: "15px 25px"
  },

  subTitle: {
    padding: '12px 14px 0px 8px',
    marginBottom: 0,
    textAlign: "center",
    fontFamily: "Roboto, sans-serif",
    color: "#A7A7A7",
    fontSize: "24px",
    fontWeight: 700,
    letterSpacing: "1px"
  },

  winnerSlot: {
    padding: '12px 14px 0px 8px',
    marginBottom: "15px",
    textAlign: "center",
    fontFamily: "Roboto, sans-serif",
    color: "#fe5e38cc",
    fontSize: "24px",
    fontWeight: 700,
    letterSpacing: "1px"
  },

  imageSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px auto 0px',
    height: '180px',
    marginLeft: '0px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },

    couponCodePD: {
      fontFamily: "Roboto, sans-serif",
      color: "#fff",
      margin: "10px 40px 20px 14px",
      fontSize: "20px",
      // fontWeight: 900,
      // textTransform: "uppercase"
    },

    claimedTime: {
      fontFamily: "Roboto, sans-serif",
      color: "#FFF",
      margin: "10px 40px 20px 14px",
      fontSize: "12px",
      fontWeight: 900,
      textTransform: "uppercase"
    },


    actionBorder: {
      borderTop: "2px solid #FFF",
      width: "100px",

    },

    enteredNote: {
      color: "#FFF",
      fontFamily: "Orelega One, sans-serif",
      fontSize: "25px",
      // width: "100%"
    },
  },

  expiredStatement: {
    color: "#FFF",  
    fontFamily: "Roboto, sans-serif",
    fontSize: "22px",
    paddingTop: "30px",
    margin: "10px 30px"
  },


  paper: {
    padding: theme.spacing(2),
    fontFamily: 'Roboto, sans-serif'
  },

  signInFlag: {
    textAlign: "center",
    fontSize: "22px",
    color: "#FE5E38",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 500
  }
}));