import React, { useEffect } from 'react';
import { Paper, Typography, CircularProgress, Button } from '@material-ui/core/';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getPost } from '../../actions/posts';
import PostDetailsModal from './PostDetailsModal';
// import StoreRangingList from './StoreRangingList';

import "./styles.css"
import useStyles from './styles';
import Auth from '../Auth/Auth';
import CPEForm from './CPEForm.js/CPEForm';
// import PrizeModal from './PrizeModal';
// import EnterTheDraw from './EnterTheDraw/ETD';
// import CPEForm from './CPEForm.js/CPEForm';


import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// IMAGES:
import Arrow from "./../assets/arrow.png"
import Tick from "./../assets/tick.png"


const PostDetails = () => {
  const user = JSON.parse(localStorage.getItem('profile'));
  const { post, isLoading } = useSelector((state) => state.posts);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useParams();
  // const [yes, setYes] = useState(false);
  const currentDate = new Date(); // Get the current date

  useEffect(() => {
    dispatch(getPost(id));
  }, [id]);





  if (!post) return null;

  if (isLoading) {
    return (
      <CircularProgress style={{ 
        position: 'absolute', 
        top: '20%',
        left: '48%', 
        color: '#30018F' 
      }} 
        size="2em" />
    );
  }


  const drawDateConverted = (post) => {
    return new Date(
      post.drawDate
    );
  };
  

  const CompareDates = ({ post }) => {
    const currentDate = new Date(); // Get the current date
    // const campaignDate = new Date(post.formatedUniqueCodeDateExpiry);

    const campaignDate = new Date(
      post.uniqueCodeDateExpiry.split('/').reverse().join('-') + 'T00:00:00.000+00:00'
    );

  
    if (campaignDate.getTime() < currentDate.getTime()) {
      return (
        <p className={classes.expiredStatement}>This campaign expired on {post.uniqueCodeDateExpiry}</p>
        // <p className={classes.expiredStatement}>This campaign expired on {formattedCampaignDate}</p>
      )
    } else {
      return (
      <SplitArrayLaunch post={post} />
      )
    }
  };


  if (!user?.result?.name) {
    return (
      <>   
    <Auth/>
    </>

    )
  }




  const SplitArrayLaunch = ({ post }) => {
    const classes = useStyles();
    const array = String(post.winners).split(',');
    const arrayClaimedComments = String(post.claimedcomments).split(',');
  
    let var2Value;
    let userWinner = false;
  
    let var2ValueCC;
    let hasClaimedCC = false;
    let userWinnerCC = false;
  
    array.forEach((item) => {
      const [var1, var2] = item.split('-');
      const resultId = user?.result?._id;
  
      if (resultId === var1) {
        userWinner = true;
        var2Value = var2;
      }
    });
  
    arrayClaimedComments.forEach((item) => {
      const [var1cc, var2cc, var3cc] = item.split('-');
      const resultId = user?.result?._id;
  
      if (resultId === var1cc) {
        userWinnerCC = true;
        var2ValueCC = var2cc;
        if (var3cc === 'CLAIMED') {
          hasClaimedCC = true;
        }
      }
    });
  
    // Check if the total number of claimedComments exceeds the winnerSlots
    const winnerSlots = Number(post.winnerSlots);
    const claimedCommentsCount = arrayClaimedComments.length;
    // console.log(claimedCommentsCount)
  
    return (
      <>
        {userWinner ? (
          <>
            {hasClaimedCC ? (
              <div style={{display: "flex", flexDirection: "row", justifyContent: "center", margin: "35px 0px 0px"}}>
                {/* <img src={Tick} style={{width: "22px", height: "22px"}} alt=""/> */}
              <p className={classes.ucsClaimedNotificationNotWinner}>
                Claimed
              </p>
              </div>
            ) : (
              <>
                {claimedCommentsCount === winnerSlots ? (
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "center", margin: "35px 0px 0px"}}>
                     <p className={classes.ucsClaimedNotificationNotWinner}>
                     All {winnerSlots} winner slots have been claimed
                   </p>
                   </div>
                ) : (
                  <>
                    <PostDetailsModal post={post} var2Value={var2Value} />
                    {/* <StoreRangingList post={post} var2Value={var2Value} /> - put above now */}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <div style={{display: "flex", flexDirection: "row", justifyContent: "center", margin: "35px 0px 0px"}}>
          <p className={classes.ucsClaimedNotificationNotWinner}>
            Unfortunately you haven't won this time
          </p>
          </div>
        )}
        <div className="gutter"></div>
      </>
    );
  };
  

  const EnsureUserLikedFirst = ({}) => {
    const postDate = drawDateConverted(post).getTime();
    const currentDateValue = currentDate.getTime();
    const userLikedPost = post.comments.some((comment) => comment === user?.result?._id);
  
    if (postDate > currentDateValue || (post.comments.length > 0 && userLikedPost)) {
      return (
        // <Paper style={{ padding: "20px", borderRadius: '25px', backgroundImage: "linear-gradient(to top, #fe9938, #fe9337, #fe8d37, #fe8737, #fe8137, #fe7c37, #fe7737, #fe7238, #fe6d38, #fe6838, #fe6338, #fe5e38)" }} elevation={6}>
          <div style={{ 
            padding: "20px", 
            boxShadow: 'rgba(0, 0, 0, 0.04) 0px 6px 16px',
            backgroundColor: '#f8fafc',
            border: "2px solid #fff"
            }}>

          <div className={classes.card}>
            {/* <div className={classes.borderPD}> */}
              <div className={classes.imageSection}>

                
      <LazyLoadImage
      effect="blur"
      className={classes.media}
      src={post.brandSelectedFile} 
      alt=""/>

                {/* <img className={classes.media} src={post.brandSelectedFile || 'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png'} alt={post.title} />
              */}
              </div>
              <div className="section">
                {/* <Typography className={classes.title}>{post.title}</Typography> */}
                <div className={classes.titleBorder}> </div>
                <p style={{  color: "#A7A7A7", fontFamily: "Roboto, sans-serif", margin: "25px 10% 10px", fontSize: "15px" }}>{post.brandDescription}</p>
                
                <a style={{textDecoration: "none"}} href={post.brandLink} target="_blank" rel="noopener noreferrer">
                <div className="learnMoreAboutBrandButton">Learn more <img style={{width: "13px", margin: "0px", padding: "1px 5px 0px"}} src={Arrow} alt=""/></div>
                </a>

                <a style={{textDecoration: "none"}} href={post.productLink} target="_blank" rel="noopener noreferrer">
                <div className="learnMoreAboutProductButton">See product range <img style={{width: "13px", margin: "0px", padding: "2px 3px 0px"}} src={Arrow} alt=""/></div>
                </a>

                <div className={classes.titleBorder}> </div>
  
                {!post.expiredByTagCode ? (
                  <CPEForm post={post} />
                ) : null}
  
                {post.expiredByTagCode === "expired" && (
                  <CompareDates post={post} />
                )}
              </div>
            {/* </div> */}
          </div>
        </div>
      );
    } else {
      return (
        // <Paper style={{ padding: "20px", borderRadius: '25px', backgroundImage: "linear-gradient(to top, #fe9938, #fe9337, #fe8d37, #fe8737, #fe8137, #fe7c37, #fe7737, #fe7238, #fe6d38, #fe6838, #fe6338, #fe5e38)" }} elevation={6}>
        <div style={{ 
        padding: "20px", 
        boxShadow: 'rgba(0, 0, 0, 0.04) 0px 6px 16px',
        backgroundColor: '#f8fafc',
        border: "2px solid #fff"
        // border: '1px solid rgb(240, 240, 240)',
        }}>
          <div className={classes.card}>
            {/* <div className={classes.borderPD}> */}
              <div className={classes.imageSection}>
                <img className={classes.media} src={post.brandSelectedFile || 'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png'} alt={<Typography className={classes.title}>{post.title}</Typography>} />
              </div>
              <div className="section">
                {/* <Typography className={classes.title}>{post.title}</Typography> */}
                <div className={classes.titleBorder}> </div>
                <p style={{ color: "#A7A7A7", fontFamily: "Roboto, sans-serif", margin: "25px 10% 10px", fontSize: "15px" }}>{post.brandDescription}</p>
                <div className={classes.titleBorder}> </div>
  
                {/* <p className={classes.ucsClaimedNotificationMissed}> */}
                <div style={{display: "flex", flexDirection: "row", justifyContent: "center", margin: "35px 0px 0px"}}>
                <p className={classes.ucsClaimedNotificationNotWinner}>
                  You didn't enter the draw in time
                </p>
                </div>
              </div>
            {/* </div> */}
          </div>
        </div>
      );
    }
  };
  
  

  return (
    <>
    <EnsureUserLikedFirst/>
    <div className="gutter"></div>
    </>
  )
}

export default PostDetails







  
  // const SplitArrayLaunch = ({ post }) => {
  //   const classes = useStyles();
  //   const array = String(post.winners).split(',');
  //   const arrayClaimedComments = String(post.claimedcomments).split(',');
  
  //   let var2Value;
  //   // let hasClaimed = false;
  //   let userWinner = false;

  //   let var2ValueCC;
  //   let hasClaimedCC = false;
  //   let userWinnerCC = false;
  
  //   array.forEach((item) => {
  //     const [var1, var2] = item.split('-');
  //     const resultId = user?.result?._id;
  
  //     if (resultId === var1) {
  //       userWinner = true;
  //       var2Value = var2;
  //     }
  //   });


      
  //   arrayClaimedComments.forEach((item) => {
  //     const [var1cc, var2cc, var3cc] = item.split('-');
  //     const resultId = user?.result?._id;
  
  //     if (resultId === var1cc) {
  //       userWinnerCC = true;
  //       var2ValueCC = var2cc;
  //       if (var3cc === 'CLAIMED') {
  //         hasClaimedCC = true;
  //       }
  //     }
  //   });
  
  
  //   return (
  //     <>
  //       {userWinner ? (
  //         <>
  //           {hasClaimedCC ? (
  //             <p className={classes.ucsClaimedNotification}>
  //               You have claimed your unique code
  //             </p>
  //           ) : (
  //             <>
  //             <PostDetailsModal post={post} var2Value={var2Value} />
  //             <StoreRangingList post={post} var2Value={var2Value}/>
  //             </>
  //           )}
  //         </>
  //       ) : (
  //         <p className={classes.ucsClaimedNotification}>Sorry, unfortunately you haven't won this time.</p>
  //       )}
  //           <div className="gutter"></div>
  //     </>
  //   );
  // };
