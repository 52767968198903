import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
    lp_section1: {
        height: "55vh",
        backgroundImage: "linear-gradient(to top, #fe9938, #fe9337, #fe8d37, #fe8737, #fe8137, #fe7c37, #fe7737, #fe7238, #fe6d38, #fe6838, #fe6338, #fe5e38)",
        width: "100%",
        borderRadius: "20px",
        border: "10px solid #FFF"
  },
  lp_header1: {
      fontFamily: "Orelega One, sans-serif",
      fontSize: "75px",
      color: "#FFF",
      width: "50vw",
      margin: "10px 0px 0px 70px",
      paddingTop: "50px"
  },

  lp_header2Section1: {
    fontFamily: "Orelega One, sans-serif",
    fontSize: "80px",
    color: "#FFF",
    width: "80vw",
    margin: "10px 0px 0px 50px",
    paddingTop: "0px",
    textAlign: "right"
  },




  lp_section2: {
    height: "60vh",
    backgroundColor: "#FFF",
    width: "100%",
    borderRadius: "12px"
},
lp_header2: {
  fontFamily: "Orelega One, sans-serif",
  fontSize: "70px",
  color: "#30018F",
  width: "95vw",
//   margin: "10px 0px 0px 70px",
  paddingTop: "50px",
  textAlign: "center" 
},
lp_subheader2: {
    fontFamily: "Orelega One, sans-serif",
    fontSize: "60px",
    color: "#30018F",
},



lp_section2point5: {
  height: "70vh",
  backgroundColor: "#FFF",
  width: "100%",
  borderRadius: "20px",
  border: "8px solid #fe8137"
},

lp_header2point5: {
  fontFamily: "Orelega One, sans-serif",
  fontSize: "45px",
  color: "#fe8137",
    width: "95vw",
    paddingTop: "30px",
    textAlign: "center"
},

lp_header2point5Sub: {
  fontFamily: "Urbanist, sans-serif",
  fontSize: "30px",
  color: "#fe8137",
  width: "70vw",
    paddingTop: "10px",
    textAlign: "center",
    margin: "15px auto"
},

lp_header2point5SubText: {
  fontFamily: "Urbanist, sans-serif",
  fontSize: "25px",
  color: "#fe8137",
  width: "80vw",
    paddingTop: "10px",
    textAlign: "center",
    margin: "0px auto"
},
subTextstyled: {
  fontFamily: "Pacifico, sans-serif",
},

lp_section3: {
    height: "auto",
    backgroundImage: "linear-gradient(to top, #fe9938, #fe9337, #fe8d37, #fe8737, #fe8137, #fe7c37, #fe7737, #fe7238, #fe6d38, #fe6838, #fe6338, #fe5e38)",
    width: "100%",
    borderRadius: "20px",
    border: "10px solid #FFF",
    margin: "20px auto"
},
lp_header3: {
  fontFamily: "Orelega One, sans-serif",
  fontSize: "60px",
  color: "#FFFFFF",
  width: "95vw",
//   margin: "10px 0px 0px 70px",
  paddingTop: "50px",
  textAlign: "center",


},

lp_section3Content: {
  paddingTop: "20px",
  textAlign: "center",
  height: "auto"
},

lp_section3Text1: {
  fontFamily: "Urbanist, sans-serif",
  fontSize: "25px",
  color: "#FFF",
  marginBottom: "0px",
  marginTop: "20px"
},
lp_section3Text2: {
  fontFamily: "Urbanist, sans-serif",
  fontSize: "16px",
  color: "#FFF",
  marginTop: "4px",
  marginBottom: "20px"
},
lp_section3TextMore: {
  fontFamily: "Orelega One, sans-serif",
  fontSize: "20px",
  color: "#FFF",
  margin: "50px 0px"
},
moreSpan: {
  backgroundColor: "#FFF",
  color: "#fe8137",
  padding: "3px 10px",
  borderRadius: "5px 15px",
  cursor: "pointer",
  border: "2px solid #f79838",
},


// FOOTER:

footerSection: {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  textAlign: "center",
  marginTop: "20px" 
},
footerOptionLogo: {
  fontFamily: "Orelega One, sans-serif",
  fontSize: "24px",
  margin: "10px 20px 30px",
  color: "#30018F",

},

footerOption: {
  margin: "15px 15px 30px",
  fontFamily: "Urbanist, sans-serif",
  color: "#30018F",
  // borderBottom: "4px solid #fe8137",
  fontWeight: 700,
  fontSize: "14px"
},




// TERMS AND CONDITIONS - note same used for the Privacy Policy!:

tc_container: {
  margin: "30px"
},

tc_header1: {
  margin: "10px 0px 30px",
  padding: "0px 0px 8px",
  fontFamily: "Roboto, sans-serif",
  color: "#30018F",
  borderBottom: "1px solid #30018F",
  fontWeight: 700,
  fontSize: "22px"
},


tc_header2: {
  margin: "10px 0px 5px",
  fontFamily: "Roboto, sans-serif",
  color: "#30018F",
  fontWeight: 700,
  fontSize: "18px"
},

tc_text: {
  margin: "10px 0px 25px",
  fontFamily: "Roboto, sans-serif",
  color: "#30018F",
  fontWeight: 500,
  fontSize: "14px"
},

tc_listText: {
  margin: "5px 0px 0px",
  fontFamily: "Roboto, sans-serif",
  color: "#30018F",
  fontWeight: 500,
  fontSize: "12px"
}






}));