import React from 'react';
import { useSelector } from 'react-redux';

import { Modal, Button, Box, Typography } from '@material-ui/core/';
import StoreRangingList from './StoreRangingList';

import useStyles from './styles';
import "./styles.css"

// IMAGES:
import ArrowWhite from "./../assets/arrowWhite.png"
import CrossClose from "./../assets/crossClose.png"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  backgroundColor: 'rgba(48, 1, 143, 0.9)', /* #30018F at 50% transparency */
  color: "#FFF",
  textAlign: "center",
  fontFamily: "Roboto, sans-serif",
  fontSize: "20px",
  // border: '1px solid #fff',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  // NEW:
  borderRadius: "7px"
};


function ChildModal({var2Value}) {
  const { post } = useSelector((state) => state.posts);
  const [open, setOpen] = React.useState(false);


  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

    // For the post card - simplicity otherwise the full ISO date will be displayed
    const formatCampaignEndDate = ({ post }) => {
      const dateObject = new Date(post.campaignEndDate);
      const day = dateObject.getUTCDate().toString().padStart(2, '0');
      const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0');
      const year = dateObject.getUTCFullYear();
  
      return `${day}/${month}/${year}`;
    };
  
    const formattedCampaignDate = formatCampaignEndDate({ post });


  return (
    <React.Fragment>
      <Button style={{    
        borderRadius: "3px",
               backgroundColor: "#fff",
               color: "#30018F",
            fontFamily: "Roboto, sans-serif",
            fontSize: "0.9rem",
            margin: "10px",
            padding: "8px 22px",
            fontWeight: 700,
            textTransform: "uppercase",
            letterSpacing: "0.2px"
          
          }} 

      onClick={handleOpen}>Reveal unique code</Button>
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        {/* <Box sx={{ ...style, width: "auto", minWidth: "300px" }}> */}

        <Box
         sx={{
          ...style,
          width: "auto",
          minWidth: "300px",
          maxHeight: "80vh", // Set a maximum height for the modal
          overflowY: "auto", // Enable vertical scrolling when the content overflows
          }}
          >
          

          <div onClick={handleClose} className="closeButton"><img style={{  width: "25px"}} src={CrossClose} alt=""/></div>
          <p style={{fontFamily: "Roboto, sans-serif", fontWeight: 500, textTransform: "capitalise", fontSize: "24px"}}>{post.reward} towards one</p>
          <p style={{fontFamily: "Roboto, sans-serif", fontWeight: 500, textTransform: "capitalise", fontSize: "19px", margin: "0px"}}>{post.title}</p>
          <p id="child-modal-title" style={{fontFamily: "Roboto, sans-serif", textTransform: "capitalise", fontSize: "22px", margin: "8px 0px 12px"}}>{post.subTitle}</p>
          <p style={{color: "#FFF", fontFamily: "Roboto, sans-serif", margin: "0px 0px 10px", fontSize: "18px"}}>{post.attribute}</p>
     
          <img style={{maxWidth: "170px" }} src={post.rewardSelectedFile} alt=""/>
          <div style={{borderBottom: "1px solid #FFF"}}></div>
          <p style={{fontFamily: "Roboto, sans-serif", textTransform: "capitalise", fontSize: "10px", fontWeight: 700, marginBottom: "10px", margin: "20px 0px 6px"}}>UNIQUE CODE</p>
          <h2 className="uniqueCodeStyleModal">{var2Value}</h2>
    
          <Typography style={{color: "#FFF", fontFamily: "Roboto, sans-serif", margin: "20px"}}>Expires: <span style={{fontSize: "20px", fontWeight: 700}}>{formattedCampaignDate}</span></Typography>
          <p style={{fontFamily: "Roboto, sans-serif", fontSize: "14px", margin: "2px 0px"}} id="child-modal-description">
            Valid with one purchase only
          </p>
          {/* <Button style={{    
            borderRadius: "25px",
              backgroundColor: "#fff",
              color: "#fe5e38cc",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            margin: "10px",
            padding: "5px 30px",
            fontWeight: 900}} 
     onClick={handleClose}>Close</Button> */}

     <p style={{fontFamily: "Roboto, sans-serif", fontSize: "11px", margin: "10px 20px"}}>
     This coupon is only valid upon purchase of {post.title} {post.subTitle}. Only one coupon can be used per product purchased. This coupon is not redeemable for any other products or exchangeable for cash, and must be used as is. This is a digitised coupon; Permitted to single use only. Once the coupon’s unique code has been claimed (completed transaction), it will no longer be valid.</p>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default function NestedModal({var2Value}) {
  const { post } = useSelector((state) => state.posts);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

// COUNTS how many slots are remaining:
const difference = post.winnerSlots - post.claimedcomments.length;


  return (
    <div>
      <div> 
      {/* <p style={{color: "#FFF", fontFamily: "Orelega One, sans-serif", fontSize: "28px", margin: "30px 0px 7px"}}>Congratulations</p>  */}
      {/* <p className="pdm_header">Congratulations</p>  */}
      <p className="pdm_headerSub">As a prize winner, you have received</p> 
       {/* <Typography style={{backgroundColor: "#FFF", color: "#f67e38", fontFamily: "Urbanist, sans-serif", fontWeight: 700, textTransform: "uppercase", letterSpacing: "2px", fontSize: 22, padding: "10px 0px", margin: "0px auto 10px", minWidth: "80px", maxWidth: "180px", borderRadius: "10px"}}>{post.reward}</Typography> */}
       <div className="pdm_rewardStyle">{post.reward}</div>
       
       <p style={{color: "#A7A7A7", fontFamily: "Roboto, sans-serif", fontSize: "18px"}}>towards one:</p>

       <div className={classes.imageSection}>
          <img className={classes.media} src={post.rewardSelectedFile || 'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png'} alt={post.title} />
        </div>

        <div className="subTitle">{post.title} {post.subTitle}</div>
        <Typography style={{color: "#A7A7A7", fontFamily: "Roboto, sans-serif", margin: "12px 0px 0px", fontSize: "20px"}}>{post.attribute}</Typography>
        <p className="postDescriptionStyle">{post.description}</p>
          
          <div className={classes.titleBorder}> </div>

          <StoreRangingList/>

          <div className={classes.titleBorder}> </div>

          <div style={{color: "#A7A7A7", fontFamily: "Roboto, sans-serif", margin: "20px 15px 10px", fontSize: "15px"}}> There {difference > 1 ? 'are' : 'is'} <span style={{fontWeight: 900, fontSize: "16px"}}>{difference}</span> claim spot{difference !== 1 ? 's' : ''} remaining. Your reward is still valid while there are still claim slots available.</div>
{/* 
          <p style={{color: "#A7A7A7", fontFamily: "Urbanist, sans-serif", fontSize: "16px", margin: "5px 20px 15px" }}>Your reward is still valid while there are still claim slots available</p> */}



    <Button style={{    
    borderRadius: "3px",
    backgroundImage: "radial-gradient(circle, #5D4DAA, #4C3AA1, #3F02A1)",
    color: "#FFF",
    fontFamily: "Roboto, sans-serif",
    fontSize: "0.9rem",
    margin: "10px",
    padding: "8px 22px",
    fontWeight: 500,
    textTransform: "uppercase",
    letterSpacing: "0.2px"
  }} 
    onClick={handleOpen}>Redeem my reward</Button>
    {/* <img src={ArrowWhite} style={{width: "14px", marginLeft: "6px"}} alt=""/> */}

    <div style={{    
    color: "#A7A7A7",
    fontFamily: "Roboto, sans-serif",
    margin: "20px 35px",
    fontSize: "11px"
    }}>{post.termsConditions}</div>

    </div>
           
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        {/* <Box sx={{ ...style, width: 400 }}> */}

        <Box sx={{
    ...style,
    width: "auto",
    minWidth: "300px",
    maxHeight: "80vh", // Set a maximum height for the modal
    overflowY: "auto", // Enable vertical scrolling when the content overflows
  }}
>
          <div onClick={handleClose} className="closeButton"><img style={{  width: "25px"}} src={CrossClose} alt=""/></div>
          <p style={{fontSize: "18px", fontFamily: "Roboto"}}>Ready to purchase your {post.title} {post.subTitle}?  <br/> </p>
          <p style={{fontSize: "18px", fontFamily: "Roboto"}}>Take the product to the checkout counter and present it, alongside the unique code, to your service or checkout person to redeem your reward.</p>

          <p style={{fontSize: "14px", fontFamily: "Roboto"}}>Once you redeem your unique code, it will no longer be valid</p>
          <ChildModal var2Value={var2Value} />
        </Box>
      </Modal>
    </div>
  );
}