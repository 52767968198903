import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, CircularProgress } from '@material-ui/core';

import QuickView from './QuickView/QuickView';
import useStyles from './styles';

const QuickViews = ({  setCurrentId }) => {
  const { posts, isLoading } = useSelector((state) => state.posts);
  const classes = useStyles();


  // const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));

  // Unavailable Cards CHECK:
  const currentDate = new Date();


  const filteredPosts = posts.filter((post) => {
    if (post.campaignEndDate) {
      const campaignEndDate = new Date(post.campaignEndDate);
      return campaignEndDate > currentDate;
    }
    return false;
  });



  // Search ERROR:
  if (!posts.length && !isLoading) return <div className={classes.errorText}>Something went wrong. Try refreshing the page.</div>;

  return (
    isLoading ? (

      <CircularProgress
  style={{
    marginTop: "20%",
    left: '48%',
    position: 'absolute',
    color: '#30018F',
  }}
  size="2em"
/>


    ) : (
      !filteredPosts.length ? (
        <div className={classes.unavailableCardContainer}>
        <div className={classes.unavailableCardNotification}>Currently no cards available, please check back later or wait for an email notification</div>
        </div>
      ) : (
        <>
        <Grid style={{ justifyContent: "center", marginTop: "5vh", }} className={classes.container} container alignItems="stretch">
          {filteredPosts.map((post) => (
            <div key={post._id} className={classes.qv_postGrid}>
              <QuickView post={post} setCurrentId={setCurrentId} />
  
            </div>
          ))}
        </Grid>
           <p style={{ fontStyle: "oblique 20deg", color: "#30018F", letterSpacing: "0.2px", fontFamily: "Roboto, sans-serif", textAlign: "center", paddingTop: "80px", marginTop: "18vh", fontWeight: 700}}>No cards remaining</p>
        </>
      )
    )
  );
}

export default QuickViews;
