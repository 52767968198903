import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: "row"
  },
  smMargin: {
    margin: theme.spacing(1),
  },
  actionDiv: {
    textAlign: 'center',
  },

  // NEW POST ALIGNMENT, removed GRID:
  qv_postGrid: {
    display: "flex",
    flexDirection: "column",
    margin: "0px"
  },

  unavailableCardNotification: {
    color: "#30018F",
    margin: "12vh 8vw",
    padding: "0px",
    fontSize: "16px",
    textAlign: "center",
    fontFamily: "Urbanist, sans-serif",
    fontWeight: 700
  },

  unavailableCardNotificationSignup: {
    color: "#30018F",
    margin: "10vh 8vw",
    padding: "0px",
    fontSize: "19px",
    textAlign: "center",
    fontFamily: "Urbanist, sans-serif",
    fontWeight: 500
  },
  

  errorText: {
    padding: '12px 14px 0px 8px',
    marginTop: "2vh",
    marginBottom: 0,
    textAlign: "center",
    fontFamily: "Urbanist, sans-serif",
    color: "#30018F",
    fontSize: "16px",
    fontWeight: 700
  },


  // IMPORTED STYLING:
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  smMargin: {
    margin: theme.spacing(1),
  },
  actionDiv: {
    textAlign: 'center',
  },

  // NEW POST ALIGNMENT, removed GRID:
  postGrid: {
    display: "flex",
    flexDirection: "column",
    margin: "40px 0px",
    padding: "20px",
    borderRadius: "10px",
    // border: "1px solid #000"
    // justifyContent: "center",
    // margin: "0px auto"
  },

  overlay2: {
    display: "flex",
    justifyContent: "center",
    padding: "5px",
    margin: "20px 10px 0px",
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: "auto",
    minWidth: '280px',
    minHeight: "300px",
    height: "auto",
    position: 'relative',
    paddingBottom: "32px",
    margin: "10px",
    padding: "30px",
    border: "1px solid rgb(221, 221, 221)",
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 6px 16px",
    borderRadius: "14px",
    textAlign: "center"
  },
  cardAlign: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },


  registeredAlign: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: "15px"
  },


  registeredStyle: {
    margin: "4px 10px",
    textAlign: "center",
    fontFamily: 'Urbanist',
    color: "#FE5E38",
    fontSize: "14px",
    textTransform: "uppercase",
    fontWeight: 700
  },


  rewardStyle: {
    margin: "20px 0px 0px",
    textAlign: "center",
    fontFamily: 'Urbanist',
    color: "#FE5E38",
    fontSize: "19px",

  },
  titleCollection: {
    margin: "10px auto 0px",
    textAlign: "center",
    fontFamily: 'Urbanist',
    color: "#FE5E38",
    fontSize: "0.7rem",
    maxWidth: "220px",
  },
  couponSubtitle: {
    margin: "2px 0px",
    fontFamily: 'Urbanist',
    color: "#FE5E38",
    fontSize: "9px",
    fontWeight: 900,
    textTransform: "uppercase",
    letterSpacing: "2px",
    textAlign: "center"
  },
  infoHeader: {
    fontFamily: "Roboto, sans-serif",
    textTransform: "uppercase",
    fontSize: "0.6rem",
    textAlign: "center",
    margin: "0px 0px 4px",
    padding: "0px",
    color: "#787878",
    fontWeight: 500,
  },
  ucsStyle: {
    padding: "0px",
    margin: "0px auto 8px",
    fontFamily: "Urbanist, sans-serif",
    color: "#FE5E38",
    fontSize: "20px",
    fontWeight: 900,
    letterSpacing: "2px",
    textAlign: "center",
    maxWidth: "200px"
  },
  border: {
    borderBottom: "0.5px solid rgb(221, 221, 221)",
    margin: "20px 30px 26px"
  },
}));