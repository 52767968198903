// FUNCTION that acceepts the STATE and accepts the ACTION: do something based on the parameters set!
// import user from '../../../server/models/user';
import { FETCH_ALL, FETCH_POST, CREATE, UPDATE, DELETE, LIKE, FETCH_BY_SEARCH, START_LOADING, END_LOADING, COMMENT } from '../constants/actionTypes';


export default (state = {isLoading: true, posts: [], authDatas: [] }, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };
    case FETCH_ALL:
      return {
        ...state,
        posts: action.payload.data,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
      }
      case FETCH_BY_SEARCH:
        return { ...state, posts: action.payload.data };
        case FETCH_POST:
          return { ...state, post: action.payload };
        case LIKE:
          return { ...state, posts: state.posts.map((post) => (post._id === action.payload._id ? action.payload : post)) };

          case COMMENT:
        // RETURN ALL THE POSTS NORMALLY
        return {
          ...state,
          posts: state.posts.map((post) => {

                 // HOWEVER ** WANT TO CHANGE THE POST THAT JUST RECEIVED A COMMENT
            if (post._id === + action.payload._id) {
              return action.payload;
            }
            return post;
          }),
        }; 
        case CREATE:
          return { ...state, posts: [...state.posts, action.payload] };
        case UPDATE:
          return { ...state, posts: state.posts.map((post) => (post._id === action.payload._id ? action.payload : post)) };
        case DELETE:
          return { ...state, posts: state.posts.filter((post) => post._id !== action.payload) };

        default:
      return state;
  }
};





// import { FETCH_ALL, CREATE, UPDATE, DELETE, LIKE } from '../constants/actionTypes';


// export default (posts = [], action) => {
//     switch (action.type) {
//         case FETCH_ALL:
//             return action.payload;
//         case CREATE: 
//             return [...posts, action.payload];
//         case UPDATE: 
//         case LIKE: 
//             return posts.map((post) => post._id === action.payload._id ? action.payload: post);
//         case DELETE: 
//             return posts.filter((post) => post._id !== action.payload);
//     default:
//        return posts;
//     }
// }