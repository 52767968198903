import React from 'react';
import { useSelector } from 'react-redux';

import { Modal, Button, Box } from '@material-ui/core/';

import "./styles.css"

// IMAGES:
import CrossClose from "./../assets/crossClose.png"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: 'rgba(48, 1, 143, 0.8)', /* #30018F at 50% transparency */
  color: "#FFF",
  textAlign: "center",
  fontFamily: "Urbanist, sans-serif",
  fontSize: "20px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: "6px"
};


export default function PrizeModal ({var2Value}) {
  const { post } = useSelector((state) => state.posts);
  const [open, setOpen] = React.useState(false);


  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const entryCommentsCount = post.comments.length;
  const halfEntryCommentsCount = entryCommentsCount / 2;

  return (
    <div>
      <div> 

    <Button style={{    
    color: "#846CC5",
    fontFamily: "Roboto, sans-serif",
    fontSize: "0.85rem",
    margin: "0px 10px 6px",
    padding: "3px 28px",
    fontWeight: 500,
    borderRadius: "4px",
    textTransform: "none",
      letterSpacing: "0px",
      backgroundColor: "#F2F5F8",
      fontStyle: "oblique 40deg"
  }} 
    onClick={handleOpen}>
    Be in to win
    </Button>


</div>

          <div className="pm_tipContainer">
          </div>



      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={{       
          ...style,
          width: "auto",
          minWidth: "300px",
          maxHeight: "80vh",
          overflowY: "auto",
          }}>
                      <div onClick={handleClose} className="closeButton"><img style={{  width: "25px"}} src={CrossClose} alt=""/></div>
          <p style={{fontSize: "19px", fontWeight: 700, paddingBottom: "5px", fontFamily: "Roboto, sans-serif", textTransform: "none"}}>Enter the draw to win<br/> </p>
          <p stlye={{fontSize: "17px", fontWeight: 0, fontFamily: "Roboto"}}> {post.rewardCap} </p>

          <p style={{fontSize: "14px"}}>Terms &amp; Conditions apply</p>
        </Box>
      </Modal>
    </div>
  );
}