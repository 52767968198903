import React, {useState, useEffect} from 'react'
import { Typography, Toolbar, Button } from '@material-ui/core';

import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import decode from "jwt-decode"

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import useStyles from './styles';

import "./styles.css"

import CoupoLogo from "./images/coupoLogo.png"
import Stack from "./images/menu.png"
import Cross from "./images/cross.png"

export const Navbar = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();

  // SANDWICH START
  const [showDropdown, setShowDropdown] = useState(false);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const closeDropdownOnOutsideClick = (event) => {
    if (showDropdown && !event.target.closest('.your-dropdown-class')) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeDropdownOnOutsideClick);
    
    return () => {
      document.removeEventListener('click', closeDropdownOnOutsideClick);
    };
  }, [showDropdown]);

// SANDWICH END


  const logout = () => {
    dispatch({ type: "LOGOUT" });

    history.push('/auth');

    setUser(null);
  };

  useEffect(() => {
    const token = user?.token

    if (token) {
      const decodedToken = decode(token)

      // if(decodedToken.exp * 2000 < new Date().getTime()) logout()
      if(decodedToken.exp * 1000 < new Date().getTime()) 
      logout()
      // history.push('/auth')
    }
    setUser(JSON.parse(localStorage.getItem("profile")))
  }, [location])




  return (
    <div className="appBar" position="static">
      <div className={classes.brandContainer}>
         {/* <Link to="/home" style={{ textDecoration: "none" }}>  */}
        <Link to="/" style={{ textDecoration: "none", display: "flex", flexDirection: "row" }}>
          {/* <img style={{ height: "22px", padding: "2px 6px 0px 0px"}}src={CoupoLogo} alt=""/> */}
          
          
          {/* <img style={{ height: "35px", padding: "0px 0px 18px 0px"}} src={CoupoLogo} alt=""/> */}
        

      {/* <LazyLoadImage
      effect="blur"
      style={{height: "35px" }} 
      src={CoupoLogo}
      alt=""/> */}

<h1 className="heading" align="center">Coupo</h1>

        </Link>

               

{/* {user?.result?._id ? (
  <Link to="/posts" style={{ textDecoration: "none" }}>
    <h1 className="heading" align="center">Coupo</h1>
  </Link>
) : (
  <Link to="/" style={{ textDecoration: "none" }}>
    <h1 className="heading" align="center">Coupo</h1>
  </Link>
)} */}

      </div>

      {/* {user?.result && 
        <Link to={"/posts"} style={{ display: "flex", flexDirection: "row", textDecoration: "none" }}>
          <h1 className="navBarOptions">Collection</h1>
        </Link>
      } */}
{/* 
{location.pathname !== "/" && location.pathname !== "/posts" && user?.result && (
        <Link to={"/posts"} style={{ display: "flex", flexDirection: "row", textDecoration: "none" }}>
          <h1 className="navBarOptions">Collection</h1>
        </Link>
      )} */}


<div className="nonSandwichBarMenu">
{user?.result && 
        <Link to={"/quick-view"} style={{ display: "flex", flexDirection: "row", textDecoration: "none" }}>
          <h1 className="navBarOptions">Unredeemed</h1>
        </Link>
      }

</div>

<div className="nonSandwichBarMenu">
{user?.result && 
<Link to={"/home"} style={{ display: "flex", flexDirection: "row", textDecoration: "none" }}>
          <h1 className="navBarOptions">About</h1>
        </Link>
      }
</div>


<div className="nonSandwichBarMenu">







        
              {user?.result ? (
                <div className={classes.profile}>
                  <div className="profileSection__responsive">
                    <Link to={"/profile"} style={{ display: "flex", flexDirection: "row", textDecoration: "none", margin: "0px 0px 20px" }}>
                      <Typography className={classes.userName}>{user.result.name}</Typography>
                    </Link>
                    <Button 
                      // style={{ borderRadius: "5px", margin: "0px 0px 0px 15px", padding: "0px 17px"  }}
                      className={classes.logoutIn} onClick={logout}>Logout</Button>
                  </div>
                </div>
              ) : (
                <></>
                // <Link style={{ textDecoration: "none" }} to={"/auth"}>
                //   <Button 
                //     style={{ borderRadius: "18px", padding: "0px 20px", fontSize: "13px" }}
                //     className={classes.signInButton}
                //     variant="contained">Sign in</Button>
                // </Link>
              )}

</div>


{!user?.result ? (
<>
  <Link style={{ textDecoration: "none" }} to={"/auth"}>
    {/* <Button  */}
    <div
        className={classes.signInButton}
        variant="contained">Log in
        {/* </Button> */}
        </div>
  </Link>
</>
) : (
<>            
<div className="sandwichBarMenu">
      <div style={{ position: 'relative', display: 'inline-block', padding: "0px" }}>
        <div style={{cursor: "pointer", borderRadius: "5px", padding:"0px 0px 10px 0px"}} onClick={handleDropdownToggle}>
          {/* ☰ */}
          {showDropdown ? (
          <img style={{width: "28px"}} src={Cross} alt="☰"/>
          ) : (
          <img style={{width: "28px"}} src={Stack} alt="☰"/>
          )}
          
        </div>

        {showDropdown && (
          <div
            style={{
              position: 'absolute',
              top: '100%',
              right: 0,
              padding: "10px 30px",
              background: '#fff',
              // border: '1px solid #ccc',
              borderRadius: '4px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              zIndex: 1,
            }}
          >

{user?.result && 
        <Link to={"/quick-view"} style={{ display: "flex", flexDirection: "row", textDecoration: "none" }}>
          <h1 className="navBarOptions_mobile">Unredeemed</h1>
        </Link>
      }


        <Link to={"/home"} style={{ display: "flex", flexDirection: "row", textDecoration: "none" }}>
          <h1 className="navBarOptions_mobile">About</h1>
        </Link>

       
              {user?.result ? (
                <div className={classes.profile}>
                  <div className="profileSection__responsive">
                    <Link to={"/profile"} style={{ display: "flex", flexDirection: "row", textDecoration: "none", margin: "10px 0px 10px" }}>
                      <Typography className={classes.userName}>Profile</Typography>
                    </Link>
                    <Button 
                      // style={{ borderRadius: "5px", margin: "0px 20px 15px", padding: "0px 18px" }}
                      className={classes.logout} onClick={logout}>Logout</Button>
                  </div>
                </div>
              ) : (
                <></>
                // <Link style={{ textDecoration: "none" }} to={"/auth"}>
                //   <Button 
                //     style={{ borderRadius: "18px", padding: "0px 20px", fontSize: "13px" }}
                //     className={classes.signInButton}
                //     variant="contained">Sign in</Button>
                // </Link>
              )}
          </div>
        )}
        </div>
      </div>
</>)}



{/* MOBILE */}

    </div>
  );
};

export default Navbar;








// import React, {useState, useEffect} from 'react'
// import { Typography, Toolbar, Avatar, Button } from '@material-ui/core';

// import { Link, useHistory, useLocation } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import decode from "jwt-decode"

// import useStyles from './styles';
// import "./styles.css"


// // PROFILE IMAGES:
// import Profile1 from "./images/profile1.png"
// import Profile2 from "./images/profile2.png"
// import Profile3 from "./images/profile3.png"
// import Profile4 from "./images/profile4.png"


// export const Navbar = () => {
//   const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
//   const dispatch = useDispatch();
//   const location = useLocation();
//   const history = useHistory();
//   const classes = useStyles();

//   console.log(user)


//   const logout = () => {
//     dispatch({ type: "LOGOUT" });

//     history.push('/');

//     setUser(null);
//   };

//   useEffect(() => {
//     const token = user?.token

//     if (token) {
//       const decodedToken = decode(token)

//       if(decodedToken.exp * 1000 < new Date().getTime()) logout()
//     }
//     setUser(JSON.parse(localStorage.getItem("profile")))
//   }, [location])



//   const AvatarSelectorChecker = () => {
//     if (user.result.age && user.result.gender && user.result.occupation && user.result.income) {
//         return (
//           <Avatar className={classes.purple} alt={user.result.name} img src={Profile4}></Avatar>
//         )
//     } 
//     else if (user.result.age && user.result.gender && (user.result.occupation || user.result.income)) {
//       return (
//           <Avatar className={classes.purple} alt={user.result.name} img src={Profile3}></Avatar>
//       )
//   } else if (user.result.age && (user.result.gender || user.result.occupation || user.result.income)) {
//       return (
//           <Avatar className={classes.purple} alt={user.result.name} img src={Profile2}></Avatar>
//       )
//   } else if ((user.result.age || user.result.gender || user.result.occupation || user.result.income))  {
//       return (
//           <Avatar className={classes.purple} alt={user.result.name} img src={Profile1}></Avatar>
//       )
//   } else {
//       return (
//           <Avatar className={classes.purple} alt={user?.result.name}></Avatar>
//       )
//   }
// }


//   return (
//     <div className="appBar" position="static">
//     <div className={classes.brandContainer}>
//     <Link to="/welcome" style={{textDecoration: "none"}}>
//         <h1 className="heading" align="center">Jeb.</h1>
//       </Link>
// </div>



// {user?.result && 
//         <Link to={"/redeem"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
//         <h1 className="navBarOptions">Redeem</h1>
//         </Link>
//              }



// {user?.result && 
//         <Link to={"/"} style={{display: "flex", flexDirection: "row", textDecoration: "none"}}>
//         <h1 className="navBarOptions">Collection</h1>
//         </Link>
//              }
       

//       <Toolbar className={classes.toolbar}>
//         {user?.result ? (

// <div className={classes.profile}>
// <div className="profileSection__responsive">

// <Link to={"/profile"} style={{display: "flex", flexDirection: "row", textDecoration: "none", margin: "3px 0px 15px"}}>

// <AvatarSelectorChecker/>

// <Typography className={classes.userName} variant="h6">{user.result.name}</Typography>
// </Link>
// <Button variant="contained" className={classes.logout} onClick={logout}>Logout {user.name}</Button>
// </div>
// </div>

//         ) : (
//           <Link style={{textDecoration: "none"}} to={"/auth"}>
//           <Button 
//           className={classes.signInButton}
//           variant="contained">Sign In </Button>
//           </Link>
//         )}
//       </Toolbar>
//   </div>
//   )
// }

// export default Navbar;