
import React from 'react'
import "./styles.css"

const Contact = () => {

  return (
    <>
      <div className="contact_section3">
        <h1 className="contact_header3"> Contact us</h1>
      <div className="contact_section3Content">
          <p className="contact_section3Text1">For all questions, please contact <span style={{textDecoration: "underline"}}>help@jebnz.com</span></p>
      </div>
      </div>
    </>
  )
}

export default Contact