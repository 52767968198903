import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    porfileContainer: {
        textAlign: "center",
        marginTop: "30px"
    },
    greetingText: {
        fontFamily: 'Pacifico, sans-serif',
        color: "#30018F",
        fontSize: "22px",
        margin: "20px 0px 2px"
    },
    usernameStyle: {
        fontSize: "28px",
        fontFamily: 'Roboto, sans-serif',
        color: "#30018F",
    },
    
    profileBorder: {
        borderBottom: "2px solid #FFF",
        margin: "0px 35% 30px"
    },
    // USER SPECIFIC's

    profileTextHead: {
        margin: "45px 0px 0px",
        fontFamily: "Roboto, sans-serif",
        color: "#30018F",
        fontSize: "14px",
        textTransform: "uppercase",
        fontWeight: 900,
        // borderBotom: "2px solid #FFF"
    },

    profileText: {
        margin: "5px 0px 16px",
        fontFamily: "Roboto, sans-serif",
        color: "#30018F",
        fontSize: "20px"
    },



    // CONTACT PANEL:
    contactContainer: {
    margin: "35px 34% 20px",
    border: "2px solid #FFF",
    borderRadius: 10,
    }, 

    profileContactHeader: {
        fontFamily: "Roboto, sans-serif",
        color: "#30018F",
        fontSize: "14px",
        margin: "50px 12px 40px",
    }, 

    profileHeaderBorder: {
        border: "1px solid #FFF",
        marginBottom: "15px"
    },
    profileContactHead: {
        margin: "10px 0px",
        fontFamily: "Roboto, sans-serif",
        color: "#30018F",
        fontSize: "12px",
        textTransform: "uppercase",
        fontWeight: 900,
    },
    profileContactText: {
        margin: "2px 0px 40px",
        fontFamily: "Urbanist, sans-serif",
        color: "#30018F",
        fontSize: "18px"
    },




    //CONSUMER PROFILE:

    consumerPersonalityPanels: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        border: "2px solid #FFF",
        margin: "40px 20%",
        borderRadius: 10,
    },

    panel1: {
        margin: "20px",
        padding: "20px"
    },

    panel1Header: {
        fontFamily: "Urbanist, sans-serif",
        fontSize: "18px",
        color: "#30018F",
        textTransform: "uppercase",
        fontWeight: 900
    },

}));