import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
appBar: {
  marginTop: '0px',
  marginBottom: "30px",
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px 50px',
},
heading: {
color: '#FE5E38',
textDecoration: 'none',
fontFamily: 'Orelega One, sans-serif',
fontWeight: 900,
letterSpacing: "0px"
},

image: {
marginLeft: '15px',
},

navBarOptions: {
fontSize: "0.88rem",
color: '#808080',
fontFamily: "Roboto, sans-serif",
marginTop: 0,
marginLeft: 80,
borderBottom: "4px solid #FE876B",
padding: 2,
fontWeight: 900,
},

toolbar: {
  display: 'flex',
  justifyContent: 'flex-end',
  width: '400px',
},

profile: {
display: 'flex',
justifyContent: "flex-end",
flexDirection: "row",
justifyContent: 'space-between',
margin: "0px 20px",
// maxWidth: '400px',
},

userName: {
display: 'flex',
textAlign: "center",
alignItems: 'center',
fontSize: "0.82rem",
letterSpacing: "0.1px",
color: '#1A014D',
fontFamily: 'Roboto, sans-serif',
// marginRight: 25,
marginTop: "8px",
fontWeight: 500,
'&:hover': {
  color: "#30018F",
},
},
brandContainer: {
alignItems: 'center',
marginBottom: "10px"
},
purple: {
color: "#FFF",
backgroundColor: "#FE5E38",
marginRight: 10,
padding: "0px",
'&:hover': {
  backgroundColor: "#30018F",
},
},

logout: {
  height: "35px",
  // border: "2px solid #4C3AA1",
  // backgroundImage: "radial-gradient(circle, #4C3AA1, #3F02A1)",
  backgroundImage: "linear-gradient(to top, #4C3AA1, #30018F)",
  color: "#fff",
  fontFamily: "Roboto, sans-serif",
  // marginBottom: "15px",
  textTransform: "none",
  // marginTop: "15px",
  // height: "33px",
  display: "flex",
  justifyContent: "center",
  fontSize: "0.7rem",
  textAlign: "center",
  fontWeight: 700,
  borderRadius: "5px",
  border: "2px solid #F7F7F7",
  padding: "6px 20px 6px",
  margin: "5px 0px 12px"
},

logoutIn: {
  height: "30px",
  // border: "2px solid #4C3AA1",
  // backgroundImage: "radial-gradient(circle, #4C3AA1, #3F02A1)",
  backgroundImage: "linear-gradient(to top, #4C3AA1, #30018F)",
  color: "#fff",
  fontFamily: "Roboto, sans-serif",
  // marginBottom: "15px",
  textTransform: "none",
  // marginTop: "15px",
  // height: "33px",
  display: "flex",
  justifyContent: "center",
  fontSize: "0.7rem",
  textAlign: "center",
  fontWeight: 700,
  borderRadius: "5px",
  border: "2px solid #F7F7F7",
  // padding: "6px 20px 8px",
  // margin: "0px 0px 12px",
  margin: "0px 0px 14px 12px", 
  padding: "15px 18px"
  },

signInButton: {
  height: "15px",
  // border: "2px solid #4C3AA1",
  // backgroundImage: "radial-gradient(circle, #4C3AA1, #3F02A1)",
  backgroundImage: "linear-gradient(to top, #4C3AA1, #30018F)",
  color: "#fff",
  fontFamily: "Roboto, sans-serif",
  // marginBottom: "15px",
  textTransform: "none",
  // marginTop: "15px",
  // height: "33px",
  display: "flex",
  justifyContent: "center",
  fontSize: "0.7rem",
  textAlign: "center",
  fontWeight: 700,
  borderRadius: "5px",
  border: "2px solid #F7F7F7",
  padding: "8px 20px 7px",
  margin: "0px 15px 12px 0px"
},

}));


// import { makeStyles } from '@material-ui/core/styles';
// import { deepPurple } from '@material-ui/core/colors';

// export default makeStyles((theme) => ({
//   appBar: {
//     borderRadius: 15,
//     margin: '30px 0',
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     padding: '10px 50px',
//     [theme.breakpoints.down('sm')]: {
//       flexDirection: 'column',
//     },
//   },
//   heading: {
//     color: theme.palette.primary.main,
//     textDecoration: 'none',
//   },
//   image: {
//     marginLeft: '10px',
//     marginTop: '5px',
//   },
//   toolbar: {
//     display: 'flex',
//     justifyContent: 'flex-end',
//     width: '400px',
//     [theme.breakpoints.down('sm')]: {
//       width: 'auto',
//     },
//   },
//   profile: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     width: '400px',
//     alignItems: 'center',
//     [theme.breakpoints.down('sm')]: {
//       width: 'auto',
//       marginTop: 20,
//       justifyContent: 'center',
//     },
//   },
//   logout: {
//     marginLeft: '20px',
//   },
//   userName: {
//     display: 'flex',
//     alignItems: 'center',
//     textAlign: 'center',
//   },
//   brandContainer: {
//     display: 'flex',
//     alignItems: 'center',
//   },
//   jebLogo: {
//     fontSize: "35px",
//     textDecoration: "none",
//     fontFamily: 'Orelega One, sans-serif',
//     color: "#fe5e36",
//     fontWeight: 700
//   },
//   purple: {
//     color: theme.palette.getContrastText(deepPurple[500]),
//     backgroundColor: deepPurple[500],
//   },
// }));