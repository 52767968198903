import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({

  appBarSearchCollection: {
    // marginBottom: '1vh',
    display: 'flex',
    padding: '0px 8px 0px 3px',
    maxWidth: "70vw",
    // margin: "30px",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    borderRadius: "20px",
    boxShadow: "rgba(0, 0, 0, 0.03) 0px 6px 16px",
    border: "2px solid rgb(240, 240, 240)",
    backgroundColor: "#F9F9F9",
  },
  appBarSearchInput: {
    margin: "6px 0px 6px 5px",
    width: "100%",
    height: "26px",
    // borderRadius: "4px 1px 1px 4px",
    // border: "2px solid #FE876B",
    padding: "0px 0px 0px 14px",
    backgroundColor: "#F9F9F9",
    border: "0px solid rgb(240, 240, 240)",
    fontSize: "11px"
  }, 

  clearButtonImage: {
    border: "1px solid rgb(240, 240, 240)", 

    // borderTop: "1px solid rgb(240, 240, 240)",
    // borderRight: "1px solid rgb(240, 240, 240)",
    // borderBottom: "1px solid rgb(240, 240, 240)",

    backgroundColor: "#f7f7f7", 
    width: "18px", 
    cursor: "pointer", 
    margin: "0px 0px 0px 6px", 
    padding: "2px 3px", 
    borderRadius: "2px"
  },

  pagination: {
    borderRadius: 4,
    marginTop: '1rem',
    padding: '16px',
  },
  gridContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
    marginTop: "0vh"
  },

  paper: {
    padding: theme.spacing(2),
    fontFamily: "Urbanist, sans-serif",
    width: "auto",
    borderRadius: "20px",
    margin: "10px 10px",
    // backgroundImage: "linear-gradient(to top, #fe9938, #fe9337, #fe8d37, #fe8737, #fe8137, #fe7c37, #fe7737, #fe7238, #fe6d38, #fe6838, #fe6338, #fe5e38)",
    // boxShadow: "3px 3px 5px 0px rgba(0,0,0,0.35)"
  },

  greetingTextHome: {
    fontFamily: "Pacifico, sans-serif",
    color: "#fff",
    fontSize: "34px",
    margin: "20px 0px 10px",
    textAlign: "center"
},

  greetingSubText: {
    color: "#fff",
    fontSize: "27px",
    margin: "50px 80px 40px",
    textAlign: "center",
    fontWeight: 0,
    fontFamily: "Urbanist, sans-serif",
    borderBottom: "1px solid #FFF",
    paddingBottom: "20px"

},

  signInFlag: {
    textAlign: "center",
    fontSize: "22px",
    color: "#fff",
    fontFamily: "Urbanist, sans-serif",
    fontWeight: 700,
    paddingTop: "10px",
    margin: "15px 0px 30px",
    textDecoration: "none",
  },

  formHeaderRedeem: {
    fontFamily: "Roboto, sans-serif",
    // fontSize: "22px",
    fontSize: "18px",
    margin: "11px 10px 10px",
    color: "#FE5E38",
    // fontWeight: 900,
    textAlign: "left",
    textTransform: "uppercase"
  },

  
  seeAllButton: {
    backgroundColor: "rgba(254, 94, 56, 0.8)",
    borderRadius: "20px",
    color: "#FFF",
    textAlign: "center",
    display: "flex",
    alignItems: "center", // Align items vertically in the center
    justifyContent: "center", // Align items horizontally in the center
    fontSize: "11px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 900,
    letterSpacing: "0.5px",
    cursor: "pointer",
    width: "100px",
    textTransform: "uppercase",
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 6px 16px",
    padding: "3px 0px 3px 8px", // Adjust padding as needed
    margin: "2px 0px 4px"
  }
}));