import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  quickViewCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '8px',
    // height: "280px",
    height: "auto",
    width: "320px",
    position: 'relative',
    paddingBottom: "32px",
    margin: "20px 10px 10px",
    boxShadow: 'rgba(0, 0, 0, 0.08) 0px 6px 16px',
    backgroundColor: '#f8fafc',
    border: "3px solid #fff"
    
  },

  media: {
    height: 100,
    objectFit: "contain",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "320px",
    textAlign: "center",
    margin: 15,
    padding: "20px",
  },


  cardBorder: {
    border: "solid",
    borderColor: "#fff",
    borderWidth: "2px",
    borderRadius: "6px",
    margin: "5px 14px 0px 14px",
  },
  fullHeightCard: {
    height: '100%',
  },
  grid: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px',
  },


  registeredAlign: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "20px 30px 16px",

  },

  claimedAlign: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "15px 30px 8px"
  },

  titleCollection: {
    // padding: '12px 14px 0px 8px',
    margin: "10px 10px",
    textTransform: "uppercase",
    textAlign: "center",
    // fontFamily: "Urbanist, sans-serif",
    fontFamily: "Roboto, sans-serif",
    color: "#30018F",
    fontSize: "0.7rem",
    fontWeight: 700,
  },

uniqueCodeStyle: {
  // color: "#FE5E38", 
  fontFamily: "Urbanist, sans-serif", 
  fontSize: "1.4rem", 
  margin: "0px auto",
  color: "#fff",
  backgroundImage: "linear-gradient(to top, #30018F, #4C3AA1)",
  width: "110px",
  borderRadius: "4px",
  padding: "8px 5px",
  fontWeight: 900,
  letterSpacing: "2px"
},

  titleBrand: {
    padding: '8px 0px 6px',
    margin: "0px 0px 4px",
    // textAlign: "right",
    textAlign: "center",
    fontFamily: "Urbanist, sans-serif",
    // color: "#FFF",
    // color: "#FE5E38",
    color: "#30018F",
    fontWeight: 700,
    fontSize: "18px"
  },

  quickViewTitleBrand: {
    padding: '8px 0px 10px',
    margin: "20px 0px 5px",
    // textAlign: "right",
    textAlign: "center",
    fontFamily: "Urbanist, sans-serif",
    // color: "#FFF",
    color: "#30018F",
    fontWeight: 700,
    fontSize: "23px"
  },

  quickViewBrand: {
    padding: "4px 10px",
    margin: "30px auto 2px",
    textAlign: "center",
    // fontFamily: "Urbanist, sans-serif",
    fontFamily: "Poppins, sans-serif",
    color: "#fff",
    backgroundImage: "linear-gradient(to top, #4C3AA1, #30018F)",
    fontWeight: 700,
    // letterSpacing: "2px",
    fontSize: "1.3rem",
    width: "72px",
    // borderRadius: "8px 8px 0px 0px"
    borderRadius: "3px"
  },

  towardsOneStyle: {
    padding: '4px 0px',
    margin: "5px 20px 10px",
    textAlign: "center",
    // fontFamily: "Urbanist, sans-serif",
    // color: "#FE5E38",
    fontWeight: 0,
    fontSize: "10px",
    textTransform: "uppercase",
    letterSpacing: "0.4px",
    color: "#A7A7A7", 
    fontFamily: "Roboto, sans-serif",
  },

  quickViewSubBrand: {
    padding: '0px 0px 2px',
    margin: "0px 25px 10px",
    textAlign: "center",
    fontFamily: "Roboto, sans-serif",
    // color: "#FE5E38",
    color: "#A7A7A7", 
    fontWeight: 0,
    fontSize: "1.1rem"
  },

  quickViewSubBrandSmall: {
    padding: '0px',
    margin: "0px 25px 0px",
    textAlign: "center",
    fontFamily: "Roboto, sans-serif",
    // color: "#FE5E38",
    color: "#A7A7A7", 
    fontWeight: 500,
    fontSize: "14px"
  },

  description: {
    fontSize: 9,
    textAlign: "center",
    fontFamily: "Urbanist, sans-serif",
    color: "#FFF",
    padding: "0px 5px 5px"
  },
  couponDetails: {
    fontSize: 10,
    textAlign: "right"
  },

  subTitleContainer: {
    overflow: "scroll"
  },

  subTitle: {
    fontFamily: "Urbanist, sans-serif",
    color: "#FFF",
    margin: "15px 0px 10px 0px",
    fontSize: "10px",
    fontWeight: 900,
    textTransform: "uppercase",
    letterSpacing: "2px",
    textAlign: "center"
  }, 
  overlay2: {
    display: "flex",
    justifyContent: "center",
    padding: "5px"
  }


});



// import { makeStyles } from '@material-ui/core/styles';

// export default makeStyles({
//   media: {
//     height: 0,
//     paddingTop: '56.25%',
//     backgroundColor: 'rgba(0, 0, 0, 0.5)',
//     backgroundBlendMode: 'darken',
//   },
//   border: {
//     border: 'solid',
//   },
//   fullHeightCard: {
//     height: '100%',
//   },
//   card: {
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'space-between',
//     borderRadius: '15px',
//     height: '100%',
//     position: 'relative',
//   },
//   overlay: {
//     position: 'absolute',
//     top: '20px',
//     left: '20px',
//     color: 'white',
//   },
//   overlay2: {
//     position: 'absolute',
//     top: '20px',
//     right: '20px',
//     color: 'white',
//   },
//   grid: {
//     display: 'flex',
//   },
//   details: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     margin: '20px',
//   },
//   title: {
//     padding: '0 16px',
//   },
//   cardActions: {
//     padding: '0 16px 8px 16px',
//     display: 'flex',
//     justifyContent: 'space-between',
//   },
// });

