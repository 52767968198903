
import * as actionType from '../constants/actionTypes';

const authReducer = (state = { authData: null }, action) => {
  switch (action.type) {
    case actionType.AUTH:
      localStorage.setItem('profile', JSON.stringify({ ...action?.data }));
      return { ...state, authData: action.data, loading: false, errors: null };

  //     case actionType.UPDATE:
  // // Update the authData field in the state with the updated user data
  // const updatedAuthData = { ...state.authData, age: action.data };
  // return { ...state, authData: updatedAuthData };

      case actionType.UPDATE:
        // Update the authData field in the state with the updated user data
        // console.log("movement in the reducer")
        return { ...state, authData: action.data };
  //     case actionType.UPDATE_PROFILE:
  // // Update the authData field in the state with the updated user data
  // const updatedAuthData = { ...state.authData, ...action.data };
  // return { ...state, authData: updatedAuthData };



    case actionType.LOGOUT:
      localStorage.clear();
          return { ...state, authData: null, loading: false, errors: null };

    default:
      return state;
  }
};

export default authReducer;




// import { AUTH, LOGOUT } from '../constants/actionTypes';

// const authReducer = (state = {authData: null}, action) => {
//     switch (action.type) {
//         case AUTH:
//             // getting profile / user info from actions(retrieved from DB --through API and now setting the user within the localStorage)
//         localStorage.setItem("profile", JSON.stringify({ ...action?.data}))
//         return {...state, authData: action?.data};
//         case LOGOUT:
//             localStorage.clear()
//             return {...state, authData: null};
//         default:
//             return state;
//     }
// }

// export default authReducer