import { FETCH_ALL, FETCH_POST, FETCH_BY_SEARCH, CREATE, UPDATE, DELETE, LIKE, START_LOADING, END_LOADING, COMMENT } from '../constants/actionTypes';
import * as api from '../api/index.js';


// ACTION CREATORS --> FUNCTIONS

export const getPosts = (page) => async (dispatch) => {
    try {
      dispatch({type: START_LOADING})
      const { data } = await api.fetchPosts(page);

      // console.log(data)
  
      dispatch({ type: FETCH_ALL, payload: data });
      dispatch({ type: END_LOADING })
    } catch (error) {
      console.log(error.message);
    }
  };


  export const getPost = (id) => async (dispatch) => {
    try {
      dispatch({type: START_LOADING})
      const { data } = await api.fetchPost(id);

      // console.log(data)
  
      dispatch({ type: FETCH_POST, payload: data });
      dispatch({ type: END_LOADING })
    } catch (error) {
      console.log(error.message);
    }
  };



  export const getPostsBySearch = (searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data: { data } } = await api.fetchPostsBySearch(searchQuery);
  
      dispatch({ type: FETCH_BY_SEARCH, payload: { data } });
      dispatch({ type: END_LOADING });
    } catch (error) {
      console.log(error + "error in action...");
    }
  };


export const createPost = (post) => async (dispatch) => {
    try {
      dispatch({type: START_LOADING})
      const { data } = await api.createPost(post);
  
      dispatch({ type: CREATE, payload: data });
    } catch (error) {
      console.log(error + "error with create POST action") ;
    }
  };


  export const updatePost = (id, post) => async (dispatch) => {
    try {
      const { data } = await api.updatePost(id, post);
  
      dispatch({ type: UPDATE, payload: data });
    } catch (error) {
      console.log(error.message);
    }
  };

  // export const updatePostRedeem = (id, post) => async (dispatch) => {
  //   try {
  //     const { data } = await api.updatePostRedeem(id, post);
  
  //     dispatch({ type: UPDATE, payload: data });
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  export const deletePost = (id) => async (dispatch) => {
    try {
      await api.deletePost(id);
  
      dispatch({ type: DELETE, payload: id });
    } catch (error) {
      console.log(error);
    }
  };

  export const likePost = (id) => async (dispatch) => {
    try {
      const { data } = await api.likePost(id);
  
      dispatch({ type: LIKE, payload: data });
    } catch (error) {
      console.log(error);
    }
  };

  export const etdPost = (id) => async (dispatch) => {
    try {
      const { data } = await api.etdPost(id);
  
      dispatch({ type: LIKE, payload: data });
    } catch (error) {
      console.log(error);
    }
  };

  export const commentPost = (value, id) => async (dispatch) => {
    try {
      const { data } = await api.comment(value, id);
  
      // RETRIEVES the comments data -- to display
      dispatch({ type: COMMENT, payload: data });
  
      return data.comments;
    } catch (error) {
      console.log(error);
    }
  };


  export const goToPromptPost = (value, id) => async (dispatch) => {
    try {
      const { data } = await api.goToPrompt(value, id);
  
      // RETRIEVES the comments data -- to display
      dispatch({ type: COMMENT, payload: data });
  
      return data.goToPrompts;
    } catch (error) {
      console.log(error);
    }
  };


