import React from 'react';
import { Link } from "react-router-dom"

import useStyles from '../styles';
import "../styles.css"
import { LazyLoadImage } from 'react-lazy-load-image-component';


const PostUnredeemed = ({post}) => {
    const classes = useStyles();
    const user = JSON.parse(localStorage.getItem('profile'));

    const currentDate = new Date();
    const [day, month, year] = post.campaignEndDate.split('/');
    const campaignEndDate = new Date(`${year}-${month}-${day}`);

  // For the post card - simplicity otherwise the full ISO date will be displayed
    const formatCampaignEndDate = ({ post }) => {
      const dateObject = new Date(post.campaignEndDate);
      const day = dateObject.getUTCDate().toString().padStart(2, '0');
      const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0');
      const year = dateObject.getUTCFullYear();
  
      return `${day}/${month}/${year}`;
    };
  
    const formattedCampaignDate = formatCampaignEndDate({ post });


const InitialFilter = ({ post }) => {
  const classes = useStyles();
  const array = String(post.winners).split(',');
  const arrayClaimedComments = String(post.claimedcomments).split(',');

  let var2Value;
  let userWinner = false;

  let var2ValueCC;
  let hasClaimedCC = false;
  let userWinnerCC = false;

  array.forEach((item) => {
    const [var1, var2] = item.split('-');
    const resultId = user?.result?._id;

    if (resultId === var1) {
      userWinner = true;
      var2Value = var2;
    }
  });

  arrayClaimedComments.forEach((item) => {
    const [var1cc, var2cc, var3cc] = item.split('-');
    const resultId = user?.result?._id;

    if (resultId === var1cc) {
      userWinnerCC = true;
      var2ValueCC = var2cc;
      if (var3cc === 'CLAIMED') {
        hasClaimedCC = true;
      }
    }
  });

  return (
    <>
         {userWinner && !hasClaimedCC ? (
          <div>
                    {campaignEndDate && currentDate < campaignEndDate ? (
                      <div className="raise-on-hover">
                        <div className={classes.card}>
                          <Link to={`/posts/${post._id}`} style={{ textDecoration: "none" }}>
                           
                            <div style={{marginTop: "0px"}}></div>
                            {/* <p className={classes.titleBrand}>{post.title}</p> */}
                            <div style={{width: "100px", height: "124.52px", margin: "8px auto 0px"}}>
            <LazyLoadImage 
               effect="blur" 
               style={{maxWidth: "100px", marginBottom: "0px"}}
               src={post.rewardSelectedFile}
               alt=""
               />
               </div>

            
               <p className="infoHeaderExpires">{currentDate > formattedCampaignDate ? 'Expired' : 'Expires'} <span className="tagStyle">{formattedCampaignDate}</span></p>
                          </Link>
                        </div>
                      </div>
                   ) : null }
                     </div>
         ) : null}
    </>
  );
};

    
    return (
      <>
      <InitialFilter post={post} />
      </>
    );
}

export default PostUnredeemed