import React, {useState} from 'react';
import { Button } from '@material-ui/core/';
import { useDispatch } from 'react-redux';
import {useHistory} from "react-router-dom"

import { etdPost } from '../../../actions/posts';

import useStyles from './styles';

// SNACKBAR:
import Snackbar from '@material-ui/core/Snackbar';
import PrizeModal from '../PrizeModal';
// import CPEForm from '../CPEForm.js/CPEForm';

// import Arrow from "../../assets/arrow.png"


const EnterTheDraw = ({post, currentId, setCurrentId}) => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const user = JSON.parse(localStorage.getItem('profile'));
    const history = useHistory()

      // SNACKBAR:
  const [hasEntered, setHasEntered] = useState(false);
  const [open, setOpen] = useState(false);

  const [hasWithdrawn, setHasWithdrawn] = useState(false);
  const [openWithdrawn, setOpenWithdrawn] = useState(false);

  const formatDrawDate = ({ post }) => {
    const dateObject = new Date(post.drawDate);
    const day = dateObject.getUTCDate().toString().padStart(2, '0');
    const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  const formattedDrawDate = formatDrawDate({ post });



  const handleEnteredNotification = (e) => {

    e.preventDefault()
    setTimeout(() => {
      setOpen(true);
    });
    setTimeout(() => {
      history.push("/posts");
    }, 1800); // Wait for 3 seconds before redirecting
  };

  const handleWithdrawnNotification = (e) => {
    e.preventDefault()
    setTimeout(() => {
      setOpenWithdrawn(true);
    });
    setTimeout(() => {
      history.push("/posts");
    }, 1800); // Wait for 3 seconds before redirecting
  };


    const CheckUserSubmissionPost = () => {
      if (post.comments.length > 0) {
        return post.comments.find((comment) => comment === (user?.result?._id))
          ? (
            <div onClick={handleWithdrawnNotification} style={{display: "flex", flexDirection: "column", borderRadius: "20px"}} >
              <p style={{fontSize: "18px", fontWeight: 900}}>Withdraw from the draw</p>
           </div>
    
          ) 
          : (
      <div onClick={handleEnteredNotification} style={{display: "flex", flexDirection: "column"}} >
          <p style={{fontSize: "18px", fontWeight: 900}}>Enter the draw</p>
          </div>

          );
      }
  
  
      return <>
         <div  onClick={handleEnteredNotification} style={{display: "flex", flexDirection: "column"}} >
                 <p style={{fontSize: "18px", fontWeight: 900}}>Enter the draw</p>
             </div>
      </>;
    };


            const CheckUserSubmissionPostSuccess = () => {
            if (post.comments.length > 0) {
              return post.comments.find((comment) => comment === (user?.result?._id))
                ? ( 
                    <div className={classes.enteredNote1}>
                    <p className={classes.enteredNote2}> 
                      You have successfully entered the draw
                    </p>
                    <p className={classes.enteredNote2}> Prize results will be available on the <span style={{color: "#FFF", padding: "2px 4px", borderRadius: "3px", fontWeight: 700, fontSize: "15px", color: "#30018F"}}>{formattedDrawDate}</span></p>
                    <br/>
                    <p className={classes.enteredNote3}> Check back then or we'll email you to let you know if you've won!</p>
                  </div>
                ) : (
                    <></>
                );
            }
            return (
                    <></>
            )
          };

    
    
    return (
      <>

{/* <p> NEED TO ADD EPIRY CHECK HERE</p> */}
    <div className={classes.CPEEnterButtonContainer}>
    {post.expiredByTagCode ? (
  <>
  </>
) : (
  <>
      {!post.expiredByTagCode && <PrizeModal post={post} />}
      <p className="pm_tipText">Campaign ends (1pm): {formattedDrawDate}</p>
    <Button className={classes.ETDButton} size="small" disabled={!user?.result} onClick={() => dispatch(etdPost(post._id))}>
      <CheckUserSubmissionPost />
    </Button>
    <CheckUserSubmissionPostSuccess/>
  </>
)}

        </div>


        {!hasEntered && 
        <Snackbar
        open={open}
        onClose={(event, reason) => {
          setOpen(false);
        }}
        style={{textAlign: "center", fontFamily: "Urbanist, sans-serif", fontSize: "30px"}}
        message={<><p style={{textAlign: "center", color: "#fff", fontFamily: "Urbanist, sans-serif", fontSize: "17px"}}>You have successfully entered the draw</p>
       {/* <p className={classes.enteredNote2}> Results will be available on the <span style={{fontWeight: 900}}>{post.drawDate}</span></p> */}
       </>}
      /> 
      }

    {!hasWithdrawn && 
    <Snackbar
    open={openWithdrawn}
    onClose={(event, reason) => {
      setOpen(false);
    }}
    // style={{textAlign: "center", fontFamily: "Urbanist, sans-serif", fontSize: "30px",}}
        // message={<><p style={{textAlign: "center", color: "#fff", fontFamily: "Urbanist, sans-serif", fontSize: "18px"}}>Successfully withdrawn. <br/> You are no longer in the draw.</p></>}
        message={<><p style={{textAlign: "center", color: "#fff", fontFamily: "Urbanist, sans-serif", fontSize: "17px"}}>Successfully withdrawn! </p>
        <p className={classes.enteredNote2}><span style={{fontWeight: 900}}>You are no longer in the draw.</span></p></>}
      />
      }
      </>
    )
}

export default EnterTheDraw



// import React, { useState } from 'react';
// import { Typography, TextField, Button, Link } from '@material-ui/core/';
// import Snackbar from '@material-ui/core/Snackbar';
// import { useDispatch } from 'react-redux';

// import { commentPost, etdPost } from '../../../actions/posts';
// import useStyles from './styles';


// import "./styles.css"
// import Tick from "../../assets/tick.png"
// import Arrow from "../../assets/arrow.png"

// const EnterTheDraw = ({post}) => {
//   const classes = useStyles();
//   const user = JSON.parse(localStorage.getItem('profile'));    
//   const dispatch = useDispatch();
//   const [open, setOpen] = useState(false);

//   const [hasEntered, setHasEntered] = useState(false);



//   const handleEnteredTheDraw = (e) => {
//     e.preventDefault()
//     setTimeout(() => {
//       setOpen(true);
//     });
//   };



// const CheckUserSubmissionPost = () => {

//   if (post.comments.length > 0) {
//     return post.comments.find((comment) => comment === (user?.result?._id))
//       ? ( 
//         <div className={classes.enteredNoteContainer}>
//           <p>Withdraw from the draw</p>
//         </div>
//       ) : (
//         <div onClick={handleEnteredTheDraw}>
//         <p>
//           Click here to enter the draw
//         </p>
//         </div>
//       );
//   }

//   return (
//     <div onClick={handleEnteredTheDraw}>
//     <p>
//     Click here to enter the draw
//   </p>
//   </div>
//   );
// };




//           const CheckUserSubmissionPostSuccess = () => {

//             if (post.comments.length > 0) {
//               return post.comments.find((comment) => comment === (user?.result?._id))
//                 ? ( 
//                     <div>
//                     <p className={classes.enteredNote2}> 
//                       You have successfully entered the draw! 
//                     </p>
//                     <p className={classes.enteredNote2}> Prize results will be available on the <span style={{fontWeight: 900}}>{post.drawDate}</span></p>
//                     <br/>
//                     <p className={classes.enteredNote3}> Check back then or we'll email you to let you know if you've won!</p>
                 
        
//                   </div>
//                 ) : (
//                     <></>
//                 );
//             }
//             return (
//                     <></>
//             )
//           };


//   return (
//     <>
//     <div>
//     <Button className={classes.ETDButton} size="small" disabled={!user?.result} 
//     onClick={() => dispatch(etdPost(post._id))}
//     >
//     {/* <CheckUserSubmissionPost /> */}
//     </Button>
//     {/* <CheckUserSubmissionPostSuccess/> */}

//   </div>

//       {!hasEntered && 
//     <Snackbar
//         open={open}
//         style={{textAlign: "center", fontFamily: "Urbanist, sans-serif", fontSize: "30px",}}
//         onClose={(event, reason) => {
//           setOpen(false);
//         }}
//         message={<><p style={{textAlign: "center", color: "#fff", fontFamily: "Urbanist, sans-serif", fontSize: "17px"}}>Successfully added to your collection! <br/> To enter the draw, please go to your collection, click on the card, and then click 'ENTER THE DRAW'. 
//         <br/><Link style={{color: "#FFF", fontSize: "21px"}} href="/posts">GO TO MY COLLECTION
//         <br/><img style={{width: "25px"}} src={Arrow} alt=""/></Link></p></>}
//       /> 
//     }
//   </>
//   )
// }

// export default EnterTheDraw

