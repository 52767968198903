import React, {useState, useEffect} from 'react'
import { Paper } from '@material-ui/core/';
import { useLocation } from 'react-router-dom';

import useStyles from './styles';

import decode from "jwt-decode"

// import FileBase from 'react-file-base64';


// import ProfileQuiz from './ProfileQuiz/ProfileQuiz';

const Profile = () => {


    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const location = useLocation();
    const classes = useStyles();

    // console.log(user)
    // console.log(user.result._id)


    useEffect(() => {
        const token = user?.token
    
        if (token) {
          const decodedToken = decode(token)
    
        //   if(decodedToken.exp * 1000 < new Date().getTime()) logout()
        }
        setUser(JSON.parse(localStorage.getItem("profile")))
      }, [location])

  return (

    <div className="gutter">

    {/* <div style={{ padding: '25px 25px', borderRadius: '25px', backgroundImage: "linear-gradient(to top, #fe9938, #fe9337, #fe8d37, #fe8737, #fe8137, #fe7c37, #fe7737, #fe7238, #fe6d38, #fe6838, #fe6338, #fe5e38)" }} elevation={6}> */}
    <div style={{ 
      marginTop: "3vh",
      padding: '10px 25px', 
      // borderRadius: '7px', 
      // backgroundColor: "#FFF",
      // border: '0.5px solid rgb(240, 240, 240)',
      // boxShadow: 'rgba(0, 0, 0, 0.01) 0px 6px 16px',
      }} elevation={6}>

      
        <div className={classes.porfileContainer}>
    <div className={classes.greetingText}>Hell000o</div>

    {/* <div className={classes.usernameStyle}>{user.result.name}</div> */}

    {/* <div className={classes.profileBorder}></div> */}
    
    {/* START OF LINK TO PROFILE QUIZ's */}

    {/* <div className={classes.contactContainer}>
        <p className={classes.profileContactHeader}>SHOPPER PROFILE:</p>
        <div className={classes.profileHeaderBorder}></div>
        <ProfileQuiz user={user}/>
    </div> */}

    {/* END OF LINK TO PROFILE QUIZ's */}

    {user?.result?.name &&
    <>
        <div className={classes.profileText}>{user.result.name}</div>
    </>
  }

    {/* {user?.result?.age &&
    <>
    <div className={classes.profileTextHead}>Age:</div>
    <div className={classes.profileText}>{user.result.age}</div>
    </>
    }

    {user?.result?.gender &&
    <>  
    <div className={classes.profileTextHead}>Gender:</div>
    <div className={classes.profileText}>{user.result.gender}</div>
    </>
    } */}

    {/* <div className={classes.profileTextHead}>Occupation (indicator):</div>
    <div className={classes.profileText}>{user.result.occupation}</div> */}


    {/* <div className={classes.profileTextHead}>Income (indicator):</div>
    <div className={classes.profileText}>{user.result.income}</div> */}


    {/* <div className={classes.profileTextHead}>Address (Suburb indicator):</div>
    <div className={classes.profileText}>{user.result.suburb}</div> */}


    {/* <div className={classes.contactContainer}> */}
        <p className={classes.profileTextHead}>CONTACT INFO</p>

        {/* <div className={classes.profileHeaderBorder}></div> */}
        <div className={classes.profileContactHead}>Email:</div>
        <div className={classes.profileText}>{user.result.email}</div>



        {/* <div className={classes.profileContactHead}>Phone:</div>
        <div className={classes.profileContactText}>{user.result.phone}</div> */}
    {/* </div> */}
    <p className={classes.profileContactHeader}> If you would like to update or remove any of the contents on your profile, please contact: <span style={{textDecoration: "underline", fontSize: "17px", color: "#30018F"}}>help@jebnz.com</span> </p>

    </div>
    </div>
    
    </div>
  )
}

export default Profile