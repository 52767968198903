import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Paper, Grid, Container } from '@material-ui/core';
import {  useHistory, Link } from 'react-router-dom';

import Snackbar from '@material-ui/core/Snackbar';

import { signin, signup } from '../../actions/auth';

import Input from './Input';

import useStyles from './styles';



// IMAGES:
// import Profile1 from "./images/profile1.png"
// import Profile2 from "./images/profile2.png"
// import Profile3 from "./images/profile3.png"
// import Profile4 from "./images/profile4.png"
import InputName from './InputName';


const initialState = { firstName: '', lastName: '', email: '', password: '', confirmPassword: '', age: '', gender: '', occupation: '', ip: '' };

const Auth = () => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [isSignup, setIsSignup] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [checked, setChecked] = useState(false);
  const history = useHistory();
  const [ip, setIp] = useState('');

  const [open, setOpen] = useState(false);

  const [openSignIn, setSignInOpen] = useState(false);

  
  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIp(data.ip));
  }, []);


    const handleShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);

    const switchMode = () => {
      setFormData(initialState);
      setIsSignup((prevIsSignup) => !prevIsSignup);
      setShowPassword(false);
    };

    

    // const handleSubmit = (e) => {
    //   e.preventDefault()
    //   if (isSignup) {
    //       dispatch(signup({ ...formData, createdAt: new Date()}, history)); 
    //   } else {
    //     dispatch(signin(formData, history));
    //   } 


    
    const handleSubmit = (e) => {
      e.preventDefault();
  

      if (isSignup) {
          dispatch(signup({ ...formData, createdAt: new Date()}, history)); 
      } else {
        dispatch(signin(formData, history));
      } 



      // if (isSignup) {
      //   dispatch(signup({ ...formData, createdAt: new Date() }))
      //     .then(() => {
      //       setTimeout(() => {
      //         history.push("/posts");
      //       }, 3000);
      //     });
      
      // } else {
      //   dispatch(signin(formData))
      //     .then(() => {
      //       setTimeout(() => {
      //         history.push("/posts");
      //       }, 3000);
      //     });
      // }
  





      setTimeout(() => {
        setOpen(true);
      }, 4000); // delay of 2 seconds (2000 milliseconds)
      // setOpen(true)

      setTimeout(() => {
        setSignInOpen(true);
      }, 4000); // delay of 2 seconds (2000 milliseconds)
      // setOpen(true)
    };


    const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value, ip: `${ip}` });



    function handleClick() {
      setIsVisible(!isVisible);
    }

    // CHECK BOX:
      // Declare a function to handle changes to the checkbox
    function handleCheck(event) {
    // Update the checked state variable with the current checked state of the checkbox
    setChecked(event.target.checked);
  }

  

  return (
    <Container component="main" maxWidth="xs">

    {/* <Paper className={classes.paper} elevation={6}> */}

    <div className={classes.paperNew}>
      {/* <div className={classes.paperBorder}> */}
    <h1 className={classes.signInHeader}>{ isSignup ? 'Sign up' : 'Log in' }</h1>


    {/* <Grid container justifyContent="center">
          <Grid item> */}
            <div className={classes.signUpSwitch} type="submit" fullWidth variant="contained" onClick={switchMode}>
                { isSignup ? 'Have an account?' : "No account?" }
              </div>

      
              {/* </Grid>
              </Grid> */}
{/* className={classes.signUpSwitch} */}


      <form className={classes.form} onSubmit={handleSubmit}>

        <Grid container spacing={2}>
          { isSignup && (
          <>

          {/* <p className={classes.authWelcome}>Hell00o <span className={classes.authWelcomeName}> &nbsp;no name&nbsp; </span>! </p> */}
       
          {/* or any other aspects in the highlighted section below,  */}
          <p className={classes.authWelcomeSub}>If you'd like to remain anonymous, feel free to exclude your name</p>
            {/* <TextField name="firstName" label="Name" type="firstName"  handleChange={handleChange} autoFocus fullWidth />
            <TextField style={{marginTop: "10px"}} name="lastName" label="Surname" type="lastName"  handleChange={handleChange} fullWidth /> */}
       
            <p className={classes.inputHeaders}>Name</p>
            <InputName name="firstName" handleChange={handleChange} autoFocus  />
            <p className={classes.inputHeaders}>Surname</p>
            <InputName name="lastName" handleChange={handleChange} />

            {/* <div className={classes.authDivider}></div> */}


            



{ isSignup && 
<Snackbar
        open={open}
        style={{textAlign: "center", color: "#FE5E38", fontFamily: "Urbanist, sans-serif"}}
        onClose={(event, reason) => {
          setSignInOpen(false);
        }}
        message="Oops, it looks like that email is already registered. Please use a different email or log in to your account."
      />

    }

            {/* <div className={classes.authDivider}></div> */}


      <label className={classes.checkBoxText}>
      <input
        required={true}
        type="checkbox"
        checked={checked}
        onChange={handleCheck}
      />
        By registering, you confirm that you agree to the processing of your feedback as described in the <Link style={{textDecoration: "none", color: "#808080"}} to="/privacy-policy">Privacy Policy</Link>.
    </label>
    
    {/* <InputName value={ip} name="ip" label="ip" handleChange={handleChange} autoFocus half /> */}

          </>
          )}



          <p className={classes.inputHeaders}>Email</p>
          <Input  className={classes.inputStyle} name="email" handleChange={handleChange} type="email" />

          <p className={classes.inputHeaders}>Password</p>
          <Input name="password" handleChange={handleChange} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
          { isSignup && <Input name="confirmPassword" label="Repeat Password" handleChange={handleChange} type="password" /> }
       
        </Grid>

        
      <Button type="submit" fullWidth variant="contained" className={classes.submit}>
          { isSignup ? 'Sign up' : 'Log in' }
        </Button>

        <Grid container justifyContent="center">
          {/* <Grid item>
          <Button className={classes.signUpSwitch} onClick={switchMode}>
                { isSignup ? 'Have an account? Sign in' : "Don't have an account? Sign Up" }
              </Button>
          </Grid> */}
          <div>
            {!isSignup && 
            <div>
      <div className={classes.forgottenPassword} onClick={handleClick}>Forgetten password?</div>
      {isVisible && <div className={classes.forgottenPasswordInfo}>Please email: <span style={{textDecoration: "underline", fontSize: "17px", color: "#30018F"}}>help@jebnz.com</span></div>}
            </div>
            }
      </div>
            
       </Grid> 
      </form>
      {/* </div> */}
      </div>
    {/* </Paper> */}


    {!isSignup && 
    <Snackbar
        open={open}
        style={{textAlign: "center", color: "#FE5E38", fontFamily: "Urbanist, sans-serif"}}
        onClose={(event, reason) => {
          setOpen(false);
        }}
        message="Oops, it looks like that email or password is not quite right, please give it another go."
      />
}
  </Container>
  )
}

export default Auth




            {/* <FormControl className={classes.fromControlStyle} style={{margin: "10px"}} fullWidth>
             <InputLabel>Age</InputLabel>
                <Select
                  required
                  name="age"
                  type="age"
                  label="Age"
                  onChange={handleChange}
                  >
                  <MenuItem value={"Under 16"}>Under 16</MenuItem>
                  <MenuItem value={"Between 16 to 20"}>Between 16 to 20</MenuItem>
                  <MenuItem value={"Between 21 to 25"}>Between 21 to 25</MenuItem>
                  <MenuItem value={"Between 26 to 30"}>Between 26 to 30</MenuItem>
                  <MenuItem value={"Between 31 to 35"}>Between 31 to 35</MenuItem>
                  <MenuItem value={"Between 36 to 40"}>Between 36 to 40</MenuItem>
                  <MenuItem value={"Between 41 to 45"}>Between 41 to 45</MenuItem>
                  <MenuItem value={"Between 46 to 50"}>Between 46 to 50</MenuItem>
                  <MenuItem value={"Between 51 to 55"}>Between 51 to 55</MenuItem>
                  <MenuItem value={"Between 56 to 60"}>Between 56 to 60</MenuItem>
                  <MenuItem value={"Between 61 to 65"}>Between 61 to 65</MenuItem>
                  <MenuItem value={"Between 66 to 60"}>Between 66 to 70</MenuItem>
                  <MenuItem value={"Prefer not to share my age"}>Prefer not to share my age</MenuItem>
                </Select>
            </FormControl>
    
   

            <FormControl  className={classes.fromControlStyle} style={{margin: "10px"}} fullWidth>
             <InputLabel> Gender </InputLabel>
                <Select
                  required
                  name="gender"
                  type="gender"
                  label="Gender"
                  onChange={handleChange}
                  >
                  <MenuItem value={"Male"}>Male</MenuItem>
                  <MenuItem value={"Female"}>Female</MenuItem>
                  <MenuItem value={"Non-binary"}>Non-binary</MenuItem>
                  <MenuItem value={"Prefer not to share my gender"}>Prefer not to share my gender</MenuItem>
                </Select>
            </FormControl>



            <FormControl  className={classes.fromControlStyle} style={{margin: "10px"}} fullWidth>
             <InputLabel> Occupation</InputLabel>
                <Select
                  required
                  // variant="outlined"
                  name="occupation"
                  type="occupation"
                  label="Occupation"
                  onChange={handleChange}
                  >
                  <MenuItem value={"Accounting"}>Accounting</MenuItem>
                  <MenuItem value={"Administration & Office Support"}>Administration &amp; Office Support</MenuItem>
                  <MenuItem value={"Advertising, Arts & Media"}>Advertising, Arts &amp; Media</MenuItem>
                  <MenuItem value={"Banking & Financial Services"}>Banking &amp; Financial Services</MenuItem>
                  <MenuItem value={"Call Center & Customer Service"}>Call Center &amp; Customer Service</MenuItem>
                  <MenuItem value={"CEO & General Management"}>CEO &amp; General Management</MenuItem>
                  <MenuItem value={"Community Services & Development"}>Community Services &amp; Development</MenuItem>
                  <MenuItem value={"Construction"}>Construction</MenuItem>
                  <MenuItem value={"Consulting & Strategy"}>Consulting &amp; Strategy</MenuItem>
                  <MenuItem value={"Design & Architecture"}>Design &amp; Architecture</MenuItem>
                  <MenuItem value={"Education & Training"}>Education &amp; Training</MenuItem>
                  <MenuItem value={"Engineering"}>Engineering</MenuItem>
                  <MenuItem value={"Farming, Animals & Conservation"}>Farming, Animals &amp; Conservation</MenuItem>
                  <MenuItem value={"Government & Defence"}>Government &amp; Defence</MenuItem>
                  <MenuItem value={"Healthcare & Medical"}>Healthcare &amp; Medical</MenuItem>
                  <MenuItem value={"Hospitality & Tourism"}>Hospitality &amp; Tourism</MenuItem>
                  <MenuItem value={"Human Resources & Recruitment"}>Human Resources &amp; Recruitment</MenuItem>
                  <MenuItem value={"Information & Communication Technology"}>Information &amp; Communication Technology</MenuItem>
                  <MenuItem value={"Insurance & Superannuation"}>Insurance &amp; Superannuation</MenuItem>
                  <MenuItem value={"Legal"}>Legal</MenuItem>
                  <MenuItem value={"Manufacturing, Transport & Logistics"}>Manufacturing, Transport &amp; Logistics</MenuItem>
                  <MenuItem value={"Marketing & Communications"}>Marketing &amp; Communications</MenuItem>
                  <MenuItem value={"Mining, Resources & Energy"}>Mining, Resources &amp; Energy</MenuItem>
                  <MenuItem value={"Real Estate & Property"}>Real Estate &amp; Property</MenuItem>
                  <MenuItem value={"Retail & Consumer Products"}>Retail &amp; Consumer Products</MenuItem>
                  <MenuItem value={"Sales"}>Sales</MenuItem>
                  <MenuItem value={"Self Employment"}>Self Employment</MenuItem>
                  <MenuItem value={"Sport & Recreation"}>Sport &amp; Recreation</MenuItem>
                  <MenuItem value={"Trades & Services"}>Trades &amp; Services</MenuItem>
                  <MenuItem value={"Prefer not to share my occupation"}>​​Prefer not to share my occupation</MenuItem>
                </Select>
            </FormControl> */}




            {/* <FormControl className={classes.fromControlStyle} style={{margin: "10px"}} fullWidth>
            <img style={{width: "40px", margin: "0px 80%"}} src={Profile4} alt=""/> 
             <InputLabel>Income (before tax)</InputLabel>
                <Select
                  required
                  // variant="outlined"
                  name="income"
                  type="income"
                  label="Income"
                  onChange={handleChange}
                  >
     
                  <MenuItem value={"$0 to $14,000"}>$0 to $14,000</MenuItem>
                  <MenuItem value={"$14,001 to $48,000"}>$14,001 to $48,000</MenuItem>
                  <MenuItem value={"​$48,001 to $70,000"}>​$48,001 to $70,000</MenuItem>
                  <MenuItem value={"​$70,001 to $180,000"}>​$70,001 to $180,000</MenuItem>
                  <MenuItem value={"$180,000+"}>​​$180,000+</MenuItem>
                  <MenuItem value={"It's a secret"}>​​It's a secret...</MenuItem>
          
                </Select>
            </FormControl> */}




{/* 
            <p className={classes.suburbNote}> Very sorry to note that our services are only available in Auckland at this time. </p>
            <FormControl className={classes.fromControlStyle} style={{margin: "10px"}} fullWidth>
             <InputLabel>Suburb</InputLabel>
                <Select
                  required
                  // variant="outlined"
                  name="suburb"
                  type="suburb"
                  label="Suburb"
                  onChange={handleChange}
                  >
     
                  <MenuItem value={"Auckland Central"}>Auckland Central</MenuItem>
                  <MenuItem value={"North Shore"}>North Shore</MenuItem>
                  <MenuItem value={"​East Auckland"}>​East Auckland</MenuItem>
                  <MenuItem value={"​South Auckland"}>​South Auckland</MenuItem>
                  <MenuItem value={"Pukekohe"}>​​Pukekohe</MenuItem>
                  <MenuItem value={"West Auckland"}>​​West Auckland</MenuItem>
                  <MenuItem value={"Prefer not to share my suburb"}>​​Prefer not to share my suburb</MenuItem>
                </Select>
            </FormControl> */}

            {/* <Input name="suburb" label="Suburb Address" handleChange={handleChange} type="suburb" />
            <p style={{fontFamily: "Urbanist, sans-serif", fontSize: "12px", margin: "0px 10px"}}>e.g. Auckland Central, North Shore...</p> */}