import React, {useEffect, useState} from 'react'
import { Container, Grow, Grid, AppBar, Button } from '@material-ui/core';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getPosts, getPostsBySearch } from '../../actions/posts';
import Posts from '../Posts/Posts';


import useStyles from './styles.js';

import Arrow2 from "./../assets/arrow2.png"
import Cross from "./../assets/cross.png"
import Search from "./../assets/magnifier.png"

import LandingPage from '../LandingPage/LandingPage.js';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}



const Home = () => {

  const classes = useStyles();

  const [currentId, setCurrentId] = useState(0);
  const [campaignCurrentId, setCampaignCurrentId] = useState(0);
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');
  const [tags, setTags] = useState([]);
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('profile'));

  const location = useLocation();


   useEffect(() => {
     dispatch(getPosts());
   }, [currentId, dispatch]);


   const searchPost = () => {
    if (search.trim() || tags) {
      dispatch(getPostsBySearch({ search, tags: tags.join(',') }));
      history.push(`/posts/search?searchQuery=${search || 'none'}&tags=${tags.join(',')}`);
    } else {
      history.push('/');
    }
  };

   const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      searchPost();
    }
  };

  const clearSearch = () => {
    setSearch('')
    window.location.reload();
  }



  // Check if the current URL is "/posts/search"
  const isSearchPage = location.pathname === "/posts/search"; 


        // CAN APPLY THIS TO TOTAL JEB... (NEED TO CREATE AN ACCOUNT ECT. EVEN BEFORE SEEING COUPONS)
        if (!user?.result?._id) {
          return (
            <><LandingPage/></>  
          )
        }

  return (
  <div className="gutter">

         {/* <center>
   <AppBar className={classes.appBarSearchCollection} position="static" color="inherit">
      <input className={classes.appBarSearchInput} onKeyDown={handleKeyPress} name="search" variant="outlined" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)}/>
      
      { isSearchPage && 
      <img src={Cross} className={classes.clearButtonImage} onClick={clearSearch} alt=""/>
      }


      <div onClick={searchPost} 
      style={{
      backgroundColor: "#B2B2B2",
      borderRadius: "20px",
      color: "#FFF",
      textAlign: "center",
      padding: "6px 0px 2px",
      width: "35px",
      margin: "0px 4px",
      textTransform: "uppercase"
    }}><img style={{width: "14px"}} src={Search} alt=""/></div>
      </AppBar>
      </center> */}


      {/* <Grow in>
        <div style={{width: "100vw"}}> */}
      {/* <Container maxWidth="xl"> */}

      {/* <div style={{display: "flex", justifyContent: "flex-end"}}> */}
      {/* <h1 className={classes.formHeaderRedeem}>Collection</h1> */}

      {/* <center> */}


      {/* <Link style={{ textDecoration: "none" }} to="/quick-view">
  <div className={classes.seeAllButton}>
    <p style={{ margin: 0 }}>Quick View</p>
    <img src={Arrow2} style={{ width: "20px", height: "20px", marginLeft: "0px", marginRight: "0px", padding: "0px 0px" }} alt="" />
  </div>
</Link> */}


      {/* </div>  */}
      {/* </center> */}
     
       <Grid container justifyContent="center" alignItems="stretch" className={classes.gridContainer}>
      <Grid item xs={12} sm={12} md={12}>
      <Posts setCurrentId={setCurrentId}/>
      </Grid>
      </Grid>
      {/* </Container> */}
      {/* </div>
      </Grow> */}
  </div>
  )
}

export default Home







